import React from 'react';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import SumTableInfo from 'src/admin/components/SumTableInfo';

import DatesFilterBar from 'src/shared/components/DatesFilterBar';
import { ClassDetails, FilterDates } from 'src/types/types';
import TableHeader from 'src/shared/components/TableHeader';

import Row from './Row';
import headerItems from './HeaderItems';
import styles from './TableSmall.module.scss';

interface TableSmallProps {
  data: ClassDetails[];
  filterDates: FilterDates;
  handleFilteringClasses: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
}

const TableSmall: React.FC<TableSmallProps> = ({
  data,
  filterDates,
  handleFilteringClasses,
  changeFilterDate,
}) => {
  const hasData = data.length !== 0;

  return (
    <TableContainer className={styles.table}>
      <DatesFilterBar
        filterDates={filterDates}
        handleFilteringData={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
      <span className={styles.numberOfClasses}>
        Number of classes: <span className={styles.number}>{data.length}</span>
      </span>
      {hasData && (
        <>
          <Table>
            <TableHeader
              items={headerItems}
              itemClassName={styles.headerCell}
            />
            <TableBody>
              {data.map((item: ClassDetails) => {
                return <Row key={item.id} data={item} />;
              })}
            </TableBody>
          </Table>
          <div className={styles.sumTable}>
            <SumTableInfo data={data} />
          </div>
        </>
      )}
    </TableContainer>
  );
};

export default TableSmall;
