import React, { useState } from 'react';
import cx from 'classnames';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import { DashboardProfessor } from 'src/types/types';
import { AdminRouteCodes } from 'src/constants/routes';
import { Link } from 'react-router-dom';
import ActionsButton from 'src/admin/components/ActionsButton';
import styles from './Row.module.scss';
import { MenuItem } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import StarIcon from '@material-ui/icons/Star';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';
import ActivityBox from 'src/admin/components/ActivityBox';

interface RowProps {
  data: DashboardProfessor;
}

const Row: React.FC<RowProps> = ({ data }) => {
  const [actionsOpen, setActionsOpen] = useState(false);

  const toggleActionsOpen = (): void => {
    setActionsOpen(!actionsOpen);
  };

  const availabilityClasses = cx({
    [styles.availability]: true,
    [styles.availability__high]: data.availability === 'HIGH',
    [styles.availability__low]: data.availability === 'LOW',
    [styles.availability__full]: data.availability === 'NONE',
  });

  return (
    <TableRow key={data.id}>
      <TableCell size="small" className={availabilityClasses}>
        <LensIcon color="inherit" />
      </TableCell>
      <TableCell size="small">{data.name}</TableCell>
      <TableCell size="small">{data.language}</TableCell>
      <TableCell size="small">
        <span className={styles.langoScore}>
          <BarChartIcon />
          {data.langoScore}
        </span>
      </TableCell>
      <TableCell size="small">
        <span className={styles.rating}>
          <StarIcon />
          {data.formatedRating}
        </span>
      </TableCell>
      <TableCell size="small">
        <span className={styles.students}>
          <PersonIcon />
          {data.formatedStudentsNumber}
        </span>
      </TableCell>
      <TableCell size="small">{data.classCount}</TableCell>
      <TableCell size="small">{data.formatedLastClassDate}</TableCell>
      <TableCell size="small">
        <ActivityBox activityValue={data.activity} />
      </TableCell>
      <TableCell size="small" align="center">
        <ActionsButton
          actionsOpen={actionsOpen}
          toggleActionsOpen={toggleActionsOpen}
        >
           <MenuItem>
             <Link to={`${AdminRouteCodes.PROFESSOR_OVERVIEW}/${data.id}`}>
              Overview
             </Link>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link to={`${AdminRouteCodes.ADD_CLASS}/${data.id}`}>
              Add class
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={`${AdminRouteCodes.STUDENT_ASSIGN}/${data.id}`}>
              Assing Student
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link to={`${AdminRouteCodes.PROFESSOR}/${data.id}`}>
              Edit Info
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link to={`${AdminRouteCodes.CLASSES}?professorId=${data.id}`}>
              View Classes
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem>
            <button
              className={styles.copyEmailButton}
              onClick={() => {
                navigator.clipboard.writeText(data.email);
                toggleActionsOpen();
              }}
            >
              Copy Email
            </button>
          </MenuItem>
        </ActionsButton>
      </TableCell>
    </TableRow>
  );
};

export default Row;
