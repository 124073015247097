import { useEffect, useState } from 'react';
import { ClassDetails, FilterDates } from 'src/types/types';
import { useHistory, useLocation } from 'react-router-dom';
import { getDates, getFormatedDate } from 'src/utils/helpers';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import { generateClassesURL } from 'src/utils/helpers';
import classes from 'src/api/classes';

interface Data {
  filterDates: FilterDates;
  handleFilteringClasses: () => void;
  changeFilterDate: (date: Date | null, type: string) => void;
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
  currentClasses: ClassDetails[];
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useAllClasses = (): Data => {
  const query = useQuery();
  const history = useHistory();
  const [error, setError] = useState<Error | null>(null);
  const [currentClasses, setCurrentClasses] = useState<ClassDetails[]>([]);
  const [filterDates, setFilterDates] = useState<any>(getDates(query));

  const [getAllClasses, getAllClassesStatus] = useAsyncRequest<
    [Date | null, Date | null]
  >(classes.getAllClassesAsProfessor, {
    onComplete: (data: ClassDetails[]) => {
      setCurrentClasses(data.reverse());
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  useEffect(() => {
    getAllClasses(filterDates.start, filterDates.end);
  }, []);

  useEffect(() => {
    const startDateParam = query.get('startDate');
    const endDateParam = query.get('endDate');
    const currentStart = new Date(filterDates.start as any)
      .getTime()
      .toString();
    const currrentEnd = new Date(filterDates.end as any).getTime().toString();
    if (currentStart !== startDateParam || currrentEnd !== endDateParam) {
      const newDates = getDates(query);
      setFilterDates(newDates);

      if (newDates.start && newDates.end) {
        getAllClasses(newDates.start, newDates.end);
      }
    }
  }, [query.get('startDate'), query.get('endDate')]);

  const handleFilteringClasses = () => {
    const URL = `${history.location.pathname}?`;
    const generatedURL = generateClassesURL(
      URL,
      null,
      null,
      filterDates.start,
      filterDates.end,
    );
    history.push(generatedURL);
    setCurrentClasses([]);
    getAllClasses(filterDates.start, filterDates.end);
  };

  const changeFilterDate = (date: Date | null, type: string) => {
    let formatedDate = date;
    if (date) {
      formatedDate = getFormatedDate(date);
    }
    setFilterDates({
      ...filterDates,
      [type]: formatedDate,
    });
  };

  return {
    filterDates,
    changeFilterDate,
    handleFilteringClasses,
    currentClasses,
    isLoading: getAllClassesStatus.loading,
    error,
    closeError: () => setError(null),
  };
};

export default useAllClasses;
