import React from 'react';
import { ProfessorUser } from 'src/types/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHeader from 'src/shared/components/TableHeader';

import headerItems from './HeaderItems';
import Row from './Row';
import styles from './TableLarge.module.scss';

interface TableLargeProps {
  data: ProfessorUser[];
  getUsersAndProfessors: () => void;
}

const TableLarge: React.FC<TableLargeProps> = ({
  data,
  getUsersAndProfessors,
}) => {
  return (
    <Table className={styles.table}>
      <TableHeader items={headerItems} />
      <TableBody>
        {data.map((item: ProfessorUser) => (
          <Row
            key={item.professorId}
            data={item}
            getUsersAndProfessors={getUsersAndProfessors}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(TableLarge);
