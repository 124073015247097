import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Professor } from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import professor from 'src/api/professors';
import { AdminRouteCodes } from 'src/constants/routes';
import { useHistory, useParams } from 'react-router-dom';

interface Data {
  onSubmit: (data: Professor) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  isUpdate: boolean;
  apiError: Error | null;
  closeError: () => void;
}

interface Params {
  id: string;
}

const formatDataForForm = (data: Professor) => {
  return {
    ...data,
    price45: data.price45 / 100,
    price60: data.price60 / 100,
    price90: data.price90 / 100,
    professorPrice45: data.professorPrice45 / 100,
    professorPrice60: data.professorPrice60 / 100,
    professorPrice90: data.professorPrice90 / 100,
  };
};

const formatDataForSave = (data: Professor) => {
  return {
    ...data,
    price45: data.price45 * 100,
    price60: data.price60 * 100,
    price90: data.price90 * 100,
    professorPrice45: data.professorPrice45 * 100,
    professorPrice60: data.professorPrice60 * 100,
    professorPrice90: data.professorPrice90 * 100,
  };
};

const useProfessorForm = (): Data => {
  const [error, setError] = useState<Error | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const history = useHistory();
  const { id } = useParams<Params>();
  const { handleSubmit, errors, control, reset } = useForm();

  const onCreateProfessorComplete = () => {
    history.push(AdminRouteCodes.DASHBOARD);
  };

  const [createProfessor, createProfessorStatus] = useAsyncRequest<[Professor]>(
    professor.createProfessor,
    {
      onComplete: () => {
        onCreateProfessorComplete();
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [updateProfessor, updateProfessorStatus] = useAsyncRequest<[Professor]>(
    professor.updateProfessor,
    {
      onComplete: () => {
        onCreateProfessorComplete();
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [getProfessorById, getProfessorByIdStatus] = useAsyncRequest<[string]>(
    professor.getProfessorById,
    {
      onComplete: (data: Professor) => {
        const formatedData = formatDataForForm(data);
        reset({ ...formatedData });
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  useEffect(() => {
    if (id) {
      getProfessorById(id);
      setIsUpdate(true);
    }
  }, [id]);

  const onSubmit = useCallback(
    (data: Professor) => {
      if (id) {
        updateProfessor(formatDataForSave({ ...data, id }));
      } else {
        createProfessor(formatDataForSave(data));
      }
    },
    [id],
  );

  return {
    isUpdate,
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading:
      createProfessorStatus.loading ||
      updateProfessorStatus.loading ||
      getProfessorByIdStatus.loading,
    apiError: error,
    closeError: (): void => setError(null),
  };
};

export default useProfessorForm;
