const headerItems = [
  {
    text: '',
    props: {
      width: '10%',
    },
  },
  {
    text: '#',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Student',
    props: {
      width: '40%',
    },
  },
  {
    text: 'Date',
    props: {
      width: '40%',
    },
  },
];

export default headerItems;
