import axios from 'axios';

const login = (email: string, password: string): Promise<any> => {
  const loginObject = {
    email: email,
    password: password,
    returnSecureToken: 'true',
  };

  return axios.post(
    `${process.env.REACT_APP_FIREBASE_URL}/verifyPassword?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
    loginObject,
  );
};

export default login;
