const headerItems = [
  {
    text: '',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Name',
    props: {
      width: '70%',
    },
  },
  {
    text: '',
    props: {
      width: '20%',
    },
  },
];

export default headerItems;
