import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/en-US';
import React from 'react';
import { Controller } from 'react-hook-form';

interface DatePickerProps {
  control: any;
  name: any;
  rules?: any;
  defaultValue?: any;
  label: string;
  maxDate?: Date;
  error?: boolean;
  helperText?: string;
  className?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  control,
  name,
  defaultValue,
  rules = {},
  label,
  maxDate,
  error,
  helperText,
  className,
}) => {
  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value, name }: any) => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <KeyboardDatePicker
              className={className}
              label={label}
              onChange={onChange}
              value={value}
              name={name}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              autoOk={true}
              maxDate={maxDate}
              error={error}
              helperText={error && helperText}
            />
          </MuiPickersUtilsProvider>
        );
      }}
    />
  );
};

export default DatePicker;
