import fetch from 'src/utils/fetch';
import { Student } from 'src/types/types';

const getAllStudents = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/students`;

  return fetch({ url });
};

const createStudent = (student: Student): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/students`;

  return fetch({ url, data: student, method: 'POST' });
};

const getStudentById = (id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/students/${id}`;

  return fetch({ url });
};

const updateStudent = (student: Student): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/students/${student.id}`;

  return fetch({ url, data: student, method: 'PUT' });
};

const updateStudentActivity = (
  studentId: string,
  active: boolean,
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/students/${studentId}`;

  const data = {
    active,
  };

  return fetch({ url, data, method: 'PATCH' });
};

const migrateToCommissionBased = (studentId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/students/${studentId}`;

  const data = {
    commissionBased: true,
  };
  return fetch({ url, data, method: 'PATCH' });
};

export default {
  getAllStudents,
  createStudent,
  getStudentById,
  updateStudent,
  updateStudentActivity,
  migrateToCommissionBased,
};
