import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import React from 'react';
import { Controller } from 'react-hook-form';

interface InputProps extends BaseTextFieldProps {
  control: any;
  name: any;
  rules?: any;
  defaultValue?: any;
  alwaysShrink?: boolean;
}

const Input: React.FC<InputProps> = ({
  control,
  name,
  defaultValue,
  alwaysShrink,
  rules = {},
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value, name, ref }: any) => {
        return (
          <TextField
            inputRef={ref}
            onChange={onChange}
            name={name}
            InputLabelProps={{ shrink: Boolean(value) || alwaysShrink }}
            value={value ? value : ''}
            {...props}
          />
        );
      }}
    />
  );
};

export default Input;
