const headerItems = [
  {
    text: '',
    props: {
      width: '3%',
    },
  },
  {
    text: 'ID',
    props: {
      width: '3%',
      style: { paddingLeft: 0 },
    },
  },
  {
    text: 'Name',
    props: {
      width: '18%',
    },
  },
  {
    text: 'Create time',
    props: {
      width: '17%',
    },
  },
  {
    text: 'Class Count',
    props: {
      width: '11%',
    },
  },
  {
    text: 'Amount',
    props: {
      width: '7%',
    },
  },
  {
    text: 'Commission',
    props: {
      width: '7%',
    },
  },
  {
    text: 'Amount after commission',
    props: {
      width: '12%',
    },
  },
  {
    text: 'Currency',
    props: {
      width: '7%',
    },
  },
  {
    text: 'Status',
    props: {
      width: '8%',
    },
  },
  {
    text: 'Commission based',
    props: {
      width: '8%',
    },
  },
];

export default headerItems;
