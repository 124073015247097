const headerItems = [
  {
    id: 'professor-header-1',
    text: '',
    props: {
      width: '2%',
    },
  },
  {
    id: 'professor-header-2',
    text: 'Name',
    props: {
      width: '17%',
    },
  },
  {
    id: 'professor-header-3',
    text: 'Language',
    props: {
      width: '10%',
    },
  },
  {
    id: 'professor-header-4',
    text: 'Score',
    props: {
      width: '10%',
    },
  },
  {
    id: 'professor-header-5',
    text: 'Rating',
    props: {
      width: '11%',
    },
  },
  {
    id: 'professor-header-6',
    text: 'Students',
    props: {
      width: '11%',
    },
  },
  {
    id: 'professor-header-7',
    text: 'Classes',
    props: {
      width: '12%',
    },
  },
  {
    id: 'professor-header-8',
    text: 'Last class',
    props: {
      width: '10%',
    },
  },
  {
    id: 'professor-header-9',
    text: 'Activity',
    props: {
      width: '6%',
    },
  },
  {
    id: 'professor-header-10',
    text: '',
    props: {
      width: '10%',
    },
  },
];

export default headerItems;
