import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ClassType,
  ProfessorAvailability,
  ProfessorStudent,
  ProfessorStudentRelationFO,
  User,
} from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import relations from 'src/api/relations';
import classes from 'src/api/classes';
import professors from 'src/api/professors';
import { getDaysDiff, getFormatedDate, todayDate } from 'src/utils/helpers';

const INFO_BOX_TIMEOUT = 2000;

interface Data {
  user: User;
  success: boolean;
  onSubmit: (data: ProfessorStudent) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  apiError: Error | null;
  closePopup: () => void;
  relationsList: ProfessorStudentRelationFO[];
  currentDurationList: number[];
  isAvailabilityFormVisible: boolean;
  onAvailabilityFormSubmit: (status: string) => void;
  isAvailabilityFormLoading: boolean;
  availabilityFormError: Error | null;
}

const getDurationList = (relation: ProfessorStudent) => {
  const possibleDurationList = [];
  if (relation.price30 && relation.professorPrice30) {
    possibleDurationList.push(30);
  }

  if (relation.price45 && relation.professorPrice45) {
    possibleDurationList.push(45);
  }

  if (relation.price60 && relation.professorPrice60) {
    possibleDurationList.push(60);
  }

  if (relation.price90 && relation.professorPrice90) {
    possibleDurationList.push(90);
  }

  return possibleDurationList;
};

const UserInitialValues = {
  email: '',
  id: '',
  isAdmin: false,
  professorId: '',
  uid: '',
};

const useAddClass = (): Data => {
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState<User>(UserInitialValues);
  const [currentStudentId, setCurrentStudentId] = useState<string>('');
  const [currentDurationList, setCurrentDurationList] = useState<number[]>([]);
  const [relationsList, setRelationsList] = useState<
    ProfessorStudentRelationFO[]
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const [isAvailabilityFormVisible, setIsAvailabilityFormVisible] = useState(
    false,
  );
  const [
    availabilityFormError,
    setAvailabilityFormError,
  ] = useState<Error | null>(null);
  const professorData = localStorage.getItem('user');

  const { handleSubmit, errors, control, watch, reset } = useForm();
  const choosenRelation = watch('psRelation');

  const [getRelations, getRelationsStatus] = useAsyncRequest<[string]>(
    relations.getRelationsAsProfessor,
    {
      onComplete: (data: ProfessorStudent[]) => {
        const relationsList = data.map((psRelation) => {
          const possibleDurationList = getDurationList(psRelation);
          return {
            id: psRelation.id,
            studentId: psRelation.studentId,
            studentName: psRelation.studentName,
            active: Object.prototype.hasOwnProperty.call(psRelation, 'active')
              ? psRelation.active
              : true,
            possibleDurationList,
            status: psRelation.status,
          };
        });
        const activeRelations = relationsList.filter(
          (relation) => relation.active,
        );

        const sortedRelationList = activeRelations.sort((a, b) => {
          if (a.studentName < b.studentName) return -1;
          if (a.studentName > b.studentName) return 1;
          return 0;
        });

        setRelationsList(sortedRelationList);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [createNewClass, createNewClassStatus] = useAsyncRequest<[ClassType]>(
    classes.createNewClassAsProfessor,
    {
      onComplete: () => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, INFO_BOX_TIMEOUT);
        reset({});
        getRelations(user.professorId);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [
    putProfessorAvailability,
    putProfessorAvailabilityStatus,
  ] = useAsyncRequest<[ProfessorAvailability]>(
    professors.putProfessorAvailability,
    {
      onComplete: () => {
        setIsAvailabilityFormVisible(false);
      },
      onError: (error: Error) => {
        setAvailabilityFormError(error);
      },
    },
  );

  const [
    getProfessorAvailability,
    getProfessorAvailabilityStatus,
  ] = useAsyncRequest<[]>(professors.getProfessorAvailability, {
    onComplete: (data: ProfessorAvailability) => {
      if (!data) {
        setIsAvailabilityFormVisible(true);
      } else {
        const daysDiff = getDaysDiff(data.createTime);
        if (daysDiff >= 7) {
          setIsAvailabilityFormVisible(true);
        }
      }
    },
  });

  const closePopup = () => {
    setError(null);
    setSuccess(false);
    setAvailabilityFormError(null);
  };

  useEffect(() => {
    if (professorData) {
      const professor = JSON.parse(professorData);
      getRelations(professor.professorId);
      setUser(professor);
      getProfessorAvailability();
    }
  }, [professorData]);

  useEffect(() => {
    if (choosenRelation) {
      setCurrentStudentId(choosenRelation.studentId);
      if (choosenRelation.status === 'TRIAL') {
        setCurrentDurationList([30]);
      } else {
        setCurrentDurationList(choosenRelation.possibleDurationList);
      }
    }
  }, [choosenRelation]);

  const onSubmit = useCallback(
    (data: any) => {
      const formatedDate = getFormatedDate(data.date);
      const classData: ClassType = {
        psRelationId: choosenRelation.id,
        professorId: user.professorId,
        studentId: currentStudentId,
        duration: data.duration,
        date: formatedDate.getTime(),
        remark: data.remark,
      };

      createNewClass(classData);
    },
    [choosenRelation, user.professorId, currentStudentId],
  );

  const onAvailabilityFormSubmit = (status: string) => {
    const availabilityData: ProfessorAvailability = {
      professorId: user.professorId,
      status,
      createTime: todayDate(),
    };

    putProfessorAvailability(availabilityData);
  };

  return {
    user,
    success,
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading:
      getRelationsStatus.loading ||
      createNewClassStatus.loading ||
      getProfessorAvailabilityStatus.loading,
    apiError: error,
    closePopup,
    relationsList,
    currentDurationList,
    isAvailabilityFormVisible,
    onAvailabilityFormSubmit,
    isAvailabilityFormLoading: putProfessorAvailabilityStatus.loading,
    availabilityFormError,
  };
};

export default useAddClass;
