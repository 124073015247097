import React from 'react';
import { getFormatedRating } from 'src/utils/dataFormater';
import { formatDate, formatMoney } from 'src/utils/helpers';
import ScoreCard from '../../components/ScoreCard';

import styles from './OverviewScoreCards.module.scss';

interface OverviewScoreCardsProps {
  performanceData: any;
  activityData: any;
  payoutData: any;
}

const OverviewScoreCards: React.FC<OverviewScoreCardsProps> = ({
  performanceData,
  activityData,
  payoutData,
}) => {
  const { ratingAvg, ratingCount, score } = performanceData;
  const {
    activeStudentCount,
    studentCount,
    trialStudentCount,
    classCount,
    lastClass,
  } = activityData;
  const { nextPayoutAmount, nextPayoutCurrency } = payoutData;

  const rating = getFormatedRating(ratingAvg, ratingCount);
  const trialStudentsNumber =
    trialStudentCount > 0 ? `+${trialStudentCount}` : '';
  const studentsCount = `${activeStudentCount}${trialStudentsNumber} (${studentCount})`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.cards}>
        <ScoreCard title="Score">{score}</ScoreCard>
        <ScoreCard title="Rating">{rating}</ScoreCard>
        <ScoreCard title="Students count">{studentsCount}</ScoreCard>
        <ScoreCard title="Class count">{classCount}</ScoreCard>
        <ScoreCard title="Last class">{formatDate(lastClass)}</ScoreCard>
        <ScoreCard title="Next payout">
          {formatMoney(nextPayoutAmount, nextPayoutCurrency)}
        </ScoreCard>
      </div>
    </div>
  );
};

export default OverviewScoreCards;
