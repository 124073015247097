import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutline';

import { Payment } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';

import styles from './Row.module.scss';
import { Button } from '@material-ui/core';
import { ASSIGN } from 'src/constants/literals';

interface RowProps {
  data: Payment;
  handleModalOpen: (paymentId: number, remark: string | null) => void;
}

const Row: React.FC<RowProps> = ({ data, handleModalOpen }) => {
  const {
    amount,
    amountAfterFee,
    currency,
    date,
    id,
    paymentMethod,
    remark,
    studentName,
    studentId,
  } = data;

  return (
    <TableRow className={!studentId ? styles.redRow : ''}>
      <TableCell size="small">{id}</TableCell>
      <TableCell size="small">{studentName}</TableCell>
      <TableCell size="small">{formatMoney(amount, currency)}</TableCell>
      <TableCell size="small">
        {formatMoney(amountAfterFee, currency)}
      </TableCell>
      <TableCell size="small">{paymentMethod}</TableCell>
      <TableCell size="small">{formatDate(date)}</TableCell>
      <TableCell size="small" align="center" className={styles.remarkCell}>
        {remark && (
          <Tooltip title={remark} classes={{ tooltip: styles.tooltip }}>
            <ChatBubbleIcon fontSize="small" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell size="small" className={styles.button}>
        {!studentId && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => handleModalOpen(id, remark)}
          >
            {ASSIGN}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
