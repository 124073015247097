import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Payout } from 'src/types/types';
import { formatDateAndTime, formatMoney } from 'src/utils/helpers';

interface RowProps {
  data: Payout;
}

const Row: React.FC<RowProps> = ({ data }) => {
  const {
    currency,
    total,
    totalCommission,
    id,
    totalAfterCommission,
    professorName,
    status,
    classCount,
    commissionBased,
    createTime,
  } = data;

  return (
    <TableRow>
      <TableCell size="small">{id}</TableCell>
      <TableCell size="small">{professorName}</TableCell>
      <TableCell size="small">{formatDateAndTime(createTime)}</TableCell>
      <TableCell size="small">{classCount}</TableCell>
      <TableCell size="small">{formatMoney(total)}</TableCell>
      <TableCell size="small">{formatMoney(totalCommission)}</TableCell>
      <TableCell size="small">{formatMoney(totalAfterCommission)}</TableCell>
      <TableCell size="small">{currency}</TableCell>
      <TableCell size="small">{status}</TableCell>
      <TableCell size="small">{commissionBased ? 'Yes' : 'No'}</TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
