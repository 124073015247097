import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Input from 'src/shared/components/Input';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from 'src/admin/components/StudentForm/StudentForm.module.scss';
import AutocompleteSelectInput from 'src/shared/components/AutocompleteSelectInput';

interface StudentFormProps {
  onSubmit: any;
  handleSubmit: any;
  errors: any;
  control: any;
  isUpdate: boolean;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
}

const StudentForm: React.FC<StudentFormProps> = ({
  onSubmit,
  handleSubmit,
  errors,
  control,
  isUpdate,
  isLoading,
  apiError,
  closeError,
}) => {
  const buttonText = isUpdate ? 'Update Student' : 'Add Student';

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="outlined" className={styles.card}>
          <CardHeader
            title="General information"
            className={styles.cardHeader}
          />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="name"
              className={styles.input__generalInfo}
              label="Name of student *"
              margin="dense"
              error={!!errors.name}
              helperText={!!errors.name && 'Name is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="language"
              className={styles.input__generalInfo}
              label="Language *"
              margin="dense"
              error={!!errors.language}
              helperText={!!errors.language && 'Language is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="country"
              className={styles.input__generalInfo}
              label="Country *"
              margin="dense"
              error={!!errors.country}
              helperText={!!errors.country && 'Country is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="email"
              className={styles.input__generalInfo}
              type="email"
              label="Email *"
              margin="dense"
              error={!!errors.email}
              helperText={!!errors.email && 'Email is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              name="mobile"
              label="Mobile"
              margin="dense"
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              name="ip"
              label="IP Address"
              margin="dense"
              disabled={isLoading}
            />
            <AutocompleteSelectInput
              error={errors.preferredPaymentMethod}
              options={['PayPal', 'Credit Card', 'RSD Payment', 'EUR Payment']}
              className={styles.input__generalInfo}
              label="Preferred way of payment"
              name="preferredPaymentMethod"
              control={control}
              disabled={isLoading}
            />
          </CardContent>
        </Card>

        <Card variant="outlined" className={styles.card}>
          <CardHeader title="Other" className={styles.cardHeader} />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              className={styles.textArea}
              label="General remark"
              name="remark"
              multiline
              disabled={isLoading}
            />
          </CardContent>
        </Card>

        <Button
          className={styles.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>{buttonText}</span>
          )}
        </Button>
      </form>
      {apiError && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default StudentForm;
