import React from 'react';

import { withWindow } from 'react-window-decorators';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProfessorsTableLarge from 'src/admin/components/Tables/Professors/TableLarge';
import ProfessorsTableSmall from 'src/admin/components/Tables/Professors/TableSmall';
import StudentsTableLarge from 'src/admin/components/Tables/Students/TableLarge';
import StudentsTableSmall from 'src/admin/components/Tables/Students/TableSmall';
import TabPanel from 'src/admin/components/TabPanel';
import Layout from 'src/shared/components/Layout';

import useDashboard from './Dashboard.hooks';
import styles from './Dashboard.module.scss';
import Button from '@material-ui/core/Button/Button';
import { NavLink } from 'react-router-dom';
import { AdminRouteCodes } from 'src/constants/routes';
import { ADD_PROFESSOR, ADD_STUDENT } from 'src/constants/literals';

interface DashboardProps {
  breakpoint: string;
}

const Dashboard: React.FC<DashboardProps> = ({ breakpoint }) => {
  const {
    activeTabIndex,
    closeError,
    data,
    error,
    handleTabsChange,
    isLoading,
    studentUpdatedId,
    updateStudentActivity,
    actionButtonLoading,
    migrate,
  } = useDashboard();

  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  return (
    <Layout isLoading={isLoading} error={error} closeError={closeError}>
      <div className={styles.tabWrapper}>
        <Tabs
          className={styles.tabs}
          value={activeTabIndex}
          onChange={handleTabsChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className={styles.tab} label="Professors" id="tab-0" />
          <Tab className={styles.tab} label="Students" id="tab-1" />
          <Tab
            className={styles.tab}
            label={isSmallOrMedium ? 'Inactive S' : 'Inactive Students'}
            id="tab-2"
          />
        </Tabs>
        <div className={styles.buttonWrapper}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            component={NavLink}
            to={AdminRouteCodes.PROFESSOR}
          >
            {ADD_PROFESSOR}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            className={styles.studentButton}
            component={NavLink}
            to={AdminRouteCodes.STUDENT}
          >
            {ADD_STUDENT}
          </Button>
        </div>
      </div>
      {data && (
        <>
          <TabPanel value={activeTabIndex} index={0}>
            {activeTabIndex == 0 &&
              (isSmallOrMedium ? (
                <ProfessorsTableSmall data={data.professors} />
              ) : (
                <ProfessorsTableLarge data={data.professors} />
              ))}
          </TabPanel>
          <TabPanel value={activeTabIndex} index={1}>
            {activeTabIndex == 1 &&
              (isSmallOrMedium ? (
                <StudentsTableSmall
                  isInactive={false}
                  studentsData={data.activeStudents}
                  studentUpdatedId={studentUpdatedId}
                  updateStudentActivity={updateStudentActivity}
                  actionButtonLoading={actionButtonLoading}
                  migrate={migrate}
                />
              ) : (
                <StudentsTableLarge
                  isInactive={false}
                  studentsData={data.activeStudents}
                  studentUpdatedId={studentUpdatedId}
                  updateStudentActivity={updateStudentActivity}
                  actionButtonLoading={actionButtonLoading}
                  migrate={migrate}
                />
              ))}
          </TabPanel>
          <TabPanel value={activeTabIndex} index={2}>
            {activeTabIndex == 2 &&
              (isSmallOrMedium ? (
                <StudentsTableSmall
                  isInactive
                  studentsData={data.inactiveStudents}
                  studentUpdatedId={studentUpdatedId}
                  updateStudentActivity={updateStudentActivity}
                  actionButtonLoading={actionButtonLoading}
                  migrate={migrate}
                />
              ) : (
                <StudentsTableLarge
                  isInactive
                  studentsData={data.inactiveStudents}
                  studentUpdatedId={studentUpdatedId}
                  updateStudentActivity={updateStudentActivity}
                  actionButtonLoading={actionButtonLoading}
                  migrate={migrate}
                />
              ))}
          </TabPanel>
        </>
      )}
    </Layout>
  );
};

export default React.memo(withWindow(Dashboard));
