import { useForm } from 'react-hook-form';
import CountryCode from 'country-codes-list';
import { useCallback, useEffect, useState } from 'react';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import {
  CHECKOUT_BACKEND_ERROR_MESSAGE,
  CHECKOUT_ERROR_TITLE,
  CHECKOUT_FIRST_STEP_TITLE,
  CHECKOUT_PAYPAL_ERROR_MESSAGE,
  CHECKOUT_SECOND_STEP_TITLE,
  CHECKOUT_SUCCESS_MESSAGE,
  CHECKOUT_SUCCESS_TITLE,
  CHECKOUT_THIRD_STEP_TITLE,
  STUDENT_NOT_FOUND_ERROR_MESSAGE,
  ORDER_NOT_FOUND_ERROR_MESSAGE,
  PAYMENT_VALIDATION_FAILED_ERROR_MESSAGE,
} from 'src/constants/literals';
import payments from 'src/api/payments';
import { formatPaypalPaymentData } from 'src/utils/dataFormater';
export interface Country {
  name: string;
  code: string;
  areaCode: string;
}
const ERROR_TEXT: Record<string, string> = {
  STUDENT_NOT_FOUND: STUDENT_NOT_FOUND_ERROR_MESSAGE,
  PAYMENT_VALIDATION_FAILED: PAYMENT_VALIDATION_FAILED_ERROR_MESSAGE,
  ORDER_NOT_FOUND: ORDER_NOT_FOUND_ERROR_MESSAGE,
};

interface Data {
  createOrder: (data: any, actions: any) => void;
  onApprove: (data: any, actions: any) => void;
  onSubmit: (data: any) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  countryList: Country[];
  areaCode: string;
  customPaymentValue: string | null;
  choosenValue: string | null;
  formData: any;
  handleStudentFormSubmit: (email: string) => void;
  pageTitle: string;
  validStudentEmail: string;
  onError: (error: any) => void;
  loading: boolean;
  successMessage: null | string;
  processErrorMessage: null | string;
  resetData: () => void;
  handleCustomValueClick: () => void;
  isCustomValueRadionButtonChecked: boolean;
}

export interface PaypalFormData {
  firstName: string;
  lastName: string;
  phone: string;
  postalCode: string;
  city: string;
  address: string;
  email: string;
  amount: string;
  customPaymentValue: string;
  currentCountryCode: string;
}

const usePaypalPayment = (): Data => {
  const { handleSubmit, errors, control, watch, getValues, reset } = useForm();
  const [validStudentEmail, setValidStudentEmail] = useState('');
  const [pageTitle, setPageTitle] = useState(CHECKOUT_FIRST_STEP_TITLE);
  const [formData, setFormData] = useState<PaypalFormData | null>(null);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [currentCounty, setCurrentCounty] = useState<Country | undefined>();
  const [choosenValue, setChoosenValue] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<null | string>(null);
  const [approving, setApproving] = useState<boolean>(false);
  const [processErrorMessage, setProcessErrorMessage] = useState<null | string>(
    null,
  );
  const [
    isCustomValueRadionButtonChecked,
    setIsCustomValueRadionButtonChecked,
  ] = useState(false);

  const currentCountryCode = watch('country');
  const amount = watch('amount');
  const customPaymentValue = watch('customPaymentValue');

  const resetData = () => {
    setFormData(null);
    setValidStudentEmail('');
    setPageTitle(CHECKOUT_FIRST_STEP_TITLE);
    setChoosenValue(null);
    window.location.reload();
  };

  useEffect(() => {
    const countryList = CountryCode.customArray({
      name: '{countryNameEn}',
      code: '{countryCode}',
      areaCode: '{countryCallingCode}',
    });

    countryList.sort(function (countryOne: Country, countryTwo: Country) {
      const nameOne = countryOne.name.toUpperCase();
      const nameTwo = countryTwo.name.toUpperCase();
      if (nameOne < nameTwo) {
        return -1;
      }
      if (nameOne > nameTwo) {
        return 1;
      }

      return 0;
    });

    setCountryList(countryList);
  }, []);

  useEffect(() => {
    if (customPaymentValue && customPaymentValue.length > 0 && amount) {
      reset({ ...getValues(), amount: null });
    }
    if (customPaymentValue) {
      setChoosenValue(customPaymentValue);
    }
  }, [customPaymentValue]);

  useEffect(() => {
    if (amount && customPaymentValue && customPaymentValue.length > 0) {
      reset({ ...getValues(), customPaymentValue: '' });
    }

    if (isCustomValueRadionButtonChecked && amount !== null) {
      setIsCustomValueRadionButtonChecked(false);
    }

    if (amount) {
      setChoosenValue(amount);
    }
  }, [amount]);

  useEffect(() => {
    if (currentCountryCode) {
      const countryData = countryList.find((country) => {
        return country.code === currentCountryCode;
      });
      setCurrentCounty(countryData);
    }
  }, [currentCountryCode]);

  const handleStudentFormSubmit = (email: string) => {
    setValidStudentEmail(email);
    setPageTitle(CHECKOUT_SECOND_STEP_TITLE);
  };

  const createOrder = useCallback(
    (data: any, actions: any) => {
      if (formData) {
        const orderData = {
          intent: 'CAPTURE',
          mock_application_codes: 'INSTRUMENT_DECLINED',
          payer: {
            name: {
              given_name: `${formData.firstName}`,
              surname: `${formData.lastName}`,
            },
            address: {
              address_line_1: `${formData.address}`,
              address_line_2: '',
              admin_area_1: `${currentCounty?.name}`,
              admin_area_2: `${formData.city}`,
              postal_code: `${formData.postalCode}`,
              country_code: `${currentCountryCode}`,
            },
            email_address: `${formData.email}`,
          },
          purchase_units: [
            {
              amount: {
                value: `${formData.amount || formData.customPaymentValue}`,
              },
            },
          ],
          application_context: {
            shipping_preference: 'NO_SHIPPING',
          },
        };
        return actions.order.create(orderData);
      }
    },
    [formData, currentCounty, choosenValue],
  );

  const [checkPaypalPayment, checkPaypalPaymentState] = useAsyncRequest<[any]>(
    payments.checkPaypalPayment,
    {
      onComplete: () => {
        setPageTitle(CHECKOUT_SUCCESS_TITLE);
        setSuccessMessage(CHECKOUT_SUCCESS_MESSAGE);
      },
      onError: (error: any) => {
        const errorType = error.response?.data?.error as string;
        setPageTitle(CHECKOUT_ERROR_TITLE);
        setProcessErrorMessage(
          ERROR_TEXT[errorType] || CHECKOUT_BACKEND_ERROR_MESSAGE,
        );
      },
    },
  );

  const onApprove = async (data: any, actions: any) => {
    setApproving(true);
    const result = await actions.order.capture();

    if (result.status === 'COMPLETED') {
      setApproving(false);
      const paypalPaymentData = {
        orderId: data.orderID,
        studentEmail: validStudentEmail,
        paymentInfo: formatPaypalPaymentData(formData),
      };

      checkPaypalPayment(paypalPaymentData);
    } else {
      setApproving(false);
      setProcessErrorMessage(CHECKOUT_PAYPAL_ERROR_MESSAGE);
    }
  };

  const onSubmit = (data: any) => {
    setFormData(data);
    setPageTitle(CHECKOUT_THIRD_STEP_TITLE);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onError = (error: any) => {
    setPageTitle(CHECKOUT_ERROR_TITLE);
    setProcessErrorMessage(CHECKOUT_PAYPAL_ERROR_MESSAGE);
  };

  const handleCustomValueClick = () => {
    reset({ ...getValues(), amount: null });
    setIsCustomValueRadionButtonChecked(true);
  };

  return {
    createOrder,
    onApprove,
    handleSubmit,
    onSubmit,
    errors,
    control,
    countryList,
    customPaymentValue,
    choosenValue,
    formData,
    handleStudentFormSubmit,
    pageTitle,
    validStudentEmail,
    onError,
    loading: checkPaypalPaymentState.loading || approving,
    successMessage,
    processErrorMessage,
    resetData,
    handleCustomValueClick,
    isCustomValueRadionButtonChecked,
    // Currently not used
    areaCode: currentCounty?.areaCode || '381',
  };
};

export default usePaypalPayment;
