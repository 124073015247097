import React from 'react';
import { withWindow } from 'react-window-decorators';

import Layout from 'src/shared/components/Layout';
import TableLarge from 'src/admin/components/Tables/PayoutList/TableLarge';
import TableSmall from 'src/admin/components/Tables/PayoutList/TableSmall';

import usePayoutList from './PayoutList.hooks';

interface DashboardProps {
  breakpoint: string;
}

const PayoutList: React.FC<DashboardProps> = ({ breakpoint }) => {
  const {
    isLoading,
    error,
    closeError,
    changeFilterDate,
    filterDates,
    handleFilteringPayouts,
    currentPayouts,
  } = usePayoutList();

  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  return (
    <Layout isLoading={isLoading} error={error} closeError={closeError}>
      {isSmallOrMedium ? (
        <TableSmall
          data={currentPayouts}
          changeFilterDate={changeFilterDate}
          filterDates={filterDates}
          handleFilteringPayouts={handleFilteringPayouts}
        />
      ) : (
        <TableLarge
          data={currentPayouts}
          changeFilterDate={changeFilterDate}
          filterDates={filterDates}
          handleFilteringPayouts={handleFilteringPayouts}
        />
      )}
    </Layout>
  );
};

export default withWindow(PayoutList);
