import axios from 'axios';
import fetch from 'src/utils/fetch';

const getUserById = (userId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/users/${userId}`;

  return fetch({ url });
};

const checkUserExist = (email: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/checkouts/student/exists?email=${email}`;

  return fetch({ url });
};

const getAllUsers = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/users`;

  return fetch({ url });
};

const createProfessorUser = (email: string, password: string): Promise<any> => {
  const token = localStorage.getItem('token');
  const professorUserData = {
    email: email,
    password: password,
    returnSecureToken: 'true',
  };

  const URL = `${process.env.REACT_APP_FIREBASE_URL}/signupNewUser?key=${process.env.REACT_APP_FIREBASE_API_KEY}&auth=${token}`;

  return axios.post(URL, professorUserData);
};

const addUser = (
  email: string,
  professorId: string,
  uid: string,
): Promise<any> => {
  const userData = {
    uid: uid,
    professorId: professorId,
    email: email,
  };

  const url = `${process.env.REACT_APP_API_URL}/adminApp/users`;

  return fetch({ url, data: userData, method: 'POST' });
};

export default {
  addUser,
  getAllUsers,
  createProfessorUser,
  getUserById,
  checkUserExist,
};
