import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Payment } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';
import styles from './Row.module.scss';
import Button from '@material-ui/core/Button';
import { ASSIGN } from 'src/constants/literals';

interface RowProps {
  data: Payment;
  handleModalOpen: (paymentId: number, remark: string | null) => void;
}

const Row: React.FC<RowProps> = ({ data, handleModalOpen }) => {
  const [open, setOpen] = useState(false);
  const {
    amount,
    amountAfterFee,
    currency,
    date,
    id,
    paymentMethod,
    remark,
    studentName,
    studentId,
  } = data;

  return (
    <>
      <TableRow className={!studentId ? styles.redRow : ''}>
        <TableCell size="small" width="5%" className={styles.buttonCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell size="small" width="50%" scope="row">
          {studentName}
        </TableCell>
        <TableCell size="small" width="45%" scope="row">
          {formatDate(date)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size="small" className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      ID
                    </TableCell>
                    <TableCell size="small">{id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Amount
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(amount, currency)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Amount after Fees
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(amountAfterFee, currency)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Payment Method
                    </TableCell>
                    <TableCell size="small">{paymentMethod}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Comment
                    </TableCell>
                    <TableCell size="small">{remark}</TableCell>
                  </TableRow>
                  {!studentId && (
                    <TableRow>
                      <TableCell size="small" variant="head">
                        Action
                      </TableCell>
                      <TableCell size="small">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => handleModalOpen(id, remark)}
                        >
                          {ASSIGN}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(Row);
