import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import payments from 'src/api/payments';
import useQuery from 'src/hooks/useQuery';
import { FilterDates, Payment, Student } from 'src/types/types';
import {
  generateClassesURL,
  getDates,
  getFormatedDate,
} from 'src/utils/helpers';
import { useHistory } from 'react-router-dom';
import student from 'src/api/student';

interface Data {
  filterDates: FilterDates;
  handleFilteringPayments: () => void;
  changeFilterDate: (date: Date | null, type: string) => void;
  payments: Payment[];
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
  selectedPaymentRemark: string | null;
  handleModalOpen: (payment: number, remark: string | null) => void;
  handleModalClose: () => void;
  isModalOpen: boolean;
  isModalLoading: boolean;
  modalError: Error | null;
  closeModalError: () => void;
  studentsList: Student[];
  setSelectedStudent: Dispatch<SetStateAction<Student | null>>;
  handleAssignClick: () => void;
}

const usePayments = (): Data => {
  const query = useQuery();
  const history = useHistory();
  const [error, setError] = useState<Error | null>(null);
  const [modalError, setModalError] = useState<Error | null>(null);
  const [currentPayments, setCurrentPayments] = useState<Payment[]>([]);
  const [filterDates, setFilterDates] = useState<any>(getDates(query));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<null | number>(
    null,
  );
  const [selectedPaymentRemark, setSelectedPaymentRemark] = useState<
    null | string
  >(null);
  const [selectedStudent, setSelectedStudent] = useState<null | Student>(null);
  const [studentsList, setStudentsList] = useState<Student[]>([]);

  const studentId = query.get('studentId');

  // Making sure that clicking on paymebnts in navigation reset the datepicker data
  useEffect(() => {
    const startDateParam = query.get('startDate');
    const endDateParam = query.get('endDate');
    const currentStart = new Date(filterDates.start).getTime().toString();
    const currrentEnd = new Date(filterDates.end).getTime().toString();
    if (currentStart !== startDateParam || currrentEnd !== endDateParam) {
      const newDates = getDates(query);
      setFilterDates(newDates);

      if (newDates.start && newDates.end) {
        getAllPayments(studentId, newDates.start, newDates.end);
      }
    }
  }, [query.get('startDate'), query.get('endDate')]);

  const [getAllStudents, getAllStudentsStatus] = useAsyncRequest<[]>(
    student.getAllStudents,
    {
      onComplete: (data: Student[]) => {
        setStudentsList(data);
      },
      onError: (error: Error) => {
        setModalError(error);
      },
    },
  );

  const [getAllPayments, getPaymentsStatus] = useAsyncRequest<
    [string | null, Date | null, Date | null]
  >(payments.getAllPayments, {
    onComplete: (data: Payment[]) => {
      setCurrentPayments(data);
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const [
    assignPaymentToStudent,
    assignPaymentToStudentStatus,
  ] = useAsyncRequest<[number | null, string | undefined]>(
    payments.assignPaymentToStudent,
    {
      onComplete: async () => {
        const updatedPayments = (await currentPayments.map((payment) => {
          if (payment.id === selectedPaymentId) {
            return {
              ...payment,
              studentId: selectedStudent?.id,
              studentName: selectedStudent?.name,
            };
          }
          return payment;
        })) as Payment[];

        setCurrentPayments(updatedPayments);
        setIsModalOpen(false);
        setSelectedPaymentId(null);
        setSelectedStudent(null);
      },
      onError: (error: Error) => {
        setModalError(error);
      },
    },
  );

  useEffect(() => {
    getAllPayments(studentId, filterDates.start, filterDates.end);
  }, []);

  const handleFilteringPayments = () => {
    const URL = `${history.location.pathname}?`;
    const generatedURL = generateClassesURL(
      URL,
      null,
      studentId,
      filterDates.start,
      filterDates.end,
    );
    history.push(generatedURL);
    setCurrentPayments([]);
    getAllPayments(studentId, filterDates.start, filterDates.end);
  };

  const changeFilterDate = (date: Date | null, type: string) => {
    let formatedDate = date;
    if (date) {
      formatedDate = getFormatedDate(date);
    }
    setFilterDates({
      ...filterDates,
      [type]: formatedDate,
    });
  };

  const handleModalOpen = (paymentId: number, remark: string | null): void => {
    setSelectedPaymentId(paymentId);
    setSelectedPaymentRemark(remark);
    setIsModalOpen(true);
    getAllStudents();
  };

  const handleModalClose = (): void => {
    setSelectedPaymentId(null);
    setSelectedPaymentRemark(null);
    setIsModalOpen(false);
    setModalError(null);
  };

  const closeModalError = () => {
    setModalError(null);
  };

  const handleAssignClick = () => {
    if (modalError) {
      setModalError(null);
    }
    assignPaymentToStudent(selectedPaymentId, selectedStudent?.id);
  };

  return {
    payments: currentPayments,
    isLoading: getPaymentsStatus.loading,
    error: error,
    closeError: (): void => setError(null),
    filterDates,
    handleFilteringPayments,
    changeFilterDate,
    selectedPaymentRemark,
    handleModalOpen,
    handleModalClose,
    isModalOpen,
    handleAssignClick,
    isModalLoading:
      getAllStudentsStatus.loading || assignPaymentToStudentStatus.loading,
    modalError,
    closeModalError,
    studentsList,
    setSelectedStudent,
  };
};

export default usePayments;
