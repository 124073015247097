import React from 'react';
import Layout from 'src/shared/components/Layout';
import AddClassForm from 'src/shared/components/AddClassForm';

import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

import useProfessorDashboard from './ProfessorDashboard.hooks';
import styles from './ProfessorDashboard.module.scss';
import AvailabilityForm from 'src/professor/components/AvailabilityForm';
import Modal from '@material-ui/core/Modal';

const ProfessorDashboard: React.FC = () => {
  const {
    user,
    relationsList,
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    apiError,
    closePopup,
    currentDurationList,
    success,
    isAvailabilityFormVisible,
    onAvailabilityFormSubmit,
    isAvailabilityFormLoading,
    availabilityFormError,
  } = useProfessorDashboard();

  const Title = (
    <Typography
      className={styles.title}
      component="h1"
      variant="h5"
    >{`Welcome ${user.email} `}</Typography>
  );

  return (
    <>
      <Layout>
        <>
          <AddClassForm
            control={control}
            errors={errors}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            relationsList={relationsList}
            currentDurationList={currentDurationList}
            title={Title}
            isLoading={isLoading}
            apiError={apiError}
            closeError={closePopup}
          />
          {success && (
            <Alert severity="success" onClose={closePopup}>
              Class was successfully registered.
            </Alert>
          )}
        </>
      </Layout>
      <Modal
        disableBackdropClick
        className={styles.modal}
        open={isAvailabilityFormVisible}
      >
        <AvailabilityForm
          error={!!availabilityFormError}
          closeError={closePopup}
          onSubmit={onAvailabilityFormSubmit}
          isLoading={isAvailabilityFormLoading}
        />
      </Modal>
    </>
  );
};

export default ProfessorDashboard;
