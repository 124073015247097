import React from 'react';
import NavigationBar from 'src/shared/components/NavigationBar';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Last30DaysCards from 'src/admin/components/Last30DayCards';
import StudentsDebtCards from 'src/admin/components/StudentsDebtCards';
import VersionBanner from 'src/shared/components/VersionBanner';
import useAnalytics from './Analytics.hook';

import styles from './Analytics.module.scss';

const Analytics: React.FC = () => {
  const { data, error, isLoading, closeError } = useAnalytics();

  return (
    <div className={styles.wrapper}>
      <NavigationBar />
      {Boolean(error) && (
        <Container className={styles.tableWrapper}>
          <Alert
            severity="error"
            onClose={closeError}
            className={styles.errorBar}
          >
            <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
          </Alert>
        </Container>
      )}
      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && data && (
        <>
          <Container className={styles.tableWrapper}>
            <Typography
              component="h1"
              variant="h4"
              className={styles.tableName}
            >
              Last 30 days
            </Typography>
            <Paper className={styles.dataWrapper}>
              <Last30DaysCards data={data} />
            </Paper>
          </Container>
          <Container className={styles.tableWrapper}>
            <Typography
              component="h1"
              variant="h4"
              className={styles.tableName}
            >
              {"Students' debt"}
            </Typography>
            <Paper className={styles.dataWrapper}>
              <StudentsDebtCards data={data} />
            </Paper>
          </Container>
        </>
      )}
      <VersionBanner />
    </div>
  );
};

export default Analytics;
