import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import payouts from 'src/api/payouts';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import { FilterDates, Payout } from 'src/types/types';
import {
  generatePayoutsURL,
  getDates,
  getFormatedDate,
} from 'src/utils/helpers';

interface Data {
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
  changeFilterDate: (date: Date | null, type: string) => void;
  handleFilteringPayouts: () => void;
  filterDates: FilterDates;
  currentPayouts: Payout[];
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const usePayoutList = (): Data => {
  const query = useQuery();
  const history = useHistory();
  const [error, setError] = useState<Error | null>(null);
  const [filterDates, setFilterDates] = useState<FilterDates>(getDates(query));
  const [currentPayouts, setCurrentPayouts] = useState<Payout[]>([]);

  useEffect(() => {
    getPayouts(filterDates.start, filterDates.end);
  }, []);

  // Making sure that clicking on class in navigation reset the datepicker data
  useEffect(() => {
    const startDateParam = query.get('startDate');
    const endDateParam = query.get('endDate');
    const currentStart = new Date(filterDates.start as any)
      .getTime()
      .toString();
    const currrentEnd = new Date(filterDates.end as any).getTime().toString();
    if (currentStart !== startDateParam || currrentEnd !== endDateParam) {
      const newDates = getDates(query);
      setFilterDates(newDates);

      if (newDates.start && newDates.end) {
        getPayouts(newDates.start, newDates.end);
      }
    }
  }, [query.get('startDate'), query.get('endDate')]);

  const [getPayouts, getPayoutsStatus] = useAsyncRequest<
    [Date | null, Date | null]
  >(payouts.getPayouts, {
    onComplete: (data: any) => {
      setCurrentPayouts(data);
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const changeFilterDate = (date: Date | null, type: string) => {
    let formatedDate = date;
    if (date) {
      formatedDate = getFormatedDate(date);
    }

    setFilterDates({
      ...filterDates,
      [type]: formatedDate,
    });
  };

  const handleFilteringPayouts = () => {
    const URL = `${history.location.pathname}?`;
    const generatedURL = generatePayoutsURL(
      URL,
      filterDates.start,
      filterDates.end,
    );
    history.push(generatedURL);
    setCurrentPayouts([]);
    getPayouts(filterDates.start, filterDates.end);
  };

  return {
    isLoading: getPayoutsStatus.loading,
    error: error,
    closeError: (): void => setError(null),
    changeFilterDate,
    filterDates,
    handleFilteringPayouts,
    currentPayouts,
  };
};

export default usePayoutList;
