import React from 'react';
import cx from 'classnames';
import packageJson from './../../../../package.json';
import styles from './VersionBanner.module.scss';

const PROD_ENVIRONMENT = 'production';

const VersionBanner: React.FunctionComponent = () => {
  const wrapperClasses = cx({
    [styles.wrapper]: true,
    [styles.wrapper__developmentMode]:
      process.env.REACT_APP_ENVIRONMENT !== PROD_ENVIRONMENT,
  });

  return (
    <div
      className={wrapperClasses}
    >{`2021 Lango Online Platform - v${packageJson.version}`}</div>
  );
};

export default VersionBanner;
