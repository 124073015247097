import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { ProfessorUser } from 'src/types/types';

import Row from './Row';
import styles from './TableSmall.module.scss';
interface TableSmallProps {
  data: ProfessorUser[];
  getUsersAndProfessors: () => void;
}

const TableSmall: React.FC<TableSmallProps> = ({
  data,
  getUsersAndProfessors,
}) => {
  return (
    <Table className={styles.table}>
      <TableBody>
        {data.map((item: ProfessorUser) => {
          return (
            <Row
              key={item.professorId}
              data={item}
              getUsersAndProfessors={getUsersAndProfessors}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableSmall;
