import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ProfessorUser } from 'src/types/types';
import Button from '@material-ui/core/Button';
import { CREATE_USER } from 'src/constants/literals';
import Modal from '@material-ui/core/Modal';
import CreateNewUserForm from 'src/admin/components/CreateNewUserForm';

import styles from './Row.module.scss';

interface RowProps {
  data: ProfessorUser;
  getUsersAndProfessors: () => void;
}

const Row: React.FC<RowProps> = ({ data, getUsersAndProfessors }) => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { name, email, uid, professorId } = data;

  return (
    <>
      <TableRow>
        <TableCell size="small" width="10%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell size="small" width="55%">
          {name}
        </TableCell>
        <TableCell size="small" width="35%" align="center">
          {!uid && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={(): void => setIsModalOpen(true)}
            >
              {CREATE_USER}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size="small" className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Email
                    </TableCell>
                    <TableCell size="small">{email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      User UID
                    </TableCell>
                    <TableCell size="small" className={styles.uidCell}>
                      {uid}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        disableBackdropClick
        className={styles.modal}
        open={isModalOpen}
        onClose={(): void => setIsModalOpen(false)}
      >
        <CreateNewUserForm
          email={email}
          professorId={professorId}
          closeModal={(): void => setIsModalOpen(false)}
          getUsersAndProfessors={getUsersAndProfessors}
        />
      </Modal>
    </>
  );
};

export default React.memo(Row);
