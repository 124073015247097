import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { OverviewPsRelation } from 'src/types/types';
import { Button, CircularProgress } from '@material-ui/core';
import ActionsButton from 'src/admin/components/ActionsButton';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { AdminRouteCodes } from 'src/constants/routes';
import Divider from '@material-ui/core/Divider';
import ActivityBox from 'src/admin/components/ActivityBox';

import styles from './Row.module.scss';

interface RowProps {
  data: OverviewPsRelation;
  deactivateRelation: (id: string) => void;
  isLoading: boolean;
  activity: string;
}

const Row: React.FC<RowProps> = ({
  data,
  deactivateRelation,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  const toggleActionsOpen = (): void => {
    setActionsOpen(!actionsOpen);
  };

  return (
    <>
      <TableRow>
        <TableCell className={styles.buttonCell} size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" className={styles.nameCell} size="small">
          <span className={styles.nameWrapper}>{data.studentName}</span>
        </TableCell>
        {isLoading ? (
          <TableCell size="small" align="center">
            <Button
              variant="outlined"
              color="primary"
              disabled
              className={styles.loadingButton}
            >
              <CircularProgress color="inherit" size="18px" />
            </Button>
          </TableCell>
        ) : (
          <TableCell size="small" align="center">
            <ActionsButton
              isFullWidth
              className={styles.actionButton}
              toggleActionsOpen={toggleActionsOpen}
              actionsOpen={actionsOpen}
            >
              <MenuItem>
                <Link to={`${AdminRouteCodes.EDIT_RELATION}/${data.id}`}>
                  Edit relation
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem>
                <button
                  className={styles.deactivateButton}
                  onClick={() => deactivateRelation(data.id)}
                >
                  Deactivate
                </button>
              </MenuItem>
            </ActionsButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">Class count</TableCell>
                    <TableCell>{data.classCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Last class</TableCell>
                    <TableCell>{data.formatedLastClassDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Activity</TableCell>
                    <TableCell>
                      <ActivityBox activityValue={data.activity} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Rating</TableCell>
                    <TableCell>{data.formatedRating}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Status</TableCell>
                    <TableCell>{data.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
