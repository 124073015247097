import React, { useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './ToolbarLarge.module.scss';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { getDayBeforeNow, getFormatedDate, todayDate } from 'src/utils/helpers';
import { AdminRouteCodes } from 'src/constants/routes';
interface ToolbarLargeProps {
  navLinks: any[];
  payoutsNavLinks: any[];
  userEmail: string;
}

const ToolbarLarge: React.FC<ToolbarLargeProps> = ({
  navLinks,
  userEmail,
  payoutsNavLinks,
}) => {
  const history = useHistory();
  const { logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderPayoutLinks = () => {
    return (
      payoutsNavLinks.length > 0 && (
        <div className={styles.dropdownWrapper} key="payouts">
          <Button
            className={styles.button}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            Payouts
          </Button>
          {isDropdownOpen && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setIsDropdownOpen(false);
              }}
            >
              <div className={styles.dropdownBody}>
                {payoutsNavLinks.map((link) => {
                  let path = link.path;
                  if (link.setDate) {
                    const startDate = getDayBeforeNow(7);
                    const endDate = getFormatedDate(todayDate());
                    path = `${
                      link.path
                    }?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;
                  }
                  return (
                    <MenuItem
                      key={link.title}
                      className={styles.navLinkWrapper}
                    >
                      <Button
                        onContextMenu={(e) => {
                          e.preventDefault();
                          window.open(path, '_blank');
                        }}
                        className={styles.navLink}
                        onClick={() => {
                          history.push(path);
                        }}
                      >
                        {link.title}
                      </Button>
                    </MenuItem>
                  );
                })}
              </div>
            </OutsideClickHandler>
          )}
        </div>
      )
    );
  };

  return (
    <Toolbar className={styles.wrapper}>
      {navLinks.map((link) => {
        let path = link.path;
        if (link.setDate) {
          const startDate = getDayBeforeNow(7);
          const endDate = getFormatedDate(todayDate());
          path = `${
            link.path
          }?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;
        }

        return link.path === AdminRouteCodes.PAYOUT ? (
          renderPayoutLinks()
        ) : (
          <Button
            onContextMenu={(e) => {
              e.preventDefault();
              window.open(path, '_blank');
            }}
            className={styles.button}
            key={link.title}
            onClick={() => {
              history.push(path);
            }}
          >
            {link.title}
          </Button>
        );
      })}

      <div className={styles.rightSide}>
        <Typography component="p">{userEmail}</Typography>
        <Button className={styles.logoutButton} onClick={logout}>
          Log out
        </Button>
      </div>
    </Toolbar>
  );
};

export default ToolbarLarge;
