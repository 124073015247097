import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import styles from './ScoreCard.module.scss';

interface ScoreCardProps {
  title: string;
  children: any;
}

const ScoreCard: React.FC<ScoreCardProps> = ({ title, children }) => {
  return (
    <Card variant="outlined" className={styles.card}>
      <CardHeader title={title} className={styles.cardHeader} />
      <CardContent className={styles.cardContent}>{children}</CardContent>
    </Card>
  );
};

export default ScoreCard;
