import React, { useState } from 'react';
import Card from '@material-ui/core/Card';

import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import styles from './AvailabilityForm.module.scss';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';
import Typography from '@material-ui/core/Typography';
import Layout from 'src/shared/components/Layout';

interface AvailabilityFormProps {
  onSubmit: (status: string) => void;
  isLoading: boolean;
  error: boolean;
  closeError: () => void;
}

const AvailabilityForm = React.forwardRef<
  HTMLDivElement,
  AvailabilityFormProps
>((props, ref) => {
  const { onSubmit, isLoading, error, closeError } = props;
  const [value, setValue] = useState<string>('');

  return (
    <div ref={ref} className={styles.wrapper} tabIndex={-1}>
      <Layout hideNavigationBar>
        <div className={styles.content}>
          <Typography className={styles.title} component="h2" variant="h5">
            Da li imate kapaciteta za nove učenike?
          </Typography>
          <Card variant="outlined" className={styles.card}>
            <FormControl component="fieldset">
              <RadioGroup
                name="availability"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <FormControlLabel
                  className={styles.radio}
                  value="HIGH"
                  control={<Radio color="primary" />}
                  label="Imam kapaciteta za više od jednog učenika"
                />
                <FormControlLabel
                  className={styles.radio}
                  value="LOW"
                  control={<Radio color="primary" />}
                  label="Možda, ali samo za jednog novog učenika i zavisi od termina"
                />
                <FormControlLabel
                  className={styles.radio}
                  value="NONE"
                  control={<Radio color="primary" />}
                  label="Nemam, potpuno mi je pun raspored"
                />
              </RadioGroup>
            </FormControl>
          </Card>
          <Button
            className={styles.submitButton}
            variant="contained"
            color="primary"
            onClick={() => onSubmit(value)}
            disabled={!value || isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              <span>Pošalji</span>
            )}
          </Button>
          {error && (
            <Alert
              className={styles.errorBox}
              severity="error"
              onClose={closeError}
            >
              <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
            </Alert>
          )}
        </div>
      </Layout>
    </div>
  );
});

AvailabilityForm.displayName = 'AvailabilityForm';

export default AvailabilityForm;
