import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Navigation from 'src/shared/components/NavigationBar';

const Unauthorized: React.FC = () => {
  return (
    <>
      <Navigation />
      <Container>
        <Typography component="h1" variant="h3">
          Unauthorized
        </Typography>
      </Container>
    </>
  );
};

export default Unauthorized;
