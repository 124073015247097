import { useEffect, useState } from 'react';
import { ClassDetails } from 'src/types/types';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import classes from 'src/api/classes';
import {
  generateClassesURL,
  getDates,
  getFormatedDate,
} from 'src/utils/helpers';
import { FilterDates } from 'src/types/types';
import { useHistory, useLocation } from 'react-router-dom';

interface Data {
  filterDates: FilterDates;
  handleFilteringClasses: () => void;
  changeFilterDate: (date: Date | null, type: string) => void;
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
  currentClasses: ClassDetails[];
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useAllClasses = (): Data => {
  const query = useQuery();
  const professorId = query.get('professorId');
  const studentId = query.get('studentId');
  const history = useHistory();
  const [error, setError] = useState<Error | null>(null);
  const [currentClasses, setCurrentClasses] = useState<ClassDetails[]>([]);
  const [filterDates, setFilterDates] = useState<any>(getDates(query));

  const [getAllClasses, getAllClassesStatus] = useAsyncRequest<
    [string | null, string | null, Date | null, Date | null]
  >(classes.getAllClasses, {
    onComplete: (data: ClassDetails[]) => {
      setCurrentClasses(data.reverse());
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  useEffect(() => {
    getAllClasses(professorId, studentId, filterDates.start, filterDates.end);
  }, []);

  // Making sure that clicking on class in navigation reset the datepicker data
  useEffect(() => {
    const startDateParam = query.get('startDate');
    const endDateParam = query.get('endDate');
    const currentStart = new Date(filterDates.start).getTime().toString();
    const currrentEnd = new Date(filterDates.end).getTime().toString();
    if (currentStart !== startDateParam || currrentEnd !== endDateParam) {
      const newDates = getDates(query);
      setFilterDates(newDates);

      if (newDates.start && newDates.end) {
        getAllClasses(professorId, studentId, newDates.start, newDates.end);
      }
    }
  }, [query.get('startDate'), query.get('endDate')]);

  const handleFilteringClasses = () => {
    const URL = `${history.location.pathname}?`;
    const generatedURL = generateClassesURL(
      URL,
      professorId,
      studentId,
      filterDates.start,
      filterDates.end,
    );
    history.push(generatedURL);
    setCurrentClasses([]);
    getAllClasses(professorId, studentId, filterDates.start, filterDates.end);
  };

  const changeFilterDate = (date: Date | null, type: string) => {
    let formatedDate = date;
    if (date) {
      formatedDate = getFormatedDate(date);
    }

    setFilterDates({
      ...filterDates,
      [type]: formatedDate,
    });
  };

  return {
    currentClasses,
    filterDates,
    handleFilteringClasses,
    changeFilterDate,
    isLoading: getAllClassesStatus.loading,
    error,
    closeError: () => setError(null),
  };
};

export default useAllClasses;
