const headerItems = [
  {
    id: 'student-header-1',
    text: 'Student Name',
    props: {
      width: '20%',
    },
  },
  {
    id: 'student-header-2',
    text: 'Class count',
    props: {
      width: '15%',
    },
  },
  {
    id: 'student-header-3',
    text: 'Last class',
    props: {
      width: '15%',
    },
  },
  {
    id: 'student-header-4',
    text: 'Activity',
    props: {
      width: '10%',
    },
  },
  {
    id: 'student-header-5',
    text: 'Rating',
    props: {
      width: '13%',
    },
  },
  {
    id: 'student-header-6',
    text: 'Status',
    props: {
      width: '15%',
    },
  },
  {
    id: 'student-header-7',
    text: '',
    props: {
      width: '12%',
    },
  },
];

export default headerItems;
