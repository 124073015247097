import { useEffect, useState } from 'react';
import payouts from 'src/api/payouts';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import { Payout } from 'src/types/types';
import { addCurrentId, removeCurrentId } from 'src/utils/dataFormater';

interface Data {
  isLoading: boolean;
  error: Error | null;
  errorCreate: Error | null;
  errorExecute: Error | null;
  closeError: () => void;
  calculatedPayouts: Payout[];
  pendingPayouts: Payout[];
  handleCalculatedCheckboxClick: (payout: Payout, checked: boolean) => void;
  handleCalculatedCheckAllClick: (checked: boolean) => void;
  handlePendingCheckboxClick: (payout: Payout, checked: boolean) => void;
  handlePendingCheckAllClick: (checked: boolean) => void;
  selectedCaluclatedPayouts: Payout[];
  selectedPendingPayouts: Payout[];
  isAllCalculatedChecked: boolean;
  isAllPendingChecked: boolean;
  createPayoutsLoading: boolean;
  executePendingPayoutsLoading: boolean;
  isCalculatedLoading: boolean;
  isPendingLoading: boolean;
  handleCreatePayouts: () => void;
  handleExecutePayouts: () => void;
  calculatedPayoutsSumData: CalculatedPayoutsSumData | undefined;
}

export interface CalculatedPayoutsSumData {
  classCountSum: number;
  amountSum: number | null;
  commissionSum: number | null;
  amountAfterCommission: number | null;
}

const usePayoutManagement = (): Data => {
  const [error, setError] = useState<Error | null>(null);
  const [errorCreate, setErrorCreate] = useState<Error | null>(null);
  const [errorExecute, setErrorExecute] = useState<Error | null>(null);
  const [calculatedPayouts, setCalculatedPayouts] = useState<Payout[]>([]);
  const [
    calculatedPayoutsSumData,
    setCalculatedPayoutsSumData,
  ] = useState<CalculatedPayoutsSumData>();
  const [pendingPayouts, setPendingPayouts] = useState<Payout[]>([]);
  const [isAllCalculatedChecked, setIsAllCalculatedChecked] = useState(false);
  const [isAllPendingChecked, setIsAllPendingChecked] = useState(false);
  const [selectedCaluclatedPayouts, setSelectedCaluclatedPayouts] = useState<
    Payout[]
  >([]);
  const [selectedPendingPayouts, setSelectedPendingPayouts] = useState<
    Payout[]
  >([]);

  useEffect(() => {
    getPayoutsCalculate();
    getPendingPayouts();
  }, []);

  useEffect(() => {
    if (
      isAllCalculatedChecked &&
      selectedCaluclatedPayouts.length < calculatedPayouts.length
    ) {
      setIsAllCalculatedChecked(false);
    }

    if (
      !isAllCalculatedChecked &&
      calculatedPayouts.length > 0 &&
      selectedCaluclatedPayouts.length === calculatedPayouts.length
    ) {
      setIsAllCalculatedChecked(true);
    }
  }, [selectedCaluclatedPayouts]);

  useEffect(() => {
    if (
      isAllPendingChecked &&
      selectedPendingPayouts.length < pendingPayouts.length
    ) {
      setIsAllPendingChecked(false);
    }

    if (
      !isAllPendingChecked &&
      pendingPayouts.length > 0 &&
      selectedPendingPayouts.length === pendingPayouts.length
    ) {
      setIsAllPendingChecked(true);
    }
  }, [selectedPendingPayouts]);

  const [getPayoutsCalculate, getPayoutsCalculateStatus] = useAsyncRequest<[]>(
    payouts.getPayoutsCalculate,
    {
      onComplete: (data: any) => {
        setCalculatedPayouts(addCurrentId(data));
        setCalculatedPayoutsSumData(calculatePayoutsSumData(data));
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [getPendingPayouts, getPendingPayoutsStatus] = useAsyncRequest<[]>(
    payouts.getPendingPayouts,
    {
      onComplete: (data: any) => {
        setPendingPayouts(addCurrentId(data));
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [createPayouts, createPayoutsStatus] = useAsyncRequest<[Payout[]]>(
    payouts.createPayouts,
    {
      onComplete: () => {
        const leftCalculatedPayouts = calculatedPayouts.filter(
          (calculatedPayout) => {
            return !selectedCaluclatedPayouts.find((selectedPayout) => {
              return calculatedPayout.currentId === selectedPayout.currentId;
            });
          },
        );
        setCalculatedPayouts(leftCalculatedPayouts);
        getPendingPayouts();
        setSelectedCaluclatedPayouts([]);
        setIsAllCalculatedChecked(false);
      },
      onError: (error: Error) => {
        setErrorCreate(error);
      },
    },
  );

  const calculatePayoutsSumData = (data: any): CalculatedPayoutsSumData => {
    let amountSum = 0;
    let commissionSum = 0;
    let amountAfterCommission = 0;
    let classCountSum = 0;
    const firstCurrency = data[0].currency;
    let currencyMismatched = false;

    data.forEach((element: any) => {
      if (firstCurrency !== element.currency && !currencyMismatched) {
        currencyMismatched = true;
      }

      amountSum += element.total;
      commissionSum += element.totalCommission;
      amountAfterCommission += element.totalAfterCommission;
      classCountSum += element.classCount;
    });

    return {
      amountSum: currencyMismatched ? null : amountSum,
      commissionSum: currencyMismatched ? null : commissionSum,
      amountAfterCommission: currencyMismatched ? null : amountAfterCommission,
      classCountSum,
    };
  };

  const [executePendingPayouts, executePendingPayoutsStatus] = useAsyncRequest<
    [number[]]
  >(payouts.executePendingPayouts, {
    onComplete: () => {
      const leftPendingPayouts = pendingPayouts.filter((pendingPayout) => {
        return !selectedPendingPayouts.find((selectedPayout) => {
          return pendingPayout.currentId === selectedPayout.currentId;
        });
      });
      setPendingPayouts(leftPendingPayouts);
      setSelectedPendingPayouts([]);
      setIsAllPendingChecked(false);
    },
    onError: (error: Error) => {
      setErrorExecute(error);
    },
  });

  const handleCreatePayouts = () => {
    if (selectedCaluclatedPayouts.length > 0) {
      createPayouts(removeCurrentId(selectedCaluclatedPayouts));
    }
  };

  const handleExecutePayouts = () => {
    if (selectedPendingPayouts.length > 0) {
      const payoutsIDList = selectedPendingPayouts.map((payout) => payout.id);
      executePendingPayouts(payoutsIDList);
    }
  };

  const handleCalculatedCheckboxClick = (payout: Payout, checked: boolean) => {
    if (checked) {
      const newList = [...selectedCaluclatedPayouts];
      newList.push(payout);
      setSelectedCaluclatedPayouts(newList);
    } else {
      const newList = selectedCaluclatedPayouts.filter((item) => {
        return item.currentId !== payout.currentId;
      });
      setSelectedCaluclatedPayouts(newList);
    }
  };

  const handleCalculatedCheckAllClick = (checked: boolean) => {
    if (checked) {
      setSelectedCaluclatedPayouts(calculatedPayouts);
      setIsAllCalculatedChecked(true);
    } else {
      setSelectedCaluclatedPayouts([]);
      setIsAllCalculatedChecked(false);
    }
  };

  const handlePendingCheckboxClick = (payout: Payout, checked: boolean) => {
    if (checked) {
      const newList = [...selectedPendingPayouts];
      newList.push(payout);
      setSelectedPendingPayouts(newList);
    } else {
      const newList = selectedPendingPayouts.filter((item) => {
        return item.currentId !== payout.currentId;
      });
      setSelectedPendingPayouts(newList);
    }
  };

  const handlePendingCheckAllClick = (checked: boolean) => {
    if (checked) {
      setSelectedPendingPayouts(pendingPayouts);
      setIsAllPendingChecked(true);
    } else {
      setSelectedPendingPayouts([]);
      setIsAllPendingChecked(false);
    }
  };

  const closeError = () => {
    setError(null);
    setErrorCreate(null);
    setErrorExecute(null);
  };

  return {
    calculatedPayouts,
    closeError: closeError,
    createPayoutsLoading: createPayoutsStatus.loading,
    executePendingPayoutsLoading: executePendingPayoutsStatus.loading,
    error: error,
    handleCalculatedCheckAllClick,
    handleCalculatedCheckboxClick,
    handleCreatePayouts,
    handleExecutePayouts,
    handlePendingCheckAllClick,
    handlePendingCheckboxClick,
    isAllCalculatedChecked,
    isAllPendingChecked,
    isLoading:
      getPayoutsCalculateStatus.loading && getPendingPayoutsStatus.loading,
    isCalculatedLoading: getPayoutsCalculateStatus.loading,
    isPendingLoading: getPendingPayoutsStatus.loading,
    pendingPayouts,
    selectedCaluclatedPayouts,
    selectedPendingPayouts,
    errorCreate,
    errorExecute,
    calculatedPayoutsSumData,
  };
};

export default usePayoutManagement;
