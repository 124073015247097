import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { OverviewPsRelation } from 'src/types/types';

import styles from './Row.module.scss';
import ActionsButton from 'src/admin/components/ActionsButton';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { AdminRouteCodes } from 'src/constants/routes';
import ActivityBox from 'src/admin/components/ActivityBox';

interface RowProps {
  data: OverviewPsRelation;
  deactivateRelation: (id: string) => void;
  isLoading: boolean;
  activity: string;
}

const Row: React.FC<RowProps> = ({
  data,
  deactivateRelation,
  isLoading,
}) => {
  const [actionsOpen, setActionsOpen] = useState(false);

  const toggleActionsOpen = (): void => {
    setActionsOpen(!actionsOpen);
  };

  return (
    <TableRow>
      <TableCell size="small">{data.studentName}</TableCell>
      <TableCell size="small">{data.classCount}</TableCell>
      <TableCell size="small">{data.formatedLastClassDate}</TableCell>
      <TableCell size="small">
        <ActivityBox activityValue={data.activity} />
      </TableCell>
      <TableCell size="small">{data.formatedRating}</TableCell>
      <TableCell size="small">{data.status}</TableCell>
      {isLoading ? (
        <TableCell size="small" align="left">
          <Button
            variant="outlined"
            color="primary"
            disabled
            className={styles.actionButton}
          >
            <CircularProgress color="inherit" size="18px" />
          </Button>
        </TableCell>
      ) : (
        <TableCell size="small" align="left">
          <ActionsButton
            isFullWidth
            className={styles.actionButton}
            toggleActionsOpen={toggleActionsOpen}
            actionsOpen={actionsOpen}
          >
            <MenuItem>
              <Link to={`${AdminRouteCodes.EDIT_RELATION}/${data.id}`}>
                Edit relation
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <button
                className={styles.deactivateButton}
                onClick={() => deactivateRelation(data.id)}
              >
                Deactivate
              </button>
            </MenuItem>
          </ActionsButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default React.memo(Row);
