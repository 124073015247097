import { DashboardStudent, FilterDates } from 'src/types/types';

export const formatDate = (date: Date): string => {
  const formatedDate = new Date(date);
  const day =
    formatedDate.getDate() < 10
      ? `0${formatedDate.getDate()}`
      : formatedDate.getDate();

  const month =
    formatedDate.getMonth() + 1 < 10
      ? `0${formatedDate.getMonth() + 1}`
      : formatedDate.getMonth() + 1;

  const year = formatedDate.getFullYear();

  return `${day}/${month}/${year}`;
};

export const formatDateAndTime = (date: string): string => {
  const formatedDate = new Date(date);
  const day =
    formatedDate.getDate() < 10
      ? `0${formatedDate.getDate()}`
      : formatedDate.getDate();

  const month =
    formatedDate.getMonth() + 1 < 10
      ? `0${formatedDate.getMonth() + 1}`
      : formatedDate.getMonth() + 1;

  const year = formatedDate.getFullYear();
  const hours =
    formatedDate.getHours() < 10
      ? `0${formatedDate.getHours()}`
      : formatedDate.getHours();

  const minutes =
    formatedDate.getMinutes() < 10
      ? `0${formatedDate.getMinutes()}`
      : formatedDate.getMinutes();

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const formatMoney = (amount: number, currency?: string): string => {
  const formatedAmount = (amount / 100)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${formatedAmount} ${currency ? currency : ''}`;
};


export const monthsDiff = (date: Date): number => {
  const currentDate = todayDate();
  const givenDate = new Date(date);
  const yearsDiff = currentDate.getFullYear() - givenDate.getFullYear();

  const months =
    yearsDiff * 12 + (currentDate.getMonth() - givenDate.getMonth());
  return months;
};

export const getDaysDiff = (date: Date): number => {
  const currentDate = todayDate();
  const givenDate = new Date(date);
  const diffTime = Math.abs(currentDate.getTime() - givenDate.getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const getDayBeforeNow = (numberOfDays: number): Date => {
  const currentDate = todayDate();
  const dayBeforeNow = new Date(
    currentDate.getTime() - numberOfDays * 24 * 60 * 60 * 1000,
  );

  return getFormatedDate(dayBeforeNow);
};

export const todayDate = (): Date => {
  const currentDate = new Date();
  currentDate.setHours(12, 0, 0, 0);
  return currentDate;
};

export const generateClassesURL = (
  URL: string,
  professorId: string | null,
  studentId: string | null,
  startDate: Date | null,
  endDate: Date | null,
): string => {
  let generatedURL = URL;

  if (professorId) {
    generatedURL = `${generatedURL}professorId=${professorId}&`;
  }
  if (studentId) {
    generatedURL = `${generatedURL}studentId=${studentId}&`;
  }
  if (startDate) {
    generatedURL = `${generatedURL}startDate=${startDate.getTime()}&`;
  }
  if (endDate) {
    generatedURL = `${generatedURL}endDate=${endDate.getTime()}`;
  }

  return generatedURL;
};

export const generatePayoutsURL = (
  URL: string,
  startDate: Date | null,
  endDate: Date | null,
): string => {
  let generatedURL = URL;

  if (startDate) {
    generatedURL = `${generatedURL}startDate=${startDate.getTime()}&`;
  }
  if (endDate) {
    generatedURL = `${generatedURL}endDate=${endDate.getTime()}`;
  }

  return generatedURL;
};

export const getDates = (query: URLSearchParams): FilterDates => {
  const startDateParam = query.get('startDate');
  const endDateParam = query.get('endDate');
  let startDate: Date | null;
  let endDate: Date | null;
  if (startDateParam === '' || startDateParam === null) {
    startDate = null;
  } else {
    startDate = new Date(parseInt(startDateParam));
  }

  if (endDateParam === '' || endDateParam === null) {
    endDate = null;
  } else {
    endDate = new Date(parseInt(endDateParam));
  }

  return {
    start: startDate,
    end: endDate,
  };
};

export const getEndDate = (date: Date): Date => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
  );
};

export const getStartDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 1);
};

export const getFormatedDate = (date: Date): Date => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12),
  );
};

export const sortStudentsByName = (
  list: DashboardStudent[],
): DashboardStudent[] => {
  return list.sort((student1, student2) => {
    const nameA = student1.name.toUpperCase();
    const nameB = student2.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};
