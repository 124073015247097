import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { WindowManager } from 'react-window-decorators';
import {
  RouteCodes,
  AdminRouteCodes,
  ProfessorRouteCodes,
} from 'src/constants/routes';
import breakpoints from 'src/constants/breakpoints';

import AddClass from 'src/admin/pages/AddClass';
import AllClasses from 'src/admin/pages/AllClasses';
import Dashboard from 'src/admin/pages/Dashboard';
import Payments from 'src/admin/pages/Payments';
import Professor from 'src/admin/pages/Professor';
import ProfessorInfo from 'src/admin/pages/ProfessorOverview';
import Student from 'src/admin/pages/Student';
import StudentAssign from 'src/admin/pages/StudentAssign';
import StudentPayment from 'src/admin/pages/StudentPayment';
import UserManagement from 'src/admin/pages/UserManagement';
import PayoutList from 'src/admin/pages/PayoutList';
import PayoutManagement from 'src/admin/pages/PayoutManagement';
import EditRelation from 'src/admin/pages/EditRelation';
import Analytics from 'src/admin/pages/Analytics';

import ProfessorClasses from 'src/professor/pages/Classes';
import ProfessorDashboard from 'src/professor/pages/Dashboard';

import Login from 'src/shared/pages/Login';
import PaypalPayment from 'src/shared/pages/PaypalPayment';
import NotFound from 'src/shared/pages/404';
import Unauthorized from 'src/shared/pages/Unauthorized';

import { UserRoles } from 'src/hooks/useAuth';
import AuthRoute from 'src/shared/components/AuthRoute';

import 'src/styles/index.scss';

import useRouter from './hooks/useRouter';

new WindowManager(breakpoints.formatted, 500);

declare global {
  interface Window {
    paypal: any;
  }
}

const App: React.FC = () => {
  const { getDefaultRoute } = useRouter();
  const defaultRoute = getDefaultRoute();

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={defaultRoute} />
      </Route>
      <Route path={RouteCodes.CHECKOUT} component={PaypalPayment} />
      <Route path={RouteCodes.LOGIN} component={Login} />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={AdminRouteCodes.DASHBOARD}
        Component={Dashboard}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={AdminRouteCodes.CLASSES}
        Component={AllClasses}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={AdminRouteCodes.PAYMENTS}
        Component={Payments}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.PROFESSOR}/:id?`}
        Component={Professor}
      />
      <AuthRoute
        exact
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.STUDENT}/:id?`}
        Component={Student}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={AdminRouteCodes.USER_MANAGEMENT}
        Component={UserManagement}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.STUDENT_ASSIGN}/:professorId`}
        Component={StudentAssign}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.ADD_CLASS}/:professorId`}
        Component={AddClass}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.STUDENT_PAYMENT}/:studentId`}
        Component={StudentPayment}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.EDIT_RELATION}/:relationId`}
        Component={EditRelation}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.PAYOUT_LIST}`}
        Component={PayoutList}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.PAYOUT_MANAGEMENT}`}
        Component={PayoutManagement}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.ANALYTICS}`}
        Component={Analytics}
      />
      <AuthRoute
        requiredRoles={[UserRoles.admin]}
        path={`${AdminRouteCodes.PROFESSOR_OVERVIEW}/:professorId`}
        Component={ProfessorInfo}
      />
      <AuthRoute
        requiredRoles={[UserRoles.professor]}
        path={`${ProfessorRouteCodes.DASHBOARD}`}
        Component={ProfessorDashboard}
      />
      <AuthRoute
        requiredRoles={[UserRoles.professor]}
        path={`${ProfessorRouteCodes.CLASSES}`}
        Component={ProfessorClasses}
      />
      <Route exact component={Unauthorized} to={RouteCodes.UNAUTHORIZE} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
