import React from 'react';
import cx from 'classnames';
import styles from './ActivityBox.module.scss';

interface ActivityBoxProps {
  activityValue: string;
}

const ActivityBox: React.FunctionComponent<ActivityBoxProps> = ({
  activityValue,
}) => {
  const topBoxClasses = cx({
    [styles.box]: true,
    [styles.box__green]: activityValue === 'HIGH',
  });

  const middleBoxClasses = cx({
    [styles.box]: true,
    [styles.box__yellow]: activityValue === 'MEDIUM',
    [styles.box__green]: activityValue === 'HIGH',
  });

  const bottomBoxClasses = cx({
    [styles.box]: true,
    [styles.box__red]: activityValue === 'LOW',
    [styles.box__yellow]: activityValue === 'MEDIUM',
    [styles.box__green]: activityValue === 'HIGH',
  });

  return (
    <div className={styles.wrapper}>
      <div className={topBoxClasses} />
      <div className={middleBoxClasses} />
      <div className={bottomBoxClasses} />
    </div>
  );
};

export default ActivityBox;
