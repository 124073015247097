import { Payout } from 'src/types/types';
import fetch from 'src/utils/fetch';

const getPayouts = (
  startDate: Date | null,
  endDate: Date | null,
): Promise<any> => {
  const startDateTime = startDate && new Date(startDate).getTime();
  const endDateTime = endDate && new Date(endDate).getTime();

  const url = `${process.env.REACT_APP_API_URL}/adminApp/payouts?startDate=${startDateTime}&endDate=${endDateTime}`;

  return fetch({ url });
};

const getPayoutsCalculate = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/payouts/calculate`;

  return fetch({ url });
};

const createPayouts = (payouts: Payout[]): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/payouts/bulk`;

  return fetch({ url, data: payouts, method: 'POST' });
};

const getPendingPayouts = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/payouts?status=pending`;

  return fetch({ url });
};

const executePendingPayouts = (payouts: number[]): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/payouts/finalize`;

  return fetch({ url, data: payouts, method: 'POST' });
};

export default {
  getPayouts,
  getPayoutsCalculate,
  createPayouts,
  getPendingPayouts,
  executePendingPayouts,
};
