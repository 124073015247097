import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { RouteCodes } from 'src/constants/routes';
interface Props {
  Component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  requiredRoles: string[];
}

const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRoles,
}: Props): JSX.Element => {
  const { isAuthenticated, getUserRole } = useAuth();
  const isAuth = isAuthenticated();
  const userRole = getUserRole();
  const hasRequiredRole = requiredRoles.includes(userRole);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) =>
        isAuth && hasRequiredRole ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={hasRequiredRole ? RouteCodes.LOGIN : RouteCodes.UNAUTHORIZE}
          />
        )
      }
    />
  );
};

export default AuthRoute;
