import axios, { AxiosRequestConfig } from 'axios';

const fetch = ({
  method = 'get',
  data = null,
  url,
}: AxiosRequestConfig): Promise<any> => {
  const token = localStorage.getItem('token');

  return axios({
    method: method,
    url: url,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default fetch;
