import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { ClassDetails } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import styles from './Row.module.scss';

interface RowProps {
  data: ClassDetails;
  index: number;
}

const Row: React.FC<RowProps> = ({ data, index }) => {
  const {
    date,
    studentName,
    duration,
    professorPrice,
    isTrial,
    professorCurrency,
  } = data;

  return (
    <TableRow>
      <TableCell size="small">{index}</TableCell>
      <TableCell size="small">{studentName}</TableCell>
      <TableCell size="small">{formatDate(date)}</TableCell>
      <TableCell size="small">{duration}</TableCell>
      <TableCell size="small">{formatMoney(professorPrice)}</TableCell>
      <TableCell size="small">{professorCurrency}</TableCell>
      <TableCell size="small" className={styles.iconCell}>
        {isTrial && (
          <Tooltip
            title={'Ovo je probni čas'}
            classes={{ tooltip: styles.tooltip }}
          >
            <BookmarkIcon />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
