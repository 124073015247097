const headerItems = [
  {
    text: 'ID',
    props: {
      width: '4%',
    },
  },
  {
    text: 'Professor',
    props: {
      width: '20%',
    },
  },
  {
    text: 'Student',
    props: {
      width: '20%',
    },
  },
  {
    text: 'Date',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Duration',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Prof. Price',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Price',
    props: {
      width: '13%',
    },
  },
  {
    text: 'Comment',
    props: {
      width: '8%',
    },
  },
];

export default headerItems;
