import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { OverviewPsRelation } from 'src/types/types';
import Row from './Row';
import styles from './TableSmall.module.scss';

interface TableSmallProps {
  deactivateRelation: (id: string) => void;
  data: OverviewPsRelation[];
  deactivateRelationLoading: boolean;
  chosenRelationId?: string;
  activity: string;
}

const TableSmall: React.FC<TableSmallProps> = ({
  data,
  deactivateRelation,
  deactivateRelationLoading,
  chosenRelationId,
  activity,
}) => {
  return (
    <TableContainer className={styles.wrapper}>
      <Table>
        <TableBody className={styles.tableBody}>
          {data.map((relation: OverviewPsRelation) => (
            <Row
              key={relation.id}
              data={relation}
              deactivateRelation={deactivateRelation}
              activity={activity}
              isLoading={Boolean(
                deactivateRelationLoading &&
                  chosenRelationId &&
                  chosenRelationId === relation.id,
              )}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableSmall);
