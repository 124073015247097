import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FlagIcon from '@material-ui/icons/Flag';
import Tooltip from '@material-ui/core/Tooltip';

import ActionsButton from 'src/admin/components/ActionsButton';
import { DEACTIVATE, REACTIVATE, MIGRATE } from 'src/constants/literals';
import { AdminRouteCodes } from 'src/constants/routes';
import { DashboardStudent } from 'src/types/types';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import styles from './Row.module.scss';
import Button from '@material-ui/core/Button';

interface RowProps {
  data: DashboardStudent;
  inactive?: boolean;
  updateStudentActivity: (id: string, active: boolean) => void;
  actionButtonLoading: boolean;
  migrate: (id: string) => void;
}

const Row: React.FC<RowProps> = ({
  data,
  inactive,
  updateStudentActivity,
  actionButtonLoading,
  migrate,
}) => {
  const {
    name,
    language,
    classCount,
    formatedLastClassDate,
    credit,
    formatedCredit,
    formatedLastPaymentDate,
    formatedTotalAmountPaid,
    error,
    showClockIcon,
    commissionBased,
    email,
  } = data;
  const [actionsOpen, setActionsOpen] = useState(false);

  const toggleActionsOpen = (): void => {
    setActionsOpen(!actionsOpen);
  };

  const rowClasses = cx(
    { [styles.row__red]: classCount > 0 && credit < 0 },
    { [styles.row__yellow]: classCount > 0 && credit === 0 },
  );

  return (
    <TableRow className={rowClasses}>
      <TableCell size="small" className={styles.signCell}>
        {showClockIcon && !inactive && !error && <HourglassEmptyIcon />}
        {error && (
          <Tooltip title={error} classes={{ tooltip: styles.tooltip }}>
            <ErrorOutlineOutlinedIcon color="error" />
          </Tooltip>
        )}
      </TableCell>
      <TableCell size="small">{name}</TableCell>
      <TableCell size="small">{language}</TableCell>
      <TableCell size="small">{formatedLastClassDate}</TableCell>
      <TableCell size="small">{classCount}</TableCell>
      <TableCell size="small">{formatedTotalAmountPaid}</TableCell>
      <TableCell size="small">{formatedCredit}</TableCell>
      <TableCell size="small">{formatedLastPaymentDate}</TableCell>
      <TableCell size="small" className={styles.flagWrapper}>
        {!commissionBased && <FlagIcon />}
      </TableCell>
      {actionButtonLoading ? (
        <TableCell size="small" align="center">
          <Button
            variant="outlined"
            color="primary"
            disabled
            className={styles.loadingButton}
          >
            <CircularProgress color="inherit" size="18px" />
          </Button>
        </TableCell>
      ) : (
        <TableCell size="small" align="center">
          {inactive ? (
            <Button
              variant="outlined"
              color="primary"
              className={styles.reactivateButton}
              onClick={() => updateStudentActivity(data.id, true)}
            >
              <span>{REACTIVATE}</span>
            </Button>
          ) : (
            <ActionsButton
              isFullWidth
              toggleActionsOpen={toggleActionsOpen}
              actionsOpen={actionsOpen}
            >
              <MenuItem>
                <Link to={`${AdminRouteCodes.STUDENT_PAYMENT}/${data.id}`}>
                  New Payment
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Link to={`${AdminRouteCodes.PAYMENTS}?studentId=${data.id}`}>
                  View Payments
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={`${AdminRouteCodes.CLASSES}?studentId=${data.id}`}>
                  View Classes
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem>
                <Link to={`${AdminRouteCodes.STUDENT}/${data.id}`}>
                  Edit Student
                </Link>
              </MenuItem>
              <MenuItem
                className={styles.button}
                component={Button}
                onClick={() => updateStudentActivity(data.id, false)}
              >
                <span>{DEACTIVATE}</span>
              </MenuItem>
              <Divider />
              <MenuItem>
                <button
                  className={styles.copyEmailButton}
                  onClick={() => {
                    navigator.clipboard.writeText(email);
                    toggleActionsOpen();
                  }}
                >
                  Copy Email
                </button>
              </MenuItem>
              {!commissionBased && (
                <span>
                  <Divider />
                  <MenuItem
                    className={styles.button}
                    component={Button}
                    onClick={() => migrate(data.id)}
                  >
                    <span>{MIGRATE}</span>
                  </MenuItem>
                </span>
              )}
            </ActionsButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default React.memo(Row);
