import React from 'react';
import { OverviewPsRelation } from 'src/types/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableContainer } from '@material-ui/core';
import TableHeader from 'src/shared/components/TableHeader';
import headerItems from './HeaderItems';
import Row from './Row';

import styles from './TableLarge.module.scss';

interface TableLargeProps {
  activateRelation: (id: string) => void;
  data: OverviewPsRelation[];
  activateRelationLoading: boolean;
  chosenRelationId?: string;
}

const TableLarge: React.FC<TableLargeProps> = ({
  data,
  activateRelation,
  activateRelationLoading,
  chosenRelationId,
}) => {
  return (
    <TableContainer className={styles.wrapper}>
      <Table>
        <TableHeader items={headerItems} />
        <TableBody>
          {data.map((relation: OverviewPsRelation) => (
            <Row
              key={relation.id}
              data={relation}
              activateRelation={activateRelation}
              isLoading={Boolean(
                activateRelationLoading &&
                  chosenRelationId &&
                  chosenRelationId === relation.id,
              )}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableLarge);
