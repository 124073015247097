import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Professor, ProfessorStudent, Student } from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import professor from 'src/api/professors';
import student from 'src/api/student';
import { AdminRouteCodes } from 'src/constants/routes';
import { useHistory, useParams } from 'react-router-dom';

interface Data {
  onSubmit: (data: ProfessorStudent) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
  studentsList: Student[];
}

interface Params {
  professorId: string;
}

const formatDataForForm = (professor: Professor) => {
  return {
    professorName: professor.name,
    language: professor.language,
    professorRemark: professor.remark,
    price45: professor.price45 / 100,
    price60: professor.price60 / 100,
    price90: professor.price90 / 100,
    currency: professor.currency,
    professorCurrency: professor.professorCurrency,
    professorPrice45: professor.professorPrice45 / 100,
    professorPrice60: professor.professorPrice60 / 100,
    professorPrice90: professor.professorPrice90 / 100,
  };
};

const formatDataForSave = (
  data: any,
  professorId: string,
  studentName: string,
) => {
  return {
    studentId: data.student.id,
    studentName: studentName,
    professorId: professorId,
    status: data.isTrial ? 'TRIAL' : 'ACTIVE',
    price30: data.price30 * 100,
    price45: data.price45 * 100,
    price60: data.price60 * 100,
    price90: data.price90 * 100,
    currency: data.currency,
    professorPrice30: data.professorPrice30 * 100,
    professorPrice45: data.professorPrice45 * 100,
    professorPrice60: data.professorPrice60 * 100,
    professorPrice90: data.professorPrice90 * 100,
    professorCurrency: data.professorCurrency,
    remark: data.remark,
  };
};

const useStudentAssign = (): Data => {
  const [studentsList, setStudentsList] = useState<Student[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const { professorId } = useParams<Params>();
  const { handleSubmit, errors, control, reset } = useForm();

  const onAssignStudentComplete = () => {
    history.push(AdminRouteCodes.DASHBOARD);
  };

  const [assignStudent, createProfessorStatus] = useAsyncRequest<
    [ProfessorStudent]
  >(professor.assignStudent, {
    onComplete: () => {
      onAssignStudentComplete();
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const [getProfessorById, getProfessorByIdStatus] = useAsyncRequest<[string]>(
    professor.getProfessorById,
    {
      onComplete: (data: Professor) => {
        reset(formatDataForForm(data));
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [getAllStudents, getAllStudentsStatus] = useAsyncRequest<[]>(
    student.getAllStudents,
    {
      onComplete: (data: Student[]) => {
        const students = data.map((student: Student) => {
          return {
            ...student,
            active: Object.prototype.hasOwnProperty.call(student, 'active')
              ? student.active
              : true,
          };
        });
        const activeStudents = students.filter((student) => student.active);
        setStudentsList(activeStudents);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const closeError = () => {
    setError(null);
    history.push(AdminRouteCodes.DASHBOARD);
  };

  useEffect(() => {
    if (professorId) {
      getProfessorById(professorId);
      getAllStudents();
    }
  }, [professorId]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (professorId && student) {
        assignStudent(formatDataForSave(data, professorId, data.student.name));
      }
    },

    [professorId, studentsList],
  );

  return {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading:
      getProfessorByIdStatus.loading ||
      getAllStudentsStatus.loading ||
      createProfessorStatus.loading,
    apiError: error,
    closeError,
    studentsList,
  };
};

export default useStudentAssign;
