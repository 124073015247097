import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { ProfessorRouteCodes, AdminRouteCodes } from 'src/constants/routes';

import ToolbarSmall from './ToolbarSmall';
import ToolbarLarge from './ToolbarLarge';
import useAuth from 'src/hooks/useAuth';
import styles from './NavigationBar.module.scss';

const adminNavLinks = [
  {
    title: 'Dashboard',
    path: AdminRouteCodes.DASHBOARD,
  },
  {
    title: 'Analytics',
    path: AdminRouteCodes.ANALYTICS,
  },
  {
    title: 'Classes',
    path: AdminRouteCodes.CLASSES,
    setDate: true,
  },
  {
    title: 'Payments',
    path: AdminRouteCodes.PAYMENTS,
    setDate: true,
  },
  {
    title: 'Payout',
    path: AdminRouteCodes.PAYOUT,
  },
  {
    title: 'User Management',
    path: AdminRouteCodes.USER_MANAGEMENT,
  },
];

const adminPayoutsNavLinks = [
  {
    title: 'Management',
    path: AdminRouteCodes.PAYOUT_MANAGEMENT,
  },
  {
    title: 'List',
    path: AdminRouteCodes.PAYOUT_LIST,
    setDate: true,
  },
];

const adminPayoutsNavLinksMobile = [
  {
    title: 'Payouts Management',
    path: AdminRouteCodes.PAYOUT_MANAGEMENT,
  },
  {
    title: 'Payouts List',
    path: AdminRouteCodes.PAYOUT_LIST,
    setDate: true,
  },
];

const professorNavLinks = [
  {
    title: 'Home',
    path: ProfessorRouteCodes.DASHBOARD,
  },
  {
    title: 'All Classes',
    path: ProfessorRouteCodes.CLASSES,
    setDate: true,
  },
];

const NavigationBar: React.FC = () => {
  const { getUserData } = useAuth();
  const userData = getUserData();

  return (
    <AppBar position="static" className={styles.bar}>
      {/* Visible on large breakpoint */}
      <ToolbarLarge
        userEmail={userData && userData.email}
        navLinks={
          userData && userData.isAdmin ? adminNavLinks : professorNavLinks
        }
        payoutsNavLinks={
          userData && userData.isAdmin ? adminPayoutsNavLinks : []
        }
      />
      {/* Visible on small and medium breakpoints */}
      <ToolbarSmall
        userEmail={userData && userData.email}
        navLinks={
          userData && userData.isAdmin ? adminNavLinks : professorNavLinks
        }
        payoutsNavLinks={
          userData && userData.isAdmin ? adminPayoutsNavLinksMobile : []
        }
      />
    </AppBar>
  );
};

export default NavigationBar;
