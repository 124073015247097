import React from 'react';
import ProfessorForm from 'src/admin/components/ProfessorForm';
import Layout from 'src/shared/components/Layout';
import useProfessor from './Professor.hooks';

const Professor: React.FC = () => {
  const {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    isUpdate,
    apiError,
    closeError,
  } = useProfessor();

  return (
    <Layout>
      <ProfessorForm
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        isUpdate={isUpdate}
        isLoading={isLoading}
        apiError={apiError}
        closeError={closeError}
      />
    </Layout>
  );
};

export default Professor;
