import React from 'react';
import { AnalyticsData } from 'src/types/types';
import { formatMoney } from 'src/utils/helpers';
import ScoreCard from '../../components/ScoreCard';

import styles from './Last30DaysCards.module.scss';

interface Last30DaysCardsProps {
  data: AnalyticsData;
}

const Last30DaysCards: React.FC<Last30DaysCardsProps> = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <ScoreCard title="Total Classes">{data.classCount}</ScoreCard>
      <ScoreCard title="Active Students">{data.activeStudentsCount}</ScoreCard>
      <ScoreCard title="Active Profs">{data.activeProfessorsCount}</ScoreCard>
      <ScoreCard title="New Students">{data.newStudentsCount}</ScoreCard>
      <ScoreCard title="Payments">
        {formatMoney(parseInt(data.newPaymentsTotal), data.newPaymentsCurrency)}
      </ScoreCard>
      <ScoreCard title="Profit">
        {formatMoney(parseInt(data.newProfitTotal), data.newProfitCurrency)}
      </ScoreCard>
    </div>
  );
};

export default Last30DaysCards;
