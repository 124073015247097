import React from 'react';
import { withWindow } from 'react-window-decorators';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HeaderCell from 'src/shared/components/HeaderCell';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './TableHeader.module.scss';

interface Props {
  items: any[];
  itemClassName?: string;
  hasSelectAll?: boolean;
  breakpoint: string;
  handleCheckboxClick?: (checked: boolean) => void;
  isCheckboxChecked?: boolean;
}

const TableHeader: React.FC<Props> = ({
  items,
  itemClassName,
  hasSelectAll,
  breakpoint,
  handleCheckboxClick,
  isCheckboxChecked,
}) => {
  let headerCellItems = items;
  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  if (hasSelectAll) {
    if (isSmallOrMedium) {
      headerCellItems = items.slice(0, items.length - 1);
    } else {
      headerCellItems = items.slice(1, items.length);
    }
  }

  return (
    <TableHead>
      <TableRow>
        {hasSelectAll && !isSmallOrMedium && handleCheckboxClick && (
          <th
            scope="col"
            className={styles.checkboxCell}
            style={{
              ...items[0].props,
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Checkbox
              color="primary"
              checked={isCheckboxChecked}
              onChange={(e) => handleCheckboxClick(e.target.checked)}
              className={styles.checkbox}
            />
          </th>
        )}
        {headerCellItems.map((item: any) => {
          return (
            <HeaderCell
              className={itemClassName}
              {...item.props}
              key={item.id ? item.id : item.text}
              text={item.text}
            />
          );
        })}
        {hasSelectAll && isSmallOrMedium && handleCheckboxClick && (
          <th
            scope="col"
            className={styles.checkboxCell}
            style={{
              ...items[0].props,
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
            }}
          >
            <Checkbox
              color="primary"
              checked={isCheckboxChecked}
              onChange={(e) => handleCheckboxClick(e.target.checked)}
              className={styles.checkbox}
            />
          </th>
        )}
      </TableRow>
    </TableHead>
  );
};

export default withWindow(TableHeader);
