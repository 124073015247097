import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  CANCEL,
  CREATE,
  CREATE_NEW_USER,
  ERROR_POP_UP_TEXT,
} from 'src/constants/literals';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useCreateNewUserForm from './CreateNewUserForm.hooks';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import styles from './CreateNewUserForm.module.scss';

interface CreateNewUserFormProps {
  email: string;
  professorId: string;
  closeModal: () => void;
  getUsersAndProfessors: () => void;
}

const CreateNewUserForm = React.forwardRef<
  HTMLDivElement,
  CreateNewUserFormProps
>((props, ref) => {
  const { email, professorId, closeModal, getUsersAndProfessors } = props;
  const {
    password,
    setPassword,
    createProfessorUser,
    isLoading,
    error,
    closeError,
  } = useCreateNewUserForm(
    email,
    professorId,
    closeModal,
    getUsersAndProfessors,
  );

  return (
    <Paper ref={ref} elevation={3} className={styles.wrapper} tabIndex={-1}>
      <div className={styles.topContent}>
        <Typography component="h1" variant="h4">
          {CREATE_NEW_USER}
        </Typography>
      </div>
      <form>
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          required
          fullWidth
          margin="normal"
          value={email}
          disabled
          className={styles.input}
        />
        <TextField
          id="passwrod"
          label="Password"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.createButton}
            onClick={() => createProfessorUser(email, password)}
            fullWidth
            variant="contained"
            color="primary"
            disabled={password.length === 0 || isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              <span>{CREATE}</span>
            )}
          </Button>
          <Button
            onClick={closeModal}
            fullWidth
            variant="contained"
            color="secondary"
          >
            {CANCEL}
          </Button>
        </div>
      </form>
      {error && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </Paper>
  );
});

CreateNewUserForm.displayName = 'CreateNewUserForm';

export default CreateNewUserForm;
