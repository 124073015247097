import fetch from 'src/utils/fetch';

const getAnalytics = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/analytics`;

  return fetch({ url });
};

export default {
  getAnalytics,
};
