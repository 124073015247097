import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import Row from './Row';
import { DashboardStudent } from 'src/types/types';

import styles from './TableSmall.module.scss';

interface TableSmallProps {
  studentsData: DashboardStudent[];
  updateStudentActivity: (id: string, active: boolean) => void;
  actionButtonLoading: boolean;
  migrate: (id: string) => void;
  studentUpdatedId: string | null;
  isInactive: boolean;
}

const TableSmall: React.FC<TableSmallProps> = ({
  studentsData,
  updateStudentActivity,
  actionButtonLoading,
  migrate,
  studentUpdatedId,
  isInactive,
}) => {
  return (
    <TableContainer className={styles.wrapper}>
      <Table>
        <TableBody className={styles.tableBody}>
          {studentsData.map((student: DashboardStudent) => {
            return (
              <Row
                key={student.id}
                data={student}
                inactive={isInactive}
                updateStudentActivity={updateStudentActivity}
                actionButtonLoading={
                  studentUpdatedId === student.id && actionButtonLoading
                }
                migrate={migrate}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableSmall);
