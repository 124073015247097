import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { ClassDetails } from 'src/types/types';
import useSumTableInfo from './useSumTableInfo.hooks';
import { formatMoney } from 'src/utils/helpers';
import TableRow from '@material-ui/core/TableRow';

import styles from './SumTableInfo.module.scss';

interface SumTableInfoProps {
  data: ClassDetails[];
}

const SumTableInfo: React.FC<SumTableInfoProps> = ({ data }) => {
  const { sumData } = useSumTableInfo(data);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="small" className={styles.headerCell}>
            Total
          </TableCell>
          <TableCell size="small" className={styles.headerCell}>
            Professors Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell size="small">
            {formatMoney(sumData.totalEUR, 'EUR')}
          </TableCell>
          <TableCell size="small">
            {formatMoney(sumData.profTotalEUR, 'EUR')}
          </TableCell>
        </TableRow>
      </TableBody>
      <TableBody>
        <TableRow>
          <TableCell size="small">
            {formatMoney(sumData.totalRSD, 'RSD')}
          </TableCell>
          <TableCell size="small">
            {formatMoney(sumData.profTotalRSD, 'RSD')}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default SumTableInfo;
