export enum RouteCodes {
  LOGIN = '/login',
  UNAUTHORIZE = '/unauthorized',
  CHECKOUT = '/checkout',
}

export enum AdminRouteCodes {
  DASHBOARD = '/admin/dashboard',
  CLASSES = '/admin/classes',
  PAYMENTS = '/admin/payments',
  PROFESSOR = '/admin/professor',
  PROFESSOR_OVERVIEW = '/admin/professor-overview',
  STUDENT = '/admin/student',
  USER_MANAGEMENT = '/admin/user-management',
  STUDENT_ASSIGN = '/admin/student-assign',
  ADD_CLASS = '/admin/add-class',
  STUDENT_PAYMENT = '/admin/student-payment',
  PAYOUT_LIST = '/admin/payout-list',
  PAYOUT_MANAGEMENT = '/admin/payout-management',
  EDIT_RELATION = '/admin/edit-relation',
  ANALYTICS = '/admin/analytics',
  PAYOUT = '/admin/payout',
}

export enum ProfessorRouteCodes {
  DASHBOARD = '/professor/dashboard',
  CLASSES = '/professor/classes',
}
