import React from 'react';

import styles from 'src/admin/components/TabPanel/TabPanel.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index }: TabPanelProps): JSX.Element => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      className={styles.tabPanel}
    >
      {children}
    </div>
  );
};

export default TabPanel;
