import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { ClassDetails } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';
import styles from './Row.module.scss';

interface RowProps {
  data: ClassDetails;
}

const Row: React.FC<RowProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const {
    currency,
    date,
    duration,
    id,
    price,
    professorCurrency,
    professorName,
    professorPrice,
    remark,
    studentName,
    commissionBased,
  } = data;

  return (
    <>
      <TableRow>
        <TableCell size="small" width="5%" className={styles.mainCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          size="small"
          width="35%"
          scope="row"
          className={styles.mainCell}
        >
          {professorName}
        </TableCell>
        <TableCell
          size="small"
          width="35%"
          scope="row"
          className={styles.mainCell}
        >
          {studentName}
        </TableCell>
        <TableCell
          size="small"
          width="25%"
          scope="row"
          className={styles.mainCell}
        >
          {formatDate(date)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      ID
                    </TableCell>
                    <TableCell size="small">{id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Duration
                    </TableCell>
                    <TableCell size="small">{duration}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Professor Price
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(professorPrice, professorCurrency)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Price
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(price, currency)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Comment
                    </TableCell>
                    <TableCell size="small">{remark}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Commision based
                    </TableCell>
                    <TableCell size="small">
                      {commissionBased ? 'True' : 'False'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(Row);
