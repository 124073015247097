import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { FilterDates } from 'src/types/types';
import Button from '@material-ui/core/Button';

import styles from './DatesFilterBar.module.scss';
import { todayDate } from 'src/utils/helpers';

interface DatesFilterBarProps {
  filterDates: FilterDates;
  handleFilteringData: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
}

const DatesFilterBar: React.FC<DatesFilterBarProps> = ({
  filterDates,
  handleFilteringData,
  changeFilterDate,
}) => {
  if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
  }

  return (
    <div className={styles.wrapper}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <KeyboardDatePicker
          className={styles.datePicker}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="Start Date"
          autoOk={true}
          value={filterDates.start || null}
          onChange={(date) => changeFilterDate(date, 'start')}
          KeyboardButtonProps={{
            'aria-label': 'change start date',
          }}
        />
        <KeyboardDatePicker
          className={styles.datePicker}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          label="End Date"
          autoOk={true}
          value={filterDates.end || null}
          maxDate={todayDate()}
          onChange={(date) => changeFilterDate(date, 'end')}
          KeyboardButtonProps={{
            'aria-label': 'change end date',
          }}
        />
      </MuiPickersUtilsProvider>
      <Button
        className={styles.filterButton}
        variant="outlined"
        color="primary"
        onClick={handleFilteringData}
      >
        Filter
      </Button>
    </div>
  );
};

export default DatesFilterBar;
