import React from 'react';

import Layout from 'src/shared/components/Layout';
import UserManagementTableLarge from 'src/admin/components/Tables/UserManagement/TableLarge';
import UserManagementTableSmall from 'src/admin/components/Tables/UserManagement/TableSmall';

import useUserManagement from './UserManagement.hooks';

const UsersManagement: React.FC = () => {
  const {
    getUsersAndProfessors,
    userList,
    isLoading,
    error,
    closeError,
  } = useUserManagement();

  return (
    <Layout isLoading={isLoading} error={error} closeError={closeError}>
      <UserManagementTableSmall
        data={userList}
        getUsersAndProfessors={getUsersAndProfessors}
      />
      <UserManagementTableLarge
        data={userList}
        getUsersAndProfessors={getUsersAndProfessors}
      />
    </Layout>
  );
};

export default UsersManagement;
