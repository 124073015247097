import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Payout } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './Row.module.scss';

interface RowProps {
  data: Payout;
  handleCheckboxClick: (payout: Payout, checked: boolean) => void;
  checked: boolean;
}

const Row: React.FC<RowProps> = ({ data, handleCheckboxClick, checked }) => {
  const {
    classCount,
    currency,
    periodEndDate,
    periodStartDate,
    professorName,
    total,
    totalAfterCommission,
    totalCommission,
    commissionBased,
  } = data;

  return (
    <TableRow>
      <TableCell size="small">
        <Checkbox
          className={styles.checkbox}
          color="primary"
          checked={checked}
          onChange={(e) => handleCheckboxClick(data, e.target.checked)}
        />
      </TableCell>
      <TableCell size="small" className={styles.name}>
        {professorName}
      </TableCell>
      <TableCell size="small">{`${formatDate(periodStartDate)} - ${formatDate(
        periodEndDate,
      )}`}</TableCell>
      <TableCell size="small">{classCount}</TableCell>
      <TableCell size="small">{formatMoney(total)}</TableCell>
      <TableCell size="small">{formatMoney(totalCommission)}</TableCell>
      <TableCell size="small">{formatMoney(totalAfterCommission)}</TableCell>
      <TableCell size="small">{currency}</TableCell>
      <TableCell size="small">{commissionBased ? 'Yes' : 'No'}</TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
