import React from 'react';
import Button from '@material-ui/core/Button';
import Input from 'src/shared/components/Input';
import styles from './PaymentForm.module.scss';
import Select from 'src/admin/components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Country } from 'src/shared/pages/PaypalPayment/PaypalPayment.hook';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Controller } from 'react-hook-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import {
  ADDRESS_ERROR_MESSAGE,
  CANCEL_BUTTON,
  CITY_ERROR_MESSAGE,
  COUNTRY_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  FIRST_NAME_ERROR_MESSAGE,
  LAST_NAME_ERROR_MESSAGE,
  MOBILE_PHONE_ERROR_MESSAGE,
  PAYMENT_FORM_BUTTON_TEXT,
  PAYMENT_VALUE_ERROR_MESSAGE,
  POSTAL_CODE_ERROR_MESSAGE,
} from 'src/constants/literals';
import InputAdornment from '@material-ui/core/InputAdornment';

interface PaymentForm {
  onSubmit: (data: any) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  countryList: Country[];
  areaCode: string;
  customPaymentValue: string;
  choosenValue: string | null;
  resetData: () => void;
  handleCustomValueClick: () => void;
  isCustomValueRadionButtonChecked: boolean;
}

const PaymentForm: React.FunctionComponent<PaymentForm> = ({
  handleSubmit,
  onSubmit,
  errors,
  control,
  countryList,
  customPaymentValue,
  choosenValue,
  resetData,
  handleCustomValueClick,
  isCustomValueRadionButtonChecked,
}) => {
  return (
    <div className={styles.wrapper}>
      {/* {Uncomment if you want explanation for payment form} */}
      {/* <Typography component="p" className={styles.explanation}>
        {CHECKOUT_PAYMENT_FORM_EXPLANATION}
      </Typography> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.content}>
          <Input
            defaultValue=""
            control={control}
            rules={{ required: true }}
            name="firstName"
            className={styles.halfWidth}
            label="Ime *"
            margin="dense"
            error={!!errors.firstName}
            helperText={!!errors.firstName && FIRST_NAME_ERROR_MESSAGE}
            variant="outlined"
          />
          <Input
            defaultValue=""
            control={control}
            rules={{ required: true }}
            name="lastName"
            className={styles.halfWidth}
            label="Prezime *"
            margin="dense"
            error={!!errors.lastName}
            helperText={!!errors.lastName && LAST_NAME_ERROR_MESSAGE}
            variant="outlined"
          />
          <Input
            defaultValue=""
            control={control}
            rules={{
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            }}
            name="email"
            className={styles.fullWidth}
            label="Email *"
            margin="dense"
            error={!!errors.email}
            helperText={!!errors.email && EMAIL_ERROR_MESSAGE}
            variant="outlined"
          />
          <Select
            className={styles.halfWidth}
            error={!!errors.country}
            name="country"
            label="Država *"
            control={control}
            errorText={COUNTRY_ERROR_MESSAGE}
            defaultValue="RS"
          >
            {countryList.map((country: any): any => {
              return (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              );
            })}
          </Select>
          <Input
            defaultValue=""
            placeholder="+381648888888"
            control={control}
            rules={{ required: true, pattern: /^[+][0-9]*$/i }}
            name="phone"
            className={styles.phone}
            label="Broj mobilnog telefona *"
            margin="dense"
            error={!!errors.phone}
            helperText={!!errors.phone && MOBILE_PHONE_ERROR_MESSAGE}
            variant="outlined"
          />
          <Input
            defaultValue=""
            control={control}
            rules={{ required: true }}
            name="address"
            className={styles.fullWidth}
            label="Adresa *"
            margin="dense"
            error={!!errors.address}
            helperText={!!errors.address && ADDRESS_ERROR_MESSAGE}
            variant="outlined"
          />
          <Input
            defaultValue=""
            control={control}
            rules={{ required: true }}
            name="postalCode"
            className={styles.halfWidth}
            label="Poštanski kod *"
            margin="dense"
            error={!!errors.postalCode}
            helperText={!!errors.postalCode && POSTAL_CODE_ERROR_MESSAGE}
            variant="outlined"
          />
          <Input
            defaultValue=""
            control={control}
            rules={{ required: true }}
            name="city"
            className={styles.halfWidth}
            label="Grad *"
            margin="dense"
            error={!!errors.city}
            helperText={!!errors.city && CITY_ERROR_MESSAGE}
            variant="outlined"
          />
          <FormControl component="fieldset" className={styles.paymentValue}>
            <FormLabel component="legend">
              Izaberite ili unesite iznos koji želite da uplatite
            </FormLabel>
            <Controller
              rules={{ required: !choosenValue }}
              control={control}
              defaultValue={null}
              name="amount"
              as={
                <RadioGroup>
                  <FormControlLabel
                    value="30"
                    control={<Radio color="primary" />}
                    label="€ 30"
                  />
                  <FormControlLabel
                    value="50"
                    control={<Radio color="primary" />}
                    label="€ 50"
                  />
                  <FormControlLabel
                    value="100"
                    control={<Radio color="primary" />}
                    label="€ 100"
                  />
                </RadioGroup>
              }
            />
            <Controller
              rules={{ required: !choosenValue }}
              control={control}
              name="customPaymentValue"
              defaultValue={''}
              render={({ onChange, value, name, ref }: any) => {
                const handleRadioButtonClick = () => {
                  handleCustomValueClick();
                  ref.current.focus();
                };

                return (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          !!customPaymentValue ||
                          isCustomValueRadionButtonChecked
                        }
                        color="primary"
                        onClick={handleRadioButtonClick}
                      />
                    }
                    label={
                      <TextField
                        className={styles.customPayment}
                        name={name}
                        inputRef={ref}
                        onChange={onChange}
                        value={value}
                        placeholder="Unesite drugi iznos (npr 40) "
                        onFocus={handleCustomValueClick}
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              className={styles.adornment}
                              position="start"
                            >
                              €
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                );
              }}
            />
            {!choosenValue && (errors.amount || errors.customPaymentValue) && (
              <span className={styles.error}>
                {PAYMENT_VALUE_ERROR_MESSAGE}
              </span>
            )}
          </FormControl>
        </div>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          type="submit"
        >
          <span>{PAYMENT_FORM_BUTTON_TEXT}</span>
        </Button>
        <Button
          className={styles.button}
          variant="contained"
          color="default"
          onClick={resetData}
        >
          <span>{CANCEL_BUTTON}</span>
        </Button>
      </form>
    </div>
  );
};

export default PaymentForm;
