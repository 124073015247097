import React, { useState } from 'react';
import { Typography, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import logo from 'src/assets/images/logo.png';
import useAuth from 'src/hooks/useAuth';
import { WELCOME, LOG_IN } from 'src/constants/literals';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import styles from './Login.module.scss';

const Login: React.FC = () => {
  const { login, isLoading, error, closeError } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Container component="main" maxWidth="xs">
      <div className={styles.topContent}>
        <img src={logo} alt="Lango logo" />
        <Typography component="h1" variant="h4">
          {WELCOME}
        </Typography>
      </div>
      {error && (
        <Alert severity="error" onClose={closeError}>
          Login Failed
        </Alert>
      )}
      <form>
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          required
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
        />
        <TextField
          id="passwrod"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
        />
        <Button
          onClick={() => login(email, password)}
          fullWidth
          variant="contained"
          className={styles.button}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress className={styles.loaderIcon} color="inherit" />
          ) : (
            <span>{LOG_IN}</span>
          )}
        </Button>
      </form>
    </Container>
  );
};

export default Login;
