import styles from './TableSmall.module.scss';

const headerItems = [
  {
    text: '',
    props: {
      width: '5%',
      className: styles.headerCell,
    },
  },
  {
    text: 'ID',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Name',
    props: {
      width: '55%',
    },
  },
  {
    text: 'Status',
    props: {
      width: '25%',
    },
  },
];

export default headerItems;
