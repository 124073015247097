import React from 'react';
import Layout from 'src/shared/components/Layout';
import StudentAssignForm from 'src/admin/components/StudentAssignForm';

import useStudentAssign from './StudentAssign.hooks';

const StudentAssign: React.FC = () => {
  const {
    studentsList,
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    apiError,
    closeError,
  } = useStudentAssign();

  return (
    <Layout>
      <StudentAssignForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        studentsList={studentsList}
        isLoading={isLoading}
        apiError={apiError}
        closeError={closeError}
      />
    </Layout>
  );
};

export default StudentAssign;
