import styles from './TableLarge.module.scss';

const headerItems = [
  {
    id: 'student-header-1',
    text: '',
    props: {
      width: '3%',
      className: styles.signHeaderCell,
    },
  },
  {
    id: 'student-header-2',
    text: 'Name',
    props: {
      width: '20%',
    },
  },
  {
    id: 'student-header-3',
    text: 'Language',
    props: {
      width: '10%',
    },
  },
  {
    id: 'student-header-4',
    text: 'Last class',
    props: {
      width: '12%',
    },
  },
  {
    id: 'student-header-5',
    text: 'Number of classes',
    props: {
      width: '10%',
    },
  },
  {
    id: 'student-header-6',
    text: 'Total paid',
    props: {
      width: '10%',
    },
  },
  {
    id: 'student-header-7',
    text: 'Credit',
    props: {
      width: '10%',
    },
  },
  {
    id: 'student-header-8',
    text: 'Last payment',
    props: {
      width: '12%',
    },
  },
  {
    id: 'student-header-9',
    text: '',
    props: {
      width: '2%',
    },
  },
  {
    id: 'student-header-10',
    text: '',
    props: {
      width: '11%',
    },
  },
];

export default headerItems;
