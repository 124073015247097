import React from 'react';

import Layout from 'src/shared/components/Layout';
import PaymentsTableLarge from 'src/admin/components/Tables/Payments/TableLarge';
import PaymentsTableSmall from 'src/admin/components/Tables/Payments/TableSmall';

import usePayments from './Payments.hooks';

import styles from './Payments.module.scss';
import Modal from '@material-ui/core/Modal';
import PaymentAssignmentModal from 'src/admin/components/PaymentAssignmentModal';

const Payments: React.FC = () => {
  const {
    filterDates,
    handleFilteringPayments,
    changeFilterDate,
    payments,
    isLoading,
    error,
    closeError,
    selectedPaymentRemark,
    handleModalOpen,
    handleModalClose,
    isModalOpen,
    isModalLoading,
    modalError,
    closeModalError,
    studentsList,
    setSelectedStudent,
    handleAssignClick,
  } = usePayments();

  return (
    <Layout isLoading={isLoading} error={error} closeError={closeError}>
      <PaymentsTableSmall
        data={payments}
        filterDates={filterDates}
        handleFilteringPayments={handleFilteringPayments}
        changeFilterDate={changeFilterDate}
        handleModalOpen={handleModalOpen}
      />
      <PaymentsTableLarge
        data={payments}
        filterDates={filterDates}
        handleFilteringPayments={handleFilteringPayments}
        changeFilterDate={changeFilterDate}
        handleModalOpen={handleModalOpen}
      />
      <Modal
        className={styles.modal}
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <PaymentAssignmentModal
          studentsList={studentsList}
          paymentRemark={selectedPaymentRemark}
          closeModal={handleModalClose}
          isLoading={isModalLoading}
          error={modalError}
          closeError={closeModalError}
          setSelectedStudent={setSelectedStudent}
          handleAssignClick={handleAssignClick}
        />
      </Modal>
    </Layout>
  );
};

export default Payments;
