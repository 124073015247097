import React from 'react';
import { withWindow } from 'react-window-decorators';
import ClassesTableLarge from 'src/admin/components/Tables/Classes/TableLarge';
import ClassesTableSmall from 'src/admin/components/Tables/Classes/TableSmall';
import Layout from 'src/shared/components/Layout';

import useAllClasses from './AllClasses.hook';

const AllClasses: React.FC = () => {
  const {
    currentClasses,
    filterDates,
    handleFilteringClasses,
    changeFilterDate,
    isLoading,
    error,
    closeError,
  } = useAllClasses();

  return (
    <Layout isLoading={isLoading} error={error} closeError={closeError}>
      <ClassesTableSmall
        data={currentClasses}
        filterDates={filterDates}
        handleFilteringClasses={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
      <ClassesTableLarge
        data={currentClasses}
        filterDates={filterDates}
        handleFilteringClasses={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
    </Layout>
  );
};

export default withWindow(AllClasses);
