import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';
import { ProfessorStudentRelationFO } from 'src/types/types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import AutocompleteSelectInput from 'src/shared/components/AutocompleteSelectInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'src/admin/components/DatePicker';
import Input from 'src/shared/components/Input';

import styles from './AddClassForm.module.scss';
import { todayDate } from 'src/utils/helpers';

interface AddClassFormProps {
  onSubmit: any;
  handleSubmit: any;
  errors: any;
  control: any;
  relationsList: ProfessorStudentRelationFO[];
  currentDurationList: number[];
  title?: JSX.Element;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
}

const AddClassForm: React.FC<AddClassFormProps> = ({
  onSubmit,
  handleSubmit,
  errors,
  control,
  relationsList,
  currentDurationList,
  title,
  isLoading,
  apiError,
  closeError,
}) => {
  return (
    <div className={styles.wrapper}>
      {title}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="outlined" className={styles.card}>
          <CardHeader title="Add new class" className={styles.cardHeader} />
          <CardContent className={styles.generalInfoWrapper}>
            <AutocompleteSelectInput
              error={errors.psRelation}
              options={relationsList}
              className={styles.input__generalInfo}
              label="Student *"
              name="psRelation"
              control={control}
              optionLabel="studentName"
              errorText="Please select student"
              isStudentSelect
            />
            <AutocompleteSelectInput
              error={errors.duration}
              options={currentDurationList}
              className={styles.input__duration}
              label="Duration *"
              name="duration"
              control={control}
              errorText="Please select duration"
              disabled={currentDurationList.length === 0}
            />
            <DatePicker
              className={styles.date}
              control={control}
              defaultValue={todayDate()}
              label="Date"
              name="date"
              maxDate={todayDate()}
            />

            <Input
              defaultValue=""
              control={control}
              className={styles.textArea}
              label="Remark"
              name="remark"
            />
          </CardContent>
        </Card>
        <Button
          className={styles.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>Register class</span>
          )}
        </Button>
      </form>
      {apiError && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default AddClassForm;
