import { useEffect, useState } from 'react';

import { User, Professor, ProfessorUser } from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import usersFetcher from 'src/api/users';
import professorsFetcher from 'src/api/professors';

interface Data {
  getUsersAndProfessors: () => void;
  userList: ProfessorUser[];
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
}

const useUserManagement = (): Data => {
  const [error, setError] = useState<Error | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [professors, setProfessors] = useState<Professor[]>([]);
  const [professorUsers, setProfessorUsers] = useState<ProfessorUser[]>([]);

  const onGetAllUsersComplete = (data: User[]) => {
    setUsers(data);
  };

  const onGetAllProfessorsComplete = (data: Professor[]) => {
    setProfessors(data);
  };

  const [getAllUsers, getAllUsersStatus] = useAsyncRequest<[]>(
    usersFetcher.getAllUsers,
    {
      onComplete: (data: any) => {
        onGetAllUsersComplete(data);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [getAllProfessors, getAllProfessorsStatus] = useAsyncRequest<[]>(
    professorsFetcher.getAllProfessors,
    {
      onComplete: (data: any) => {
        onGetAllProfessorsComplete(data);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const getProfessorUsers = () => {
    const professorUsersList = professors.map((professor) => {
      const userData = users.find((user) => user.professorId === professor.id);

      return {
        professorId: professor.id,
        email: professor.email,
        name: professor.name,
        uid: userData ? userData.uid : null,
      };
    });

    setProfessorUsers(professorUsersList);
  };

  const getUsersAndProfessors = () => {
    getAllUsers();
    getAllProfessors();
  };

  useEffect(() => {
    getUsersAndProfessors();
  }, []);

  useEffect(() => {
    if (users.length > 0 && professors.length > 0) {
      getProfessorUsers();
    }
  }, [users, professors]);

  return {
    getUsersAndProfessors,
    userList: professorUsers,
    isLoading: getAllProfessorsStatus.loading || getAllUsersStatus.loading,
    error,
    closeError: () => setError(null),
  };
};

export default useUserManagement;
