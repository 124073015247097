import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { ProfessorUser } from 'src/types/types';
import { CREATE_USER } from 'src/constants/literals';
import Modal from '@material-ui/core/Modal';
import CreateNewUserForm from 'src/admin/components/CreateNewUserForm';

import styles from './Row.module.scss';

interface RowProps {
  data: ProfessorUser;
  getUsersAndProfessors: () => void;
}

const Row: React.FC<RowProps> = ({ data, getUsersAndProfessors }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { name, email, uid, professorId } = data;

  return (
    <>
      <TableRow>
        <TableCell size="small">{name}</TableCell>
        <TableCell size="small">{email}</TableCell>
        <TableCell size="small">{uid}</TableCell>
        <TableCell size="small" align="center">
          {!uid && (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={(): void => setIsModalOpen(true)}
            >
              {CREATE_USER}
            </Button>
          )}
        </TableCell>
      </TableRow>
      <Modal
        disableBackdropClick
        className={styles.modal}
        open={isModalOpen}
        onClose={(): void => setIsModalOpen(false)}
      >
        <CreateNewUserForm
          email={email}
          professorId={professorId}
          closeModal={(): void => setIsModalOpen(false)}
          getUsersAndProfessors={getUsersAndProfessors}
        />
      </Modal>
    </>
  );
};

export default React.memo(Row);
