import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

interface ProfessorsState {
  professors: [];
}

const initialState: ProfessorsState = {
  professors: [],
};

export const professorSlice = createSlice({
  name: 'professors',
  initialState,
  reducers: {
    addProfessors: (state, action: PayloadAction<[]>) => {
      state.professors = action.payload;
    },
  },
});

export const { addProfessors } = professorSlice.actions;

export const selectProfessors = (state: RootState): [] =>
  state.professors.professors;

export default professorSlice.reducer;
