import React, { useState } from 'react';
import cx from 'classnames';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CalculatedPayoutsSumData } from 'src/admin/pages/PayoutManagement/PayoutManagement';
import { formatMoney } from 'src/utils/helpers';

import styles from './SumRow.module.scss';

interface SumRowProps {
  data: CalculatedPayoutsSumData;
}

const SumRow: React.FC<SumRowProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const {
    amountSum,
    commissionSum,
    amountAfterCommission,
    classCountSum,
  } = data;

  return (
    <>
      <TableRow>
        <TableCell size="small" width="10%" className={styles.mainCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          size="small"
          width="70%"
          scope="row"
          className={styles.mainCell}
        >
          <b>{'Total data'}</b>
        </TableCell>
        <TableCell
          size="small"
          width="20%"
          scope="row"
          className={cx(styles.mainCell, styles.checkboxCell)}
        ></TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Total Class Count
                    </TableCell>
                    <TableCell size="small">
                      <b>{classCountSum}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Total Amount
                    </TableCell>
                    <TableCell size="small">
                      <b>{amountSum ? formatMoney(amountSum) : ''}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Total Commission
                    </TableCell>
                    <TableCell size="small">
                      <b>{commissionSum ? formatMoney(commissionSum) : ''}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Total After Commission
                    </TableCell>
                    <TableCell size="small">
                      <b>
                        {amountAfterCommission
                          ? formatMoney(amountAfterCommission)
                          : null}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(SumRow);
