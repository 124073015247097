import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditRelationData, ProfessorStudent } from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import { AdminRouteCodes } from 'src/constants/routes';
import { useHistory, useParams } from 'react-router-dom';
import relations from 'src/api/relations';

interface Data {
  onSubmit: (data: ProfessorStudent) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
}

interface Params {
  relationId: string;
}

const formatDataForForm = (relationData: EditRelationData) => {
  return {
    price45: relationData.price45 / 100,
    price60: relationData.price60 / 100,
    price90: relationData.price90 / 100,
    currency: relationData.currency,
    professorCurrency: relationData.professorCurrency,
    professorPrice45: relationData.professorPrice45 / 100,
    professorPrice60: relationData.professorPrice60 / 100,
    professorPrice90: relationData.professorPrice90 / 100,
  };
};

const formatDataForSave = (
  data: any,
  relationData: EditRelationData,
): EditRelationData => {
  return {
    id: relationData.id,
    active: relationData.active,
    commissionBased: relationData.commissionBased,
    studentId: relationData.studentId,
    studentName: relationData.studentName,
    professorId: relationData.professorId,
    status: relationData.status,
    price45: data.price45 * 100,
    price60: data.price60 * 100,
    price90: data.price90 * 100,
    currency: data.currency,
    professorPrice45: data.professorPrice45 * 100,
    professorPrice60: data.professorPrice60 * 100,
    professorPrice90: data.professorPrice90 * 100,
    professorCurrency: data.professorCurrency,
  };
};

const useStudentAssign = (): Data => {
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const { relationId } = useParams<Params>();
  const { handleSubmit, errors, control, reset } = useForm();
  const [relationData, setRelationData] = useState<EditRelationData>();

  useEffect(() => {
    if (relationId) {
      getRelation(relationId);
    }
  }, [relationId]);

  const [getRelation, getRelationStatus] = useAsyncRequest<[string]>(
    relations.getRelation,
    {
      onComplete: (data: any) => {
        setRelationData(data);
        reset(formatDataForForm(data));
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [updateRelation, updateRelationStatus] = useAsyncRequest<[EditRelationData]>(
    relations.updateRelation,
    {
      onComplete: () => {
        history.push(`${AdminRouteCodes.PROFESSOR_OVERVIEW}/${relationData?.professorId}`);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const closeError = () => {
    setError(null);
    history.push(`${AdminRouteCodes.PROFESSOR_OVERVIEW}/${relationData?.professorId}`);
  };

  const onSubmit = useCallback(
    async (data: any) => {
      if (relationData) {
        updateRelation(formatDataForSave(data, relationData));
      }
    },

    [relationData],
  );

  return {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading: getRelationStatus.loading || updateRelationStatus.loading,
    apiError: error,
    closeError,
  };
};

export default useStudentAssign;
