import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';

interface AutocompleteSelectInput {
  options: any[];
  className?: string;
  label: string;
  name: string;
  control: any;
  optionLabel?: string;
  errorText?: string;
  defaultValue?: any;
  error?: any;
  disabled?: boolean;
  isStudentSelect?: boolean;
}

const AutocompleteSelectInput: React.FC<AutocompleteSelectInput> = ({
  className,
  options,
  label,
  name,
  control,
  optionLabel,
  errorText,
  defaultValue,
  error,
  disabled,
  isStudentSelect,
}) => {
  return (
    <FormControl margin="dense" className={className} error={Boolean(error)}>
      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            options={options}
            getOptionLabel={(option): any => {
              // If we are choosing student
              if (isStudentSelect && optionLabel) {
                const isTrial = option.status === 'TRIAL';
                const label = option[optionLabel];
                return isTrial ? `${label} - Probni čas` : label;
              }

              return optionLabel ? option[optionLabel] : option.toString();
            }}
            renderInput={(params) => (
              <TextField {...params} label={label} error={Boolean(error)} />
            )}
            onChange={(_, data) => props.onChange(data)}
            defaultValue={defaultValue ? defaultValue : null}
            disabled={disabled}
          />
        )}
        rules={{ required: errorText }}
        name={name}
        control={control}
        defaultValue={null}
      />
      <FormHelperText>{error && error.message}</FormHelperText>
    </FormControl>
  );
};

export default AutocompleteSelectInput;
