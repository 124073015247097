import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

interface PaymentsState {
  payments: [];
}

const initialState: PaymentsState = {
  payments: [],
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    addPayments: (state, action: PayloadAction<[]>) => {
      state.payments = action.payload;
    },
  },
});

export const { addPayments } = paymentsSlice.actions;

export const selectPayments = (state: RootState): [] => state.payments.payments;

export default paymentsSlice.reducer;
