import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import { ClassDetails } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';
import styles from './Row.module.scss';

interface RowProps {
  data: ClassDetails;
  index: number;
}

const Row: React.FC<RowProps> = ({ data, index }) => {
  const [open, setOpen] = useState(false);
  const {
    professorCurrency,
    date,
    duration,
    professorPrice,
    studentName,
    isTrial,
  } = data;

  return (
    <>
      <TableRow>
        <TableCell className={styles.buttonCell} size="small" width="10%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          className={styles.numberCell}
          size="small"
          width="10%"
          scope="row"
        >
          {index}
        </TableCell>
        <TableCell size="small" width="40%" scope="row">
          {studentName}
        </TableCell>
        <TableCell size="small" width="40%" scope="row">
          {formatDate(date)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size="small" className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Duration
                    </TableCell>
                    <TableCell size="small">{duration}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Price
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(professorPrice)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Currency
                    </TableCell>
                    <TableCell size="small">
                      {professorCurrency ? professorCurrency : 'RSD'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Is this trial class?
                    </TableCell>
                    <TableCell size="small" className={styles.trial}>
                      {isTrial ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <ClearIcon color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(Row);
