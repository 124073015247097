import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AdminPayment, Student } from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import student from 'src/api/student';
import payments from 'src/api/payments';
import { AdminRouteCodes } from 'src/constants/routes';
import { useHistory, useParams } from 'react-router-dom';

interface Data {
  onSubmit: (data: AdminPayment) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
  currentStudent: Student;
}

interface Params {
  studentId: string;
}

const intialStudentValues = {
  id: '',
  name: '',
  email: '',
  mobile: '',
  country: '',
  ip: '',
  preferredPaymentMethod: '',
  active: false,
  remark: '',
  language: '',
};

const useAddClass = (): Data => {
  const [currentStudent, setCurrentStudent] = useState<Student>(
    intialStudentValues,
  );
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const { studentId } = useParams<Params>();
  const { handleSubmit, errors, control, reset } = useForm();

  const [getStudentById, getStudentByIdStatus] = useAsyncRequest<[string]>(
    student.getStudentById,
    {
      onComplete: (student: Student) => {
        setCurrentStudent(student);
        reset({
          paymentMethod: student.preferredPaymentMethod,
        });
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [createNewPayment, createNewPaymentStatus] = useAsyncRequest<
    [AdminPayment]
  >(payments.createNewPayment, {
    onComplete: () => {
      history.push(AdminRouteCodes.DASHBOARD);
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  const closeError = () => {
    setError(null);
    history.push(AdminRouteCodes.DASHBOARD);
  };

  const onSubmit = useCallback(
    (data: any) => {
      const formatedDate = new Date(
        Date.UTC(
          data.date.getFullYear(),
          data.date.getMonth(),
          data.date.getDate(),
          12,
        ),
      );

      const payment = {
        studentId,
        amount: data.amount * 100,
        amountAfterFee: null,
        paymentMethod: data.paymentMethod,
        currency: data.currency,
        date: formatedDate.getTime(),
        externalReference: data.externalReference,
        remark: data.remark,
      };

      createNewPayment(payment);
    },
    [studentId],
  );

  useEffect(() => {
    if (studentId) {
      getStudentById(studentId);
    }
  }, [studentId]);

  return {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading: getStudentByIdStatus.loading || createNewPaymentStatus.loading,
    apiError: error,
    closeError,
    currentStudent,
  };
};

export default useAddClass;
