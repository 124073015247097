import React from 'react';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import Row from './Row';
import { ClassDetails, FilterDates } from 'src/types/types';
import TableHeader from 'src/shared/components/TableHeader';
import ProfessorSumTableInfo from 'src/professor/components/ProfessorSumTableInfo';
import DatesFilterBar from 'src/shared/components/DatesFilterBar';

import headerItems from './HeaderItems';

import styles from './TableSmall.module.scss';

interface TableSmallProps {
  data: ClassDetails[];
  filterDates: FilterDates;
  handleFilteringClasses: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
}

const TableSmall: React.FC<TableSmallProps> = ({
  data,
  filterDates,
  handleFilteringClasses,
  changeFilterDate,
}) => {
  return (
    <TableContainer className={styles.wrapper}>
      <DatesFilterBar
        filterDates={filterDates}
        handleFilteringData={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
      <Table>
        <TableHeader items={headerItems} />
        <TableBody>
          {data.map((item: ClassDetails, index: number) => {
            return (
              <Row key={item.id} data={item} index={data.length - index} />
            );
          })}
        </TableBody>
      </Table>
      <div className={styles.sumTable}>
        <ProfessorSumTableInfo data={data} />
      </div>
    </TableContainer>
  );
};

export default TableSmall;
