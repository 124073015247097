import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { store } from 'src/app/store';
import App from 'src/App';

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
      <Router>
        <App />
      </Router>
    </StylesProvider>
  </Provider>,
  document.getElementById('root'),
);
