import loginFetcher from 'src/api/login';
import users from 'src/api/users';
import useAsyncRequest, { Status } from 'src/hooks/useAsyncRequest';
import { useHistory } from 'react-router';
import {
  RouteCodes,
  ProfessorRouteCodes,
  AdminRouteCodes,
} from 'src/constants/routes';
import { useState } from 'react';
import { User } from 'src/types/types';

export enum UserRoles {
  admin = 'admin',
  professor = 'professor',
  student = 'student',
}

export interface LoginData {
  displayName: string;
  email: string;
  expiresIn: string;
  idToken: string;
  kind: string;
  localId: string;
  refreshToken: string;
  registered: boolean;
}

interface AuthData {
  login: (email: string, password: string) => void;
  loginStatus: Status;
  logout: () => void;
  isAuthenticated: () => boolean;
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
  getUserRole: () => string;
  getUserData: () => User;
}

const useAuth = (): AuthData => {
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [getUser] = useAsyncRequest<[string]>(users.getUserById, {
    onComplete: (data: any) => {
      localStorage.setItem('user', JSON.stringify(data));
      setIsLoading(false);

      if (data.isAdmin) {
        history.push(AdminRouteCodes.DASHBOARD);
      } else {
        history.push(ProfessorRouteCodes.DASHBOARD);
      }
    },
    onError: (error: Error) => {
      setError(error);
      setIsLoading(false);
    },
  });

  const onLoginSuccess = (data: LoginData) => {
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + 3600000;

    localStorage.setItem('token', data.idToken);
    localStorage.setItem('tokenExpirationTime', expirationTime.toString());

    getUser(data.localId);
  };

  const hasTokenExpired = (tokenExpirationTime: number): boolean => {
    const currentTime = new Date().getTime();
    return tokenExpirationTime < currentTime;
  };

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem('token');
    const tokenExpirationTime =
      localStorage.getItem('tokenExpirationTime') || '0';

    return (
      !!token &&
      !!tokenExpirationTime &&
      !hasTokenExpired(parseInt(tokenExpirationTime))
    );
  };

  const [login, loginStatus] = useAsyncRequest<[string, string]>(loginFetcher, {
    onComplete: (data: LoginData) => {
      onLoginSuccess(data);
    },
    onError: (error: Error) => {
      setError(error);
      setIsLoading(false);
    },
  });

  const handleLogin = (email: string, password: string) => {
    login(email, password);
    setIsLoading(true);
  };

  const getUserRole = () => {
    const user = localStorage.getItem('user');

    if (user) {
      const userDate = JSON.parse(user);

      return userDate.isAdmin ? UserRoles.admin : UserRoles.professor;
    } else {
      // TODO: not sure why we added this here
      // logout();
      return '';
    }
  };

  const getUserData = () => {
    const user = localStorage.getItem('user');

    if (user) {
      return JSON.parse(user);
    }
  };

  const logout = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpirationTime');
    localStorage.removeItem('user');

    history.push(RouteCodes.LOGIN);
  };

  return {
    isLoading,
    login: handleLogin,
    logout,
    isAuthenticated,
    loginStatus,
    error,
    closeError: () => setError(null),
    getUserRole,
    getUserData,
  };
};

export default useAuth;
