import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import MenuItem from '@material-ui/core/MenuItem';
import { REACTIVATE } from 'src/constants/literals';
import ActionsButton from 'src/admin/components/ActionsButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { AdminRouteCodes } from 'src/constants/routes';
import { DashboardStudent } from 'src/types/types';

import styles from './TableSmallRow.module.scss';
import { Button, CircularProgress } from '@material-ui/core';

interface RowProps {
  data: DashboardStudent;
  inactive?: boolean;
  updateStudentActivity: (id: string, active: boolean) => void;
  actionButtonLoading: boolean;
  migrate: (id: string) => void;
}

const Row: React.FC<RowProps> = ({
  data,
  inactive,
  updateStudentActivity,
  actionButtonLoading,
  migrate,
}) => {
  const {
    id,
    name,
    language,
    classCount,
    formatedLastClassDate,
    showClockIcon,
    credit,
    formatedCredit,
    formatedLastPaymentDate,
    formatedTotalAmountPaid,
    commissionBased,
    error,
  } = data;
  const [open, setOpen] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  const toggleActionsOpen = (): void => {
    setActionsOpen(!actionsOpen);
  };

  const rowClasses = cx(
    { [styles.row__red]: credit < 0 },
    { [styles.row__yellow]: credit === 0 },
  );

  const nameCellClasses = cx({
    [styles.nameCell]: true,
    [styles.nameCell__noIcon]: !showClockIcon && !error,
  });

  return (
    <>
      <TableRow className={rowClasses}>
        <TableCell className={styles.buttonCell} size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" className={nameCellClasses} size="small">
          <span className={styles.nameWrapper}>
            {showClockIcon && !inactive && !error && <HourglassEmptyIcon />}
            {error && (
              <Tooltip title={error} classes={{ tooltip: styles.tooltip }}>
                <ErrorOutlineOutlinedIcon color="error" />
              </Tooltip>
            )}
            {name}
          </span>
        </TableCell>
        {actionButtonLoading ? (
          <TableCell size="small" align="center">
            <Button
              variant="outlined"
              color="primary"
              disabled
              className={styles.loadingButton}
            >
              <CircularProgress color="inherit" size="18px" />
            </Button>
          </TableCell>
        ) : (
          <TableCell size="small">
            {inactive ? (
              <Button
                variant="outlined"
                color="primary"
                className={styles.reactivateButton}
                onClick={() => updateStudentActivity(data.id, true)}
              >
                <span>{REACTIVATE}</span>
              </Button>
            ) : (
              <ActionsButton
                toggleActionsOpen={toggleActionsOpen}
                actionsOpen={actionsOpen}
              >
                <MenuItem>
                  <Link to={`${AdminRouteCodes.STUDENT_PAYMENT}/${id}`}>
                    New Payment
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Link to={`${AdminRouteCodes.PAYMENTS}?studentId=${data.id}`}>
                    View Payments
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to={`${AdminRouteCodes.CLASSES}?studentId=${data.id}`}>
                    View Classes
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Link to={`${AdminRouteCodes.STUDENT}/${id}`}>
                    Edit Student
                  </Link>
                </MenuItem>
                <MenuItem
                  className={styles.button}
                  component={Button}
                  onClick={() => updateStudentActivity(id, false)}
                >
                  <span className={styles.buttonText}>Deactivate</span>
                </MenuItem>
                {!commissionBased && (
                  <span>
                    <Divider />
                    <MenuItem
                      className={styles.button}
                      component={Button}
                      onClick={() => migrate(id)}
                    >
                      <span className={styles.buttonText}>Migrate</span>
                    </MenuItem>
                  </span>
                )}
                <Divider />
                <MenuItem>
                  <button
                    className={styles.copyEmailButton}
                    onClick={() => {
                      navigator.clipboard.writeText(data.email);
                      toggleActionsOpen();
                    }}
                  >
                    Copy Email
                  </button>
                </MenuItem>
              </ActionsButton>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">Language</TableCell>
                    <TableCell>{language}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Last class</TableCell>
                    <TableCell>{formatedLastClassDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Number of Classes</TableCell>
                    <TableCell>{classCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Total paid</TableCell>
                    <TableCell>{formatedTotalAmountPaid}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Credit</TableCell>
                    <TableCell>{formatedCredit}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Last class</TableCell>
                    <TableCell>{formatedLastPaymentDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Is commission based</TableCell>
                    <TableCell>
                      {commissionBased ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <ClearIcon color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
