import React from 'react';
import ReactDOM from 'react-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import PaymentForm from 'src/shared/components/PaymentForm';
import StudentEmailForm from 'src/shared/components/StudentEmailForm';
import logo from 'src/assets/images/logo.png';

import usePaypalPayment from './PaypalPayment.hook';
import styles from './PaypalPayment.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import { CANCEL_BUTTON, GO_BACK_BUTTON } from 'src/constants/literals';

const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

const PaypalPayment: React.FC = () => {
  const {
    createOrder,
    onApprove,
    handleSubmit,
    onSubmit,
    errors,
    control,
    countryList,
    areaCode,
    customPaymentValue,
    choosenValue,
    formData,
    handleStudentFormSubmit,
    pageTitle,
    validStudentEmail,
    onError,
    loading,
    successMessage,
    processErrorMessage,
    resetData,
    handleCustomValueClick,
    isCustomValueRadionButtonChecked,
  } = usePaypalPayment();

  return (
    <div className={styles.wrapper}>
      <Container component="main" maxWidth="xs">
        <div className={styles.header}>
          <img src={logo} alt="Lango logo" />
          <Typography component="h1" variant="h5">
            {pageTitle}
          </Typography>
        </div>
        {!validStudentEmail &&
          !formData &&
          !processErrorMessage &&
          !successMessage && (
            <StudentEmailForm submit={handleStudentFormSubmit} />
          )}
        {validStudentEmail &&
          !formData &&
          !processErrorMessage &&
          !successMessage && (
            <PaymentForm
              handleCustomValueClick={handleCustomValueClick}
              areaCode={areaCode}
              countryList={countryList}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              control={control}
              customPaymentValue={customPaymentValue || ''}
              choosenValue={choosenValue}
              resetData={resetData}
              isCustomValueRadionButtonChecked={
                isCustomValueRadionButtonChecked
              }
            />
          )}
        {validStudentEmail &&
          formData &&
          !processErrorMessage &&
          !successMessage && (
            <>
              <PayPalButton
                createOrder={(data: any, actions: any) =>
                  createOrder(data, actions)
                }
                onApprove={(data: any, actions: any) => {
                  onApprove(data, actions);
                }}
                onError={(e: Error) => onError(e)}
              />
              <Button
                className={styles.button}
                variant="contained"
                color="default"
                onClick={resetData}
              >
                <span>{CANCEL_BUTTON}</span>
              </Button>
            </>
          )}
        {processErrorMessage && (
          <>
            <Alert className={styles.errorBox} severity="error">
              <AlertTitle>{processErrorMessage}</AlertTitle>
            </Alert>
            <Button
              className={styles.button}
              variant="contained"
              color="default"
              onClick={resetData}
            >
              <span>{GO_BACK_BUTTON}</span>
            </Button>
          </>
        )}
        {successMessage && (
          <>
            <Alert severity="success">
              <AlertTitle>{successMessage}</AlertTitle>
            </Alert>
            <Button
              className={styles.button}
              variant="contained"
              color="default"
              onClick={resetData}
            >
              <span>{GO_BACK_BUTTON}</span>
            </Button>
          </>
        )}
      </Container>
      {loading && (
        <div className={styles.loaderWrapper}>
          <CircularProgress color="inherit" size="80px" />
        </div>
      )}
    </div>
  );
};

export default PaypalPayment;
