const headerItems = [
  {
    text: 'Name',
    props: {
      width: '30%',
    },
  },
  {
    text: 'Email',
    props: {
      width: '30%',
    },
  },
  {
    text: 'User ID',
    props: {
      width: '25%',
    },
  },
  {
    text: '',
    props: {
      width: '15%',
    },
  },
];

export default headerItems;
