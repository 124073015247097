import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import professor from 'src/api/professors';
import relations from 'src/api/relations';
import { OverviewPsRelation } from 'src/types/types';
import { formatOverviewPSData } from 'src/utils/dataFormater';

interface Data {
  info: any | null;
  activateRelation: any;
  deactivateRelation: any;
  activeRelations: OverviewPsRelation[];
  inactiveRelations: OverviewPsRelation[];
  activateRelationLoading: boolean;
  deactivateRelationLoading: boolean;
  chosenRelationId?: string;
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
  activeStudentsTableRef: any;
  inactiveStudentsTableRef: any;
  tablesHeight: number;
}

interface Params {
  professorId: string;
}

const BOTTOM_PADDING = 40;

const useProfessorInfo = (): Data => {
  const [info, setInfo] = useState();
  const [chosenRelationId, setChosenRelationId]= useState<string>();
  const [activeRelations, setActiveRelations] = useState<OverviewPsRelation[]>([]);
  const [inactiveRelations, setInactiveRelations] = useState<OverviewPsRelation[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const { professorId } = useParams<Params>();
  const activeStudentsTableRef = useRef<HTMLDivElement>(null);
  const inactiveStudentsTableRef = useRef<HTMLDivElement>(null);
  const [tablesHeight, setTablesHeight] = useState(0);

  useEffect(() => {
    getProfessorOverview(professorId);
  }, []);

  const getPsRelations = (relations: Array<OverviewPsRelation>) => {
    const activeRelations = relations.filter(relation => relation.active === true);
    const inactiveRelations = relations.filter(relation => relation.active === false);
    setActiveRelations(formatOverviewPSData(activeRelations));
    setInactiveRelations(formatOverviewPSData(inactiveRelations));
  };

  useEffect(() => {
    let activeStudentsTableHeight = 0;
    let inactiveStudentsTableHeight = 0;
    if (activeStudentsTableRef && activeStudentsTableRef.current) {
      activeStudentsTableHeight = activeStudentsTableRef.current.offsetHeight;
    }

    if (inactiveStudentsTableRef && inactiveStudentsTableRef.current) {
      inactiveStudentsTableHeight = inactiveStudentsTableRef.current.offsetHeight;
    }
    setTablesHeight(activeStudentsTableHeight + inactiveStudentsTableHeight + BOTTOM_PADDING);
  }, [activeRelations, inactiveRelations])

  const [getProfessorOverview, getProfessorOverviewStatus] = useAsyncRequest<
    [string]
  >(professor.getProfessorOverview, {
    onComplete: (data: any) => {
      setInfo(data);
      getPsRelations(data.psRelations as Array<OverviewPsRelation>);
    },
    onError: (error: Error) => {
      setError(error);
      window.scrollTo(0, 0);
    },
  });

  const [activateRelation, activateRelationStatus] = useAsyncRequest<[string]>(
    relations.activateRelation,
    {
      onComplete: () => {
        getProfessorOverview(professorId);
      },
      onError: (error: Error) => {
        setError(error);
        window.scrollTo(0, 0);
      },
    },
  );

  const [deactivateRelation, deactivateRelationStatus] = useAsyncRequest<
    [string]
  >(relations.deactivateRelation, {
    onComplete: () => {
      getProfessorOverview(professorId);
    },
    onError: (error: Error) => {
      setError(error);
      window.scrollTo(0, 0);
    },
  });

  const handleActivateRelation = (id: string) => {
    setChosenRelationId(id);
    activateRelation(id);
  }

  const handleDeactivateRelation = (id: string) => {
    setChosenRelationId(id);
    deactivateRelation(id);
  }

  const closeError = () => {
    setError(null);
  }

  return { 
    info, 
    isLoading: getProfessorOverviewStatus.loading,
    activateRelation: handleActivateRelation, 
    deactivateRelation: handleDeactivateRelation, 
    activeRelations,
    inactiveRelations,
    activateRelationLoading: activateRelationStatus.loading,
    deactivateRelationLoading: deactivateRelationStatus.loading,
    chosenRelationId,
    error,
    closeError,
    activeStudentsTableRef,
    inactiveStudentsTableRef,
    tablesHeight,
  };
};

export default useProfessorInfo;
