import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import TableHeader from 'src/shared/components/TableHeader';
import DatesFilterBar from 'src/shared/components/DatesFilterBar';
import { FilterDates } from 'src/types/types';
import headerItems from './HeaderItems';
import Row from './Row';

import { Payout } from 'src/types/types';

interface TableLargeProps {
  data: Payout[];
  filterDates: FilterDates;
  handleFilteringPayouts: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
}

const TableLarge: React.FC<TableLargeProps> = ({
  data,
  filterDates,
  handleFilteringPayouts,
  changeFilterDate,
}) => {
  const hasData = data.length !== 0;

  return (
    <TableContainer>
      <DatesFilterBar
        filterDates={filterDates}
        handleFilteringData={handleFilteringPayouts}
        changeFilterDate={changeFilterDate}
      />
      {hasData && (
        <>
          <Table>
            <TableHeader items={headerItems} />
            <TableBody>
              {data.map((item: Payout) => {
                return <Row key={item.id} data={item} />;
              })}
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
};

export default React.memo(TableLarge);
