import fetch from 'src/utils/fetch';
import { AdminPayment } from 'src/types/types';
import { generateClassesURL } from 'src/utils/helpers';

const getAllPayments = (
  studentId: string | null,
  startDate: Date | null,
  endDate: Date | null,
): Promise<any> => {
  const URL = `${process.env.REACT_APP_API_URL}/adminApp/reports/payments?`;

  const generatedURL = generateClassesURL(
    URL,
    null,
    studentId,
    startDate,
    endDate,
  );

  return fetch({ url: generatedURL });
};

const createNewPayment = (payment: AdminPayment): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/payments/${payment.studentId}`;

  return fetch({ url, data: payment, method: 'POST' });
};

const assignPaymentToStudent = (
  paymentId: number | null,
  studentId: string | undefined,
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/payments/${paymentId}`;

  return fetch({ url, data: { studentId }, method: 'PATCH' });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const checkPaypalPayment = (data: any): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/checkouts/paypal/payments`;

  return fetch({ url, data, method: 'POST' });
};

export default {
  getAllPayments,
  createNewPayment,
  assignPaymentToStudent,
  checkPaypalPayment,
};
