import React from 'react';
import { DashboardProfessor } from 'src/types/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHeader from 'src/shared/components/TableHeader';

import Row from './Row';
import headerItems from './HeaderItems';
import styles from './TableLarge.module.scss';

interface TableLargeProps {
  data: DashboardProfessor[];
}

const TableLarge: React.FC<TableLargeProps> = ({ data }) => {
  return (
    <TableContainer className={styles.wrapper}>
      <Table>
        <TableHeader items={headerItems} />
        <TableBody>
          {data.map((professor: DashboardProfessor) => {
            return <Row key={professor.id} data={professor} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableLarge);
