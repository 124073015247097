import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { ClassDetails } from 'src/types/types';

interface ClassesState {
  classes: ClassDetails[];
}

const initialState: ClassesState = {
  classes: [],
};

export const classesSlice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    addClasses: (state, action: PayloadAction<ClassDetails[]>) => {
      state.classes = action.payload;
    },
  },
});

export const { addClasses } = classesSlice.actions;

export const selectClasses = (state: RootState): ClassDetails[] =>
  state.classes.classes;

export default classesSlice.reducer;
