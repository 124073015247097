import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { ProfessorUser } from 'src/types/types';

interface userState {
  users: ProfessorUser[];
}

const initialState: userState = {
  users: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAllUsers: (state, action: PayloadAction<ProfessorUser[]>) => {
      state.users = action.payload;
    },
  },
});

export const { setAllUsers } = usersSlice.actions;

export const selectUsers = (state: RootState): ProfessorUser[] =>
  state.users.users;

export default usersSlice.reducer;
