import React from 'react';
import cx from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import DatePicker from 'src/admin/components/DatePicker';
import Input from 'src/shared/components/Input';
import { Student } from 'src/types/types';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT, SUBMIT_PAYMENT } from 'src/constants/literals';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './StudentPaymentForm.module.scss';
import AutocompleteSelectInput from 'src/shared/components/AutocompleteSelectInput';
import { todayDate } from 'src/utils/helpers';

interface StudentPaymentFormProps {
  onSubmit: any;
  handleSubmit: any;
  errors: any;
  control: any;
  student: Student;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
}

const StudentPaymentForm: React.FC<StudentPaymentFormProps> = ({
  onSubmit,
  handleSubmit,
  errors,
  control,
  student,
  isLoading,
  apiError,
  closeError,
}) => {
  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="outlined" className={styles.card}>
          <CardHeader
            title={`Payment for student: ${student.name}`}
            className={styles.cardHeader}
          />
          <CardContent className={styles.generalInfoWrapper}>
            <AutocompleteSelectInput
              className={cx(styles.input__generalInfo, styles.autocomplete)}
              error={errors.paymentMethod}
              options={['PayPal', 'Credit Card', 'RSD Payment', 'EUR Payment']}
              label="Payment channel *"
              name="paymentMethod"
              defaultValue={student.preferredPaymentMethod}
              control={control}
              errorText="Please choose payment method"
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__amount}
              rules={{ required: true }}
              name="amount"
              type="number"
              label="Amount *"
              margin="dense"
              error={Boolean(errors.amount)}
              helperText={Boolean(errors.amount) && 'This field is required'}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__reference}
              rules={{ required: true }}
              name="externalReference"
              label="External Reference *"
              margin="dense"
              error={Boolean(errors.externalReference)}
              helperText={
                Boolean(errors.externalReference) && 'This field is required'
              }
            />
            <AutocompleteSelectInput
              error={errors.currency}
              options={['RSD', 'EUR']}
              className={cx(styles.input__generalInfo, styles.autocomplete)}
              label="Currency *"
              name="currency"
              control={control}
              errorText="Please select the currency"
            />
            <DatePicker
              className={styles.date}
              rules={{ required: true }}
              control={control}
              defaultValue={todayDate()}
              label="Date"
              name="date"
              maxDate={todayDate()}
              error={Boolean(errors.date)}
              helperText={'Please chose the date'}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.textArea}
              label="Remark"
              name="remark"
              multiline
            />
          </CardContent>
        </Card>
        <Button
          className={styles.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>{SUBMIT_PAYMENT}</span>
          )}
        </Button>
      </form>
      {apiError && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default StudentPaymentForm;
