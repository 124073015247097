import { useState, useEffect } from 'react';

type Fetcher<P extends any[]> = (...params: P) => Promise<any>;

interface Handlers<D> {
  onComplete?: (data: D) => void;
  onError?: (error: Error) => void;
}

export interface Status<TData = any> {
  loading: boolean;
  error: Error | null;
  data: TData | null;
}

type ReturnTuple<P extends any[], D> = [(...params: P) => void, Status<D>];

const useAsyncRequest = <P extends any[], D = any>(
  fetcher: Fetcher<P>,
  handlers?: Handlers<D>,
): ReturnTuple<P, D> => {
  const [params, setParams] = useState<P>();

  const [status, setStatus] = useState<Status<D>>({
    loading: false,
    error: null,
    data: null,
  });

  const start = () => {
    setStatus({
      loading: true,
      error: null,
      data: null,
    });
  };

  const fail = (error: Error) => {
    setStatus({
      loading: false,
      error: error,
      data: null,
    });
  };

  const complete = (data: D) => {
    setStatus({
      loading: false,
      error: null,
      data,
    });
  };

  const call = (...params: P) => {
    setParams(params);
  };

  useEffect(() => {
    if (params) {
      request(...params);
    }
  }, [params]);

  const request = (...params: P) => {
    const onComplete = handlers && handlers.onComplete;
    const onError = handlers && handlers.onError;

    start();

    fetcher(...params)
      .then((response) => {
        complete(response.data);

        if (onComplete) {
          onComplete(response.data);
        }
      })
      .catch((error: Error) => {
        fail(error);

        if (onError) {
          onError(error);
        }
      });
  };

  return [call, status];
};

export default useAsyncRequest;
