import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Dispatch, SetStateAction } from 'react';
import {
  CANCEL,
  CHOOSE_STUDENT,
  ASSIGN,
  ERROR_POP_UP_TEXT,
  COMMENT,
} from 'src/constants/literals';
import { Student } from 'src/types/types';

import styles from './PaymentAssignmentModal.module.scss';

interface PaymentAssignmentModalProps {
  isLoading: boolean;
  closeModal: () => void;
  error: Error | null;
  closeError: () => void;
  studentsList: Student[];
  paymentRemark: string | null;
  setSelectedStudent: Dispatch<SetStateAction<Student | null>>;
  handleAssignClick: () => void;
}

const PaymentAssignmentModal = React.forwardRef<
  HTMLDivElement,
  PaymentAssignmentModalProps
>((props, ref) => {
  const {
    isLoading,
    closeModal,
    error,
    closeError,
    studentsList,
    paymentRemark,
    setSelectedStudent,
    handleAssignClick,
  } = props;

  return (
    <Paper ref={ref} elevation={3} className={styles.wrapper} tabIndex={-1}>
      <div className={styles.topContent}>
        <Typography component="h1" variant="h4" className={styles.header}>
          {CHOOSE_STUDENT}
        </Typography>
        {paymentRemark && (
          <Typography className={styles.paymentRemark}>
            <b>{COMMENT}</b>: {paymentRemark}
          </Typography>
        )}
      </div>
      <Autocomplete
        className={styles.autocomplete}
        options={studentsList}
        getOptionLabel={(option) => `${!option.active ? '[X] ' : ''} ${option.name} - ${option.email}`}
        disabled={isLoading}
        renderInput={(params) => <TextField {...params} label="Student" />}
        onChange={(_, data) => setSelectedStudent(data)}
      />
      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.createButton}
          onClick={handleAssignClick}
          fullWidth
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>{ASSIGN}</span>
          )}
        </Button>
        <Button onClick={closeModal} fullWidth variant="contained">
          {CANCEL}
        </Button>
      </div>
      {error && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </Paper>
  );
});

PaymentAssignmentModal.displayName = 'PaymentAssignmentModal';

export default PaymentAssignmentModal;
