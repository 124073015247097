import React from 'react';
import { DashboardStudent } from 'src/types/types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableContainer } from '@material-ui/core';
import TableHeader from 'src/shared/components/TableHeader';

import Row from './Row';
import styles from './TableLarge.module.scss';
import headerItems from './HeaderItems';

interface TableLargeProps {
  studentsData: DashboardStudent[];
  updateStudentActivity: (id: string, active: boolean) => void;
  actionButtonLoading: boolean;
  studentUpdatedId: string | null;
  migrate: (id: string) => void;
  isInactive: boolean;
}

const TableLarge: React.FC<TableLargeProps> = ({
  studentsData,
  studentUpdatedId,
  updateStudentActivity,
  actionButtonLoading,
  migrate,
  isInactive,
}) => {
  return (
    <TableContainer className={styles.wrapper}>
      <Table>
        <TableHeader items={headerItems} />
        <TableBody>
          {studentsData.map((student: DashboardStudent) => (
            <Row
              key={student.id}
              data={student}
              inactive={isInactive}
              updateStudentActivity={updateStudentActivity}
              actionButtonLoading={
                studentUpdatedId === student.id && actionButtonLoading
              }
              migrate={migrate}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableLarge);
