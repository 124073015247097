import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { getDayBeforeNow, getFormatedDate, todayDate } from 'src/utils/helpers';

import styles from './ToolbarSmall.module.scss';
import useAuth from 'src/hooks/useAuth';
import { AdminRouteCodes } from 'src/constants/routes';

interface ToolbarSmallProps {
  navLinks: any[];
  payoutsNavLinks: any[];
  userEmail: string;
}

const ToolbarSmall: React.FC<ToolbarSmallProps> = ({
  navLinks,
  userEmail,
  payoutsNavLinks,
}) => {
  const history = useHistory();
  const { logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const renderPayoutsLinks = () => {
    return payoutsNavLinks.map((link) => {
      return (
        <MenuItem
          key={link.title}
          onClick={() => {
            let path = link.path;
            if (link.setDate) {
              const startDate = getDayBeforeNow(7);
              const endDate = getFormatedDate(todayDate());
              path = `${
                link.path
              }?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;
            }
            history.push(path);
          }}
        >
          {link.title}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Toolbar className={styles.wrapper}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="subtitle1" noWrap className={styles.menuText}>
          Menu
        </Typography>
        <Typography variant="subtitle1" className={styles.email}>
          {userEmail}
        </Typography>
      </Toolbar>
      <nav className={styles.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(!isMenuOpen)}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <MenuList>
              <MenuItem>
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  edge="start"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <CloseIcon />
                </IconButton>
              </MenuItem>
              <Divider />
              {navLinks.map((link) => {
                return link.path === AdminRouteCodes.PAYOUT ? (
                  renderPayoutsLinks()
                ) : (
                  <MenuItem
                    key={link.title}
                    onClick={() => {
                      let path = link.path;
                      if (link.setDate) {
                        const startDate = getDayBeforeNow(7);
                        const endDate = getFormatedDate(todayDate());
                        path = `${
                          link.path
                        }?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;
                      }
                      history.push(path);
                    }}
                  >
                    {link.title}
                  </MenuItem>
                );
              })}
              <Divider />
              <MenuItem
                className={styles.logoutButton}
                component={Button}
                onClick={logout}
              >
                Log out
              </MenuItem>
            </MenuList>
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default ToolbarSmall;
