import fetch from 'src/utils/fetch';
import { ClassType } from 'src/types/types';
import { generateClassesURL } from 'src/utils/helpers';

const getAllClasses = (
  professorId: string | null,
  studentId: string | null,
  startDate: Date | null,
  endDate: Date | null,
): Promise<any> => {
  const URL = `${process.env.REACT_APP_API_URL}/adminApp/reports/classes?`;

  const generatedURL = generateClassesURL(
    URL,
    professorId,
    studentId,
    startDate,
    endDate,
  );

  return fetch({ url: generatedURL });
};

const getAllClassesAsProfessor = (
  startDate: Date | null,
  endDate: Date | null,
): Promise<any> => {
  const URL = `${process.env.REACT_APP_API_URL}/professorApp/reports/classes?`;

  const generatedURL = generateClassesURL(URL, null, null, startDate, endDate);

  return fetch({ url: generatedURL });
};

const createNewClass = (classData: ClassType): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/classes`;

  return fetch({ url, data: classData, method: 'POST' });
};

const createNewClassAsProfessor = (classData: ClassType): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/professorApp/classes`;

  return fetch({ url, data: classData, method: 'POST' });
};

export default {
  getAllClasses,
  createNewClass,
  createNewClassAsProfessor,
  getAllClassesAsProfessor,
};
