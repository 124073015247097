import React, { useState } from 'react';
import cx from 'classnames';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import ActionsButton from 'src/admin/components/ActionsButton';
import StarIcon from '@material-ui/icons/Star';
import LensIcon from '@material-ui/icons/Lens';
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import PersonIcon from '@material-ui/icons/Person';

import { DashboardProfessor } from 'src/types/types';
import MenuItem from '@material-ui/core/MenuItem';

import { AdminRouteCodes } from 'src/constants/routes';

import styles from './TableSmallRow.module.scss';
import ActivityBox from 'src/admin/components/ActivityBox';
interface RowProps {
  data: DashboardProfessor;
}

const Row: React.FC<RowProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);

  const toggleActionsOpen = (): void => {
    setActionsOpen(!actionsOpen);
  };

  const availabilityClasses = cx({
    [styles.availability]: true,
    [styles.availability__high]: data.availability === 'HIGH',
    [styles.availability__low]: data.availability === 'LOW',
    [styles.availability__full]: data.availability === 'NONE',
  });

  return (
    <>
      <TableRow>
        <TableCell size="small" className={styles.firstCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell size="small" scope="row" className={styles.nameCell}>
          <span className={availabilityClasses}>
            <LensIcon />
            {data.name}
          </span>
        </TableCell>
        <TableCell size="small">
          <ActionsButton
            actionsOpen={actionsOpen}
            toggleActionsOpen={toggleActionsOpen}
          >
            <MenuItem>
              <Link to={`${AdminRouteCodes.PROFESSOR_OVERVIEW}/${data.id}`}>
                Overview
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link to={`${AdminRouteCodes.ADD_CLASS}/${data.id}`}>
                Add class
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={`${AdminRouteCodes.STUDENT_ASSIGN}/${data.id}`}>
                Assing Student
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link to={`${AdminRouteCodes.PROFESSOR}/${data.id}`}>
                Edit Info
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <Link to={`${AdminRouteCodes.CLASSES}?professorId=${data.id}`}>
                View Classes
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <button
                className={styles.copyEmailButton}
                onClick={() => {
                  navigator.clipboard.writeText(data.email);
                  toggleActionsOpen();
                }}
              >
                Copy Email
              </button>
            </MenuItem>
          </ActionsButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size="small" className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Language
                    </TableCell>
                    <TableCell size="small">{data.language}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Score
                    </TableCell>
                    <TableCell size="small">
                      <span className={styles.langoScore}>
                        <BarChartIcon />
                        {data.langoScore}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Rating
                    </TableCell>
                    <TableCell size="small">
                      <span className={styles.rating}>
                        <StarIcon />
                        {data.formatedRating}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Students
                    </TableCell>
                    <TableCell size="small">
                      <span className={styles.students}>
                        <PersonIcon />
                        {data.formatedStudentsNumber}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Number of Classes
                    </TableCell>
                    <TableCell size="small">{data.classCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Last class
                    </TableCell>
                    <TableCell size="small">
                      {data.formatedLastClassDate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Activity
                    </TableCell>
                    <TableCell size="small">
                      <ActivityBox activityValue={data.activity} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
