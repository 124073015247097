import React from 'react';
import { withWindow } from 'react-window-decorators';
import OverviewScoreCards from 'src/admin/components/OverviewScoreCards';
import ProfessorOverviewData from 'src/admin/components/ProfessorOverviewData';
import InactiveStudentsTableLarge from 'src/admin/components/Tables/InactiveStudents/TableLarge';
import InactiveStudentsTableSmall from 'src/admin/components/Tables/InactiveStudents/TableSmall';
import ActiveStudentsTableLarge from 'src/admin/components/Tables/ActiveStudents/TableLarge';
import ActiveStudentsTableSmall from 'src/admin/components/Tables/ActiveStudents/TableSmall';
import NavigationBar from 'src/shared/components/NavigationBar';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import VersionBanner from 'src/shared/components/VersionBanner';

import useProfessorOverview from './ProfessorInfo.hooks';

import styles from './ProfessorOverview.module.scss';

type ProfessorOverviewData = {
  breakpoint: string;
};

const ProfessorOverview: React.FC<ProfessorOverviewData> = ({ breakpoint }) => {
  const {
    info,
    inactiveRelations,
    activeRelations,
    error,
    activateRelation,
    deactivateRelation,
    activateRelationLoading,
    deactivateRelationLoading,
    chosenRelationId,
    isLoading,
    closeError,
    activeStudentsTableRef,
    inactiveStudentsTableRef,
  } = useProfessorOverview();
  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  return (
    <div className={styles.wrapper}>
      <NavigationBar />
      {Boolean(error) && (
        <Container className={styles.tableWrapper}>
          <Alert
            severity="error"
            onClose={closeError}
            className={styles.errorBar}
          >
            <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
          </Alert>
        </Container>
      )}
      {info && (
        <Container className={styles.tableWrapper}>
          <Typography component="h1" variant="h4" className={styles.tableName}>
            Professor info
          </Typography>
          <Paper className={styles.dataWrapper}>
            <ProfessorOverviewData
              professorData={info.professorData}
              availabilityData={info.availabilityData}
              activity={info.activityData.activity}
            />
            <OverviewScoreCards
              performanceData={info.performanceData}
              activityData={info.activityData}
              payoutData={info.payoutData}
            />
          </Paper>
        </Container>
      )}
      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      <div>
        {!isLoading && activeRelations && activeRelations.length > 0 && (
          <Container
            className={styles.tableWrapper}
            ref={activeStudentsTableRef}
          >
            <Typography
              component="h1"
              variant="h4"
              className={styles.tableName}
            >
              Active students
            </Typography>
            <Paper>
              {isSmallOrMedium ? (
                <ActiveStudentsTableSmall
                  data={activeRelations}
                  deactivateRelation={deactivateRelation}
                  deactivateRelationLoading={deactivateRelationLoading}
                  chosenRelationId={chosenRelationId}
                  activity={info.activityData.activity}
                />
              ) : (
                <ActiveStudentsTableLarge
                  data={activeRelations}
                  deactivateRelation={deactivateRelation}
                  deactivateRelationLoading={deactivateRelationLoading}
                  chosenRelationId={chosenRelationId}
                  activity={info.activityData.activity}
                />
              )}
            </Paper>
          </Container>
        )}
        {!isLoading && inactiveRelations && inactiveRelations.length > 0 && (
          <Container
            className={styles.tableWrapper}
            ref={inactiveStudentsTableRef}
          >
            <Typography
              component="h1"
              variant="h4"
              className={styles.tableName}
            >
              Inactive students
            </Typography>
            <Paper>
              {isSmallOrMedium ? (
                <InactiveStudentsTableSmall
                  data={inactiveRelations}
                  activateRelation={activateRelation}
                  activateRelationLoading={activateRelationLoading}
                  chosenRelationId={chosenRelationId}
                />
              ) : (
                <InactiveStudentsTableLarge
                  data={inactiveRelations}
                  activateRelation={activateRelation}
                  activateRelationLoading={activateRelationLoading}
                  chosenRelationId={chosenRelationId}
                />
              )}
            </Paper>
          </Container>
        )}
      </div>
      <VersionBanner />
    </div>
  );
};

export default withWindow(ProfessorOverview);
