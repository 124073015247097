import React from 'react';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import DatesFilterBar from 'src/shared/components/DatesFilterBar';
import { Payout, FilterDates } from 'src/types/types';
import TableHeader from 'src/shared/components/TableHeader';

import Row from './Row';
import headerItems from './HeaderItems';

import styles from './TableSmall.module.scss';

interface TableSmallProps {
  data: Payout[];
  filterDates: FilterDates;
  handleFilteringPayouts: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
}

const TableSmall: React.FC<TableSmallProps> = ({
  data,
  filterDates,
  handleFilteringPayouts,
  changeFilterDate,
}) => {
  const hasData = data.length !== 0;

  return (
    <TableContainer className={styles.table}>
      <DatesFilterBar
        filterDates={filterDates}
        handleFilteringData={handleFilteringPayouts}
        changeFilterDate={changeFilterDate}
      />
      {hasData && (
        <>
          <Table>
            <TableHeader
              items={headerItems}
              itemClassName={styles.headerCell}
            />
            <TableBody>
              {data.map((item: Payout) => {
                return <Row key={item.id} data={item} />;
              })}
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
};

export default TableSmall;
