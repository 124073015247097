import { EditRelationData } from 'src/types/types';
import fetch from 'src/utils/fetch';

const getRelations = (professorId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/ps-relations?professorId=${professorId}`;

  return fetch({ url });
};

const getRelation = (psRelationId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/ps-relations/${psRelationId}`;

  return fetch({ url });
};

const activateRelation = (psRelationId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/ps-relations/${psRelationId}/activate`;

  return fetch({ url, data: {}, method: 'POST' });
};

const deactivateRelation = (psRelationId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/ps-relations/${psRelationId}/deactivate`;

  return fetch({ url, data: {}, method: 'POST' });
};

const updateRelation = (relationData: EditRelationData): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/ps-relations/${relationData.id}`;

  return fetch({ url, data: relationData, method: 'PUT' });
};

const getRelationsAsProfessor = (professorId: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/professorApp/ps-relations?professorId=${professorId}`;

  return fetch({ url });
};

export default {
  getRelations,
  getRelation,
  getRelationsAsProfessor,
  activateRelation,
  deactivateRelation,
  updateRelation,
};
