import React from 'react';
import { AnalyticsData } from 'src/types/types';
import { formatMoney } from 'src/utils/helpers';
import ScoreCard from '../../components/ScoreCard';

import styles from './StudentsDebtCards.module.scss';

interface StudentsDebtCardsProps {
  data: AnalyticsData;
}

const StudentsDebtCards: React.FC<StudentsDebtCardsProps> = ({ data }) => {
  return (
    <div className={styles.wrapper}>
      <ScoreCard title="No of students">{data.debtStudentCount}</ScoreCard>
      <ScoreCard title="Total debt">
        {formatMoney(parseInt(data.debtTotal), data.debtCurrency)}
      </ScoreCard>
    </div>
  );
};

export default StudentsDebtCards;
