import { PaypalFormData } from 'src/shared/pages/PaypalPayment/PaypalPayment.hook';
import { DashboardProfessor, DashboardStudent, OverviewPsRelation, Payout } from 'src/types/types';
import { formatDate, formatMoney, monthsDiff } from 'src/utils/helpers';

export const getFormatedRating = (ratingAvg: number, ratingCount: number): string => {
  const avarage = (ratingAvg / 100).toFixed(2);
  return `${avarage} (${ratingCount})`;
};

export const formatProfessorsData = (
  data: DashboardProfessor[],
): DashboardProfessor[] => {
  const formatedData = data.map((professor) => {
    const trialStudentsNumber =
      professor.trialStudentCount > 0 ? `+${professor.trialStudentCount}` : '';
    return {
      ...professor,
      formatedLastClassDate:
        professor.classCount > 0 ? formatDate(professor.lastClassDate) : '',
      formatedRating: getFormatedRating(
        professor.ratingAvg,
        professor.ratingCount,
      ),
      formatedStudentsNumber: `${professor.activeStudentCount}${trialStudentsNumber} (${professor.studentCount})`,
    };
  });

  return formatedData;
};

export const formatOverviewPSData = (
  data: OverviewPsRelation[],
): OverviewPsRelation[] => {
  const formatedData = data.map((relation) => {
    return {
      ...relation,
      formatedLastClassDate:
        relation.classCount > 0 ? formatDate(relation.lastClass) : '',
      formatedRating: getFormatedRating(
        relation.ratingAvg,
        relation.ratingCount,
      ),
    };
  });

  return formatedData;
};

export const formatStudentsData = (
  data: DashboardStudent[],
): DashboardStudent[] => {
  const formatedData = data.map((student) => {
    return {
      ...student,
      // Last class was 3 month ago
      showClockIcon: student.lastClassDate
        ? monthsDiff(student.lastClassDate) >= 3
        : false,
      formatedLastClassDate:
        student.classCount > 0 ? formatDate(student.lastClassDate) : '',
      formatedCredit: formatMoney(student.credit, student.reportingCurrency),
      formatedTotalAmountPaid: formatMoney(
        student.totalAmountPaid,
        student.reportingCurrency,
      ),
      formatedLastPaymentDate: student.lastPaymentDate
        ? formatDate(student.lastPaymentDate)
        : '',
    };
  });

  return formatedData;
};

export const formatPaypalPaymentData = (data: PaypalFormData | null): any => {
  if (data) {
    let amount = '';
    if (data.amount) {
      amount = `${data.amount}.00`;
    } else if (data.customPaymentValue) {
      amount = `${data.customPaymentValue}.00`;
    }

    return {
      ...data,
      amount,
      currency: 'EUR',
    };
  }
};

export const addCurrentId = (data: Payout[]): Payout[] => {
  const formatedList = data.map((payout) => {
    return {
      ...payout,
      currentId: `${payout.professorId}-${payout.commissionBased}-${payout.periodStartDate}-${payout.periodEndDate}`,
    };
  });

  return formatedList;
};

export const removeCurrentId = (data: Payout[]): Payout[] => {
  const formatedList = data.map((payout) => {
    if (payout.currentId) {
      delete payout.currentId;
    }

    return payout;
  });

  return formatedList;
};
