import React from 'react';
import cx from 'classnames';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';

import styles from './HeaderCell.module.scss';

interface Props extends TableCellProps {
  text: string;
  className?: string;
}

const HeaderCell: React.FC<Props> = (props) => {
  return (
    <TableCell
      size="small"
      {...props}
      className={cx(styles.headerCell, props.className)}
    >
      {props.text}
    </TableCell>
  );
};

export default HeaderCell;
