const headerItems = [
  {
    text: 'ID',
    props: {
      width: '5%',
    },
  },
  {
    text: 'Student',
    props: {
      width: '25%',
    },
  },
  {
    text: 'Amount',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Amount after Fees',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Payment Method',
    props: {
      width: '14%',
    },
  },
  {
    text: 'Date',
    props: {
      width: '12%',
    },
  },
  {
    text: 'Comment',
    props: {
      width: '7%',
    },
  },
  {
    text: 'Action',
    props: {
      width: '7%',
      align: 'center',
    },
  },
];

export default headerItems;
