import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React from 'react';
import { Controller } from 'react-hook-form';

interface SelectProps {
  name: string;
  error: any;
  control: any;
  label: string;
  className?: string;
  errorText?: string;
  disabled?: boolean;
  defaultValue?: string;
}

const SelectInput: React.FC<SelectProps> = ({
  error,
  control,
  name,
  label,
  className,
  errorText,
  children,
  disabled,
  defaultValue,
}) => {
  return (
    <FormControl margin="dense" className={className} error={Boolean(error)}>
      <InputLabel>{label}</InputLabel>

      <Controller
        as={<Select disabled={disabled}>{children}</Select>}
        name={name}
        rules={{ required: errorText }}
        control={control}
        defaultValue={defaultValue ? defaultValue : ''}
      />
      <FormHelperText>{error && error.message}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
