import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';
import {
  CHECKOUT_SIGN_UP,
  CHECKOUT_STUDENT_EMAIL_EXPLANATION,
  EMAIL_ERROR_MESSAGE,
} from 'src/constants/literals';
import useStudentEmailForm from 'src/shared/components/StudentEmailForm/StudentEmailForm.hooks';

import styles from './StudentEmailForm.module.scss';

interface StudentEmailFormProps {
  submit: (email: string) => void;
}
const StudentEmailForm: React.FunctionComponent<StudentEmailFormProps> = ({
  submit,
}) => {
  const {
    studentEmail,
    handleOnChange,
    isEmailInvalid,
    handleSubmitButton,
    loading,
    error,
    closeError,
    handleEnterClick,
  } = useStudentEmailForm(submit);

  return (
    <div className={styles.wrapper}>
      <Typography component="p" className={styles.explanation}>
        {CHECKOUT_STUDENT_EMAIL_EXPLANATION}
      </Typography>
      <TextField
        error={isEmailInvalid}
        value={studentEmail}
        onChange={(e) => handleOnChange(e.target.value)}
        label="Email učenika"
        onKeyDown={handleEnterClick}
        helperText={isEmailInvalid ? EMAIL_ERROR_MESSAGE : null}
      />
      <Button
        className={styles.submitButton}
        onClick={handleSubmitButton}
        variant="contained"
        color="primary"
        type="submit"
        disabled={!studentEmail || loading}
      >
        {loading ? (
          <CircularProgress color="inherit" size="24px" />
        ) : (
          <span>{CHECKOUT_SIGN_UP}</span>
        )}
      </Button>
      {error && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default StudentEmailForm;
