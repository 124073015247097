import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import { DashboardProfessor } from 'src/types/types';

import Row from './Row';

import styles from './TableSmall.module.scss';

interface TableSmallProps {
  data: DashboardProfessor[];
}

const TableSmall: React.FC<TableSmallProps> = ({ data }) => {
  return (
    <TableContainer className={styles.wrapper}>
      <Table>
        <TableBody className={styles.tableBody}>
          {data.map((item: DashboardProfessor) => {
            return <Row key={item.name} data={item} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSmall;
