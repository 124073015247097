import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { DashboardStudent } from 'src/types/types';

interface StudentState {
  activeStudents: DashboardStudent[];
  inactiveStudents: DashboardStudent[];
}

const initialState: StudentState = {
  activeStudents: [],
  inactiveStudents: [],
};

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    addStudents: (state, action: PayloadAction<[]>) => {
      const students: DashboardStudent[] = action.payload;
      const activeStudents = students.filter((student) => student.active);
      const inactiveStudents = students.filter((student) => !student.active);
      state.activeStudents = activeStudents;
      state.inactiveStudents = inactiveStudents;
    },
  },
});

export const { addStudents } = studentsSlice.actions;

export const selectStudents = (state: RootState): StudentState =>
  state.students;

export default studentsSlice.reducer;
