import { useState, useEffect } from 'react';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import dashboard from 'src/api/dashboard';
import { DashboardProfessor, DashboardStudent } from 'src/types/types';
import {
  formatProfessorsData,
  formatStudentsData,
} from 'src/utils/dataFormater';
import student from 'src/api/student';
import { sortStudentsByName } from 'src/utils/helpers';

interface DashboardData {
  professors: DashboardProfessor[];
  activeStudents: DashboardStudent[];
  inactiveStudents: DashboardStudent[];
}

interface Data {
  activeTabIndex: number;
  handleTabsChange: (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => void;
  isLoading: boolean;
  actionButtonLoading: boolean;
  error: Error | null;
  data: DashboardData;
  closeError: () => void;
  updateStudentActivity: (id: string, active: boolean) => void;
  studentUpdatedId: string | null;
  migrate: (id: string) => void;
}

const useDashboard = (): Data => {
  const [error, setError] = useState<Error | null>(null);
  const [studentUpdatedId, setStudentUpdatedId] = useState<string | null>(null);
  const [isStudentActive, setIsStudentActive] = useState(false);
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    professors: [],
    activeStudents: [],
    inactiveStudents: [],
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const onGetDashboardDataComplete = (data: any) => {
    const activeStudents = data.studentList.filter(
      (student: DashboardStudent) => student.active,
    );
    const inactiveStudents = data.studentList.filter(
      (student: DashboardStudent) => !student.active,
    );

    setDashboardData({
      professors: formatProfessorsData(data.professorList),
      activeStudents: formatStudentsData(activeStudents),
      inactiveStudents: formatStudentsData(inactiveStudents),
    });
  };

  const [getDashboardData, getDashboardDataState] = useAsyncRequest<[]>(
    dashboard.getDashboardData,
    {
      onComplete: (data: any) => {
        onGetDashboardDataComplete(data);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [updateStudentActivity, updateStudentActivityState] = useAsyncRequest<
    [string, boolean]
  >(student.updateStudentActivity, {
    onComplete: () => {
      if (isStudentActive) {
        let inactiveStudents = [...dashboardData.inactiveStudents];
        const removedStudent = dashboardData.activeStudents.find(
          (student) => student.id === studentUpdatedId,
        );

        if (removedStudent) {
          inactiveStudents = sortStudentsByName([
            ...inactiveStudents,
            removedStudent,
          ]);
        }

        const activeStudents = dashboardData.activeStudents.filter(
          (student) => student.id !== studentUpdatedId,
        );
        setDashboardData({
          ...dashboardData,
          activeStudents,
          inactiveStudents,
        });
      } else {
        let activeStudents = [...dashboardData.activeStudents];
        const removedStudent = dashboardData.inactiveStudents.find(
          (student) => student.id === studentUpdatedId,
        );

        if (removedStudent) {
          activeStudents = sortStudentsByName([
            ...activeStudents,
            removedStudent,
          ]);
        }

        const inactiveStudents = dashboardData.inactiveStudents.filter(
          (student) => student.id !== studentUpdatedId,
        );

        setDashboardData({
          ...dashboardData,
          inactiveStudents,
          activeStudents,
        });
      }

      setStudentUpdatedId(null);
    },
    onError: (error: Error) => {
      setError(error);
      setStudentUpdatedId(null);
    },
  });

  const [
    migrateToCommissionBased,
    migrateToCommissionBasedState,
  ] = useAsyncRequest<[string]>(student.migrateToCommissionBased, {
    onComplete: () => {
      const activeStudents = dashboardData.activeStudents.map((student) => {
        if (student.id === studentUpdatedId) {
          return {
            ...student,
            commissionBased: true,
          };
        }

        return student;
      }) as DashboardStudent[];

      setDashboardData({
        ...dashboardData,
        activeStudents,
      });

      setStudentUpdatedId(null);
    },
    onError: (error: Error) => {
      setError(error);
      setStudentUpdatedId(null);
    },
  });

  const handleStudentActivityUpdate = (id: string, active: boolean) => {
    updateStudentActivity(id, active);
    setIsStudentActive(!active);
    setStudentUpdatedId(id);
  };

  const migrate = (id: string) => {
    migrateToCommissionBased(id);
    setStudentUpdatedId(id);
  };

  const handleTabsChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setActiveTabIndex(newValue);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return {
    activeTabIndex,
    handleTabsChange,
    data: dashboardData,
    isLoading: getDashboardDataState.loading,
    actionButtonLoading:
      updateStudentActivityState.loading ||
      migrateToCommissionBasedState.loading,
    error,
    closeError: (): void => setError(null),
    updateStudentActivity: handleStudentActivityUpdate,
    studentUpdatedId,
    migrate,
  };
};

export default useDashboard;
