import fetch from 'src/utils/fetch';
import {
  Professor,
  ProfessorAvailability,
  ProfessorStudent,
} from 'src/types/types';

const getAllProfessors = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/professors`;

  return fetch({ url });
};

const createProfessor = (professor: Professor): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/professors`;

  return fetch({ url, data: professor, method: 'POST' });
};

const getProfessorById = (id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/professors/${id}`;

  return fetch({ url });
};

const updateProfessor = (professor: Professor): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/professors/${professor.id}`;

  return fetch({ url, data: professor, method: 'PUT' });
};

const assignStudent = (relation: ProfessorStudent): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/ps-relations`;

  return fetch({ url, data: relation, method: 'POST' });
};

const putProfessorAvailability = (
  availability: ProfessorAvailability,
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/professorApp/availability`;

  return fetch({ url, data: availability, method: 'PUT' });
};

const getProfessorAvailability = (): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/professorApp/availability`;

  return fetch({ url });
};

const getProfessorOverview = (id: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_URL}/adminApp/professors/${id}/overview`;

  return fetch({ url });
};

export default {
  getAllProfessors,
  createProfessor,
  getProfessorById,
  updateProfessor,
  assignStudent,
  putProfessorAvailability,
  getProfessorAvailability,
  getProfessorOverview,
};
