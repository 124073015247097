import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import professorsReducer from 'src/reducers/professorsSlice';
import userReducer from 'src/reducers/userSlice';
import studentsReducer from 'src/reducers/studentsSlice';
import paymentsReducer from 'src/reducers/paymentsSlice';
import classesReducer from 'src/reducers/classesSlice';
import usersReducer from 'src/reducers/usersSlice';

export const store = configureStore({
  reducer: {
    professors: professorsReducer,
    user: userReducer,
    students: studentsReducer,
    payments: paymentsReducer,
    classes: classesReducer,
    users: usersReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
