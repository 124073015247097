import React from 'react';
import Layout from 'src/shared/components/Layout';
import AddClassForm from 'src/shared/components/AddClassForm';

import useAddClass from './AddClass.hooks';

const AddClass: React.FC = () => {
  const {
    relationsList,
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    apiError,
    closeError,
    currentDurationList,
  } = useAddClass();

  return (
    <Layout>
      <AddClassForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        relationsList={relationsList}
        currentDurationList={currentDurationList}
        isLoading={isLoading}
        apiError={apiError}
        closeError={closeError}
      />
    </Layout>
  );
};

export default AddClass;
