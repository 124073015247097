import React from 'react';
import Layout from 'src/shared/components/Layout';
import TableLarge from 'src/professor/components/Tables/ProfessorClasses/TableLarge';
import TableSmall from 'src/professor/components/Tables/ProfessorClasses/TableSmall';

import useProfessorClasses from './ProfessorClasses.hooks';

const ProfessorClasses: React.FC = () => {
  const {
    filterDates,
    handleFilteringClasses,
    changeFilterDate,
    currentClasses,
    isLoading,
    error,
    closeError,
  } = useProfessorClasses();

  return (
    <Layout isLoading={isLoading} error={error} closeError={closeError}>
      <TableLarge
        data={currentClasses}
        filterDates={filterDates}
        handleFilteringClasses={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
      <TableSmall
        data={currentClasses}
        filterDates={filterDates}
        handleFilteringClasses={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
    </Layout>
  );
};

export default ProfessorClasses;
