const name = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
};

// Must stay in descending order
const value = {
  [name.XLARGE]: 1200,
  [name.LARGE]: 900,
  [name.MEDIUM]: 600,
  [name.SMALL]: 375,
  [name.XSMALL]: 0,
};

const formatted = [
  {
    name: name.XSMALL,
    media: `(min-width: ${value[name.XSMALL]}px)`,
  },
  {
    name: name.SMALL,
    media: `(min-width: ${value[name.SMALL]}px)`,
  },
  {
    name: name.MEDIUM,
    media: `(min-width: ${value[name.MEDIUM]}px)`,
  },
  {
    name: name.LARGE,
    media: `(min-width: ${value[name.LARGE]}px)`,
  },
  {
    name: name.XLARGE,
    media: `(min-width: ${value[name.XLARGE]}px)`,
  },
];

export default {
  name,
  value,
  formatted,
};
