import { useState } from 'react';
import users from 'src/api/users';
import {
  EMAIL_CHECK_ERROR,
  MULTIPLE_EMAILS_ERROR,
  NO_EMAIL_ERROR,
} from 'src/constants/literals';
import useAsyncRequest from 'src/hooks/useAsyncRequest';

interface Data {
  studentEmail: string;
  handleOnChange: (value: string) => void;
  isEmailInvalid: boolean;
  handleSubmitButton: () => void;
  loading: boolean;
  error: string | null;
  closeError: () => void;
  handleEnterClick: (e: any) => void;
}

const useStudentEmailForm = (submit: (email: string) => void): Data => {
  const [studentEmail, setStudentEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [checkUserExist, checkUserExistState] = useAsyncRequest<[string]>(
    users.checkUserExist,
    {
      onComplete: (data: any) => {
        if (data.studentCount === 0) {
          setError(NO_EMAIL_ERROR);
        }

        if (data.studentCount === 1) {
          submit(studentEmail);
        }

        if (data.studentCount > 1) {
          setError(MULTIPLE_EMAILS_ERROR);
        }
      },
      onError: () => {
        setError(EMAIL_CHECK_ERROR);
      },
    },
  );

  const handleOnChange = (value: string) => {
    if (isEmailInvalid) {
      setIsEmailInvalid(false);
    }

    if (error) {
      setError(null);
    }

    setStudentEmail(value);
  };

  const handleSubmitButton = () => {
    if (!studentEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setIsEmailInvalid(true);
    } else {
      checkUserExist(studentEmail);
    }
  };

  const closeError = () => {
    setError(null);
  };

  const handleEnterClick = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmitButton();
    }
  };

  return {
    studentEmail,
    handleOnChange,
    isEmailInvalid,
    handleSubmitButton,
    loading: checkUserExistState.loading,
    error,
    closeError,
    handleEnterClick,
  };
};

export default useStudentEmailForm;
