import React from 'react';
import Layout from 'src/shared/components/Layout';
import EditRelationForm from 'src/admin/components/EditRelationForm';

import useEditRelation from './EditRelation.hooks';

const EditRelation: React.FC = () => {
  const {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    apiError,
    closeError,
  } = useEditRelation();

  return (
    <Layout>
      <EditRelationForm
        control={control}
        errors={errors}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        apiError={apiError}
        closeError={closeError}
      />
    </Layout>
  );
};

export default EditRelation;
