import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ACTIVATE } from 'src/constants/literals';
import { OverviewPsRelation } from 'src/types/types';

import styles from './Row.module.scss';
import { Button, CircularProgress } from '@material-ui/core';

interface RowProps {
  data: OverviewPsRelation;
  activateRelation: (id: string) => void;
  isLoading: boolean;
}

const Row: React.FC<RowProps> = ({ data, activateRelation, isLoading }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell className={styles.buttonCell} size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" className={styles.nameCell} size="small">
          <span className={styles.nameWrapper}>{data.studentName}</span>
        </TableCell>
        <TableCell size="small" align="center">
          <Button
            className={styles.activateButton}
            variant="contained"
            color="primary"
            onClick={() => activateRelation(data.id)}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              <span>{ACTIVATE}</span>
            )}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">Class count</TableCell>
                    <TableCell>{data.classCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Last class</TableCell>
                    <TableCell>{data.formatedLastClassDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Rating</TableCell>
                    <TableCell>{data.formatedRating}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Status</TableCell>
                    <TableCell>{data.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
