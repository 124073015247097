import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { LoginData } from 'src/hooks/useAuth';

interface userState {
  user: LoginData | null;
}

const initialState: userState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<LoginData>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState): LoginData | null =>
  state.user.user;

export default userSlice.reducer;
