import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ClassType,
  ProfessorStudent,
  ProfessorStudentRelationFO,
} from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import relations from 'src/api/relations';
import { AdminRouteCodes } from 'src/constants/routes';
import { useHistory, useParams } from 'react-router-dom';
import classes from 'src/api/classes';

interface Data {
  onSubmit: (data: ProfessorStudent) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
  relationsList: ProfessorStudentRelationFO[];
  currentDurationList: number[];
}

interface Params {
  professorId: string;
}

const getDurationList = (relation: ProfessorStudent) => {
  const possibleDurationList = [];
  if (relation.price30 && relation.professorPrice30) {
    possibleDurationList.push(30);
  }

  if (relation.price45 && relation.professorPrice45) {
    possibleDurationList.push(45);
  }

  if (relation.price60 && relation.professorPrice60) {
    possibleDurationList.push(60);
  }

  if (relation.price90 && relation.professorPrice90) {
    possibleDurationList.push(90);
  }

  return possibleDurationList;
};

const useAddClass = (): Data => {
  const [currentStudentId, setCurrentStudentId] = useState<string>('');
  const [currentDurationList, setCurrentDurationList] = useState<number[]>([]);
  const [relationsList, setRelationsList] = useState<
    ProfessorStudentRelationFO[]
  >([]);
  const [error, setError] = useState<Error | null>(null);
  const history = useHistory();
  const { professorId } = useParams<Params>();
  const { handleSubmit, errors, control, watch } = useForm();
  const choosenRelation = watch('psRelation');

  const [getRelations, getRelationsStatus] = useAsyncRequest<[string]>(
    relations.getRelations,
    {
      onComplete: (data: ProfessorStudent[]) => {
        const relationsList = data.map((psRelation) => {
          const possibleDurationList = getDurationList(psRelation);
          return {
            id: psRelation.id,
            studentId: psRelation.studentId,
            studentName: psRelation.studentName,
            active: Object.prototype.hasOwnProperty.call(psRelation, 'active')
              ? psRelation.active
              : true,
            possibleDurationList,
            status: psRelation.status,
          };
        });
        const activeRelations = relationsList.filter(
          (relation) => relation.active,
        );

        const sortedRelationList = activeRelations.sort((a, b) => {
          if (a.studentName < b.studentName) return -1;
          if (a.studentName > b.studentName) return 1;
          return 0;
        });

        setRelationsList(sortedRelationList);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [createNewClass, createNewClassStatus] = useAsyncRequest<[ClassType]>(
    classes.createNewClass,
    {
      onComplete: () => {
        history.push(AdminRouteCodes.DASHBOARD);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const closeError = () => {
    setError(null);
    history.push(AdminRouteCodes.DASHBOARD);
  };

  useEffect(() => {
    if (professorId) {
      getRelations(professorId);
    }
  }, [professorId]);

  useEffect(() => {
    if (choosenRelation) {
      setCurrentStudentId(choosenRelation.studentId);
      if (choosenRelation.status === 'TRIAL') {
        setCurrentDurationList([30]);
      } else {
        setCurrentDurationList(choosenRelation.possibleDurationList);
      }
    }
  }, [choosenRelation]);

  const onSubmit = useCallback(
    (data: any) => {
      const classData: ClassType = {
        psRelationId: choosenRelation.id,
        professorId: professorId,
        studentId: currentStudentId,
        duration: data.duration,
        date: data.date.getTime(),
        remark: data.remark,
      };

      createNewClass(classData);
    },
    [choosenRelation, professorId, currentStudentId],
  );

  return {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading: getRelationsStatus.loading || createNewClassStatus.loading,
    apiError: error,
    closeError,
    relationsList,
    currentDurationList,
  };
};

export default useAddClass;
