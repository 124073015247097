const headerItems = [
  {
    text: 'ID',
    props: {
      width: '4%',
    },
  },
  {
    text: 'Name',
    props: {
      width: '16%',
    },
  },
  {
    text: 'Create time',
    props: {
      width: '14%',
    },
  },
  {
    text: 'Class Count',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Amount',
    props: {
      width: '6%',
    },
  },
  {
    text: 'Commission',
    props: {
      width: '8%',
    },
  },
  {
    text: 'Amount After Commission',
    props: {
      width: '11%',
    },
  },
  {
    text: 'Currency',
    props: {
      width: '7%',
    },
  },
  {
    text: 'Status',
    props: {
      width: '7%',
    },
  },
  {
    text: 'Commision Based',
    props: {
      width: '10%',
      style: { paddingRight: '5px' },
    },
  },
];

export default headerItems;
