const headerItems = [
  {
    text: '',
    props: {
      width: '3%',
    },
  },
  {
    text: 'Name',
    props: {
      width: '20%',
      style: { paddingLeft: 0 },
    },
  },
  {
    text: 'Interval',
    props: {
      width: '20%',
    },
  },
  {
    text: 'Class Count',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Amount',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Commission',
    props: {
      width: '8%',
    },
  },
  {
    text: 'Amount after commission',
    props: {
      width: '13%',
    },
  },
  {
    text: 'Currency',
    props: {
      width: '8%',
    },
  },
  {
    text: 'Commission based',
    props: {
      width: '8%',
    },
  },
];

export default headerItems;
