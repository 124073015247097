import { Dispatch, SetStateAction, useState } from 'react';
import usersFetcher from 'src/api/users';
import useAsyncRequest from 'src/hooks/useAsyncRequest';

interface Data {
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  createProfessorUser: (params_0: string, params_1: string) => void;
  error: Error | null;
  isLoading: boolean;
  closeError: () => void;
}

const useCreateNewUserForm = (
  email: string,
  professorsId: string,
  closeModal: () => void,
  getUsersAndProfessors: () => void,
): Data => {
  const [error, setError] = useState<Error | null>(null);
  const [password, setPassword] = useState('');

  const onCreateProffesorUserComplete = (uid: string) => {
    addUser(email, professorsId, uid);
  };

  const onAddUserComplete = () => {
    closeModal();
    getUsersAndProfessors();
  };

  const [addUser, addUserStatus] = useAsyncRequest<[string, string, string]>(
    usersFetcher.addUser,
    {
      onComplete: () => {
        onAddUserComplete();
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [createProfessorUser, createProfessorUserStatus] = useAsyncRequest<
    [string, string]
  >(usersFetcher.createProfessorUser, {
    onComplete: (data: any) => {
      onCreateProffesorUserComplete(data.localId);
    },
    onError: (error: Error) => {
      setError(error);
    },
  });

  return {
    password,
    setPassword,
    createProfessorUser,
    isLoading: addUserStatus.loading || createProfessorUserStatus.loading,
    error,
    closeError: () => setError(null),
  };
};

export default useCreateNewUserForm;
