import React, { useState } from 'react';
import cx from 'classnames';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Payout } from 'src/types/types';
import { formatDateAndTime, formatMoney } from 'src/utils/helpers';
import styles from './Row.module.scss';
import { Checkbox } from '@material-ui/core';

interface RowProps {
  data: Payout;
  handleCheckboxClick: (payout: Payout, checked: boolean) => void;
  checked: boolean;
}

const Row: React.FC<RowProps> = ({ data, handleCheckboxClick, checked }) => {
  const [open, setOpen] = useState(false);
  const {
    currency,
    total,
    totalCommission,
    totalAfterCommission,
    professorName,
    classCount,
    commissionBased,
    id,
    status,
    createTime,
  } = data;

  return (
    <>
      <TableRow>
        <TableCell size="small" width="10%" className={styles.mainCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          size="small"
          width="70%"
          scope="row"
          className={styles.mainCell}
        >
          {professorName}
        </TableCell>
        <TableCell
          size="small"
          width="20%"
          scope="row"
          className={cx(styles.mainCell, styles.checkboxCell)}
        >
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(e) => handleCheckboxClick(data, e.target.checked)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.collapsingCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      ID
                    </TableCell>
                    <TableCell size="small">{id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Create time
                    </TableCell>
                    <TableCell size="small">
                      {formatDateAndTime(createTime)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Class Count
                    </TableCell>
                    <TableCell size="small">{classCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Amount
                    </TableCell>
                    <TableCell size="small">{formatMoney(total)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Commission
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(totalCommission)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Amount After commision
                    </TableCell>
                    <TableCell size="small">
                      {formatMoney(totalAfterCommission)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Currency
                    </TableCell>
                    <TableCell size="small">{currency}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Status
                    </TableCell>
                    <TableCell size="small">{status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small" variant="head">
                      Commission based
                    </TableCell>
                    <TableCell size="small">
                      {commissionBased ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(Row);
