import React from 'react';

import Container from '@material-ui/core/Container';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import NavigationBar from 'src/shared/components/NavigationBar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';
import VersionBanner from 'src/shared/components/VersionBanner';
import styles from './Layout.module.scss';

interface LayoutProps {
  isLoading?: boolean;
  loaderMessage?: string;
  error?: Error | null;
  closeError?: () => void;
  children: JSX.Element | JSX.Element[] | Element | Element[];
  errorMessage?: string;
  hideNavigationBar?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  isLoading,
  error,
  closeError,
  errorMessage,
  hideNavigationBar,
}) => {
  return (
    <div className={styles.wrapper}>
      {!hideNavigationBar && <NavigationBar />}
      <Container>
        {Boolean(error) && (
          <Alert
            severity="error"
            onClose={closeError}
            className={styles.errorBar}
          >
            <AlertTitle>
              {errorMessage ? errorMessage : ERROR_POP_UP_TEXT}
            </AlertTitle>
          </Alert>
        )}
        <Paper
          className={cx(styles.content, {
            [styles.content__hasError]: Boolean(error),
          })}
          elevation={3}
        >
          {children}
          {isLoading && (
            <div className={styles.loader}>
              <CircularProgress />
            </div>
          )}
        </Paper>
      </Container>
      <VersionBanner />
    </div>
  );
};

export default Layout;
