import React from 'react';
import StudentForm from 'src/admin/components/StudentForm';
import Layout from 'src/shared/components/Layout';
import useStudent from './Student.hook';

const Professor: React.FC = () => {
  const {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    isUpdate,
    apiError,
    closeError,
  } = useStudent();

  return (
    <Layout>
      <StudentForm
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        isUpdate={isUpdate}
        isLoading={isLoading}
        apiError={apiError}
        closeError={closeError}
      />
    </Layout>
  );
};

export default Professor;
