const headerItems = [
  {
    text: '#',
    props: {
      width: '10%',
    },
  },
  {
    text: 'Student',
    props: {
      width: '25%',
    },
  },
  {
    text: 'Date',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Duration',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Price',
    props: {
      width: '15%',
    },
  },
  {
    text: 'Currency',
    props: {
      width: '10%',
    },
  },
  {
    text: '',
    props: {
      width: '10%',
    },
  },
];

export default headerItems;
