import React from 'react';
import { withWindow } from 'react-window-decorators';

import CalculatedPayoutsTableLarge from 'src/admin/components/Tables/CalculatedPayoutList/TableLarge';
import CalculatedPayoutsTableSmall from 'src/admin/components/Tables/CalculatedPayoutList/TableSmall';
import PendingPayoutsTableLarge from 'src/admin/components/Tables/PendingPayoutsList/TableLarge';
import PendingPayoutsTableSmall from 'src/admin/components/Tables/PendingPayoutsList/TableSmall';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Container from '@material-ui/core/Container/Container';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import NavigationBar from 'src/shared/components/NavigationBar';
import {
  CREATE,
  EXECUTE,
  NO_PAYOUTS,
  PENDING_PAYOUTS,
  CALCULATED_PAYOUTS,
  ERROR_POP_UP_TEXT,
} from 'src/constants/literals';

import usePayoutManagement from './PayoutManagement';
import styles from './PayoutManagement.module.scss';
import Alert from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import VersionBanner from 'src/shared/components/VersionBanner';

interface DashboardProps {
  breakpoint: string;
}

const PayoutList: React.FC<DashboardProps> = ({ breakpoint }) => {
  const {
    isLoading,
    createPayoutsLoading,
    executePendingPayoutsLoading,
    error,
    errorCreate,
    errorExecute,
    closeError,
    calculatedPayouts,
    pendingPayouts,
    handleCalculatedCheckboxClick,
    handleCalculatedCheckAllClick,
    handlePendingCheckboxClick,
    handlePendingCheckAllClick,
    selectedCaluclatedPayouts,
    selectedPendingPayouts,
    isAllCalculatedChecked,
    handleCreatePayouts,
    isAllPendingChecked,
    handleExecutePayouts,
    isCalculatedLoading,
    isPendingLoading,
    calculatedPayoutsSumData,
  } = usePayoutManagement();

  const isSmallOrMedium = ['xsmall', 'small', 'medium'].includes(breakpoint);

  return (
    <div className={styles.wrapper}>
      <NavigationBar />
      {Boolean(error) && (
        <Container className={styles.tableWrapper}>
          <Alert
            severity="error"
            onClose={closeError}
            className={styles.errorBar}
          >
            <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
          </Alert>
        </Container>
      )}
      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      {!isLoading &&
        !isCalculatedLoading &&
        !isPendingLoading &&
        calculatedPayouts.length === 0 &&
        pendingPayouts.length === 0 && (
          <Container className={styles.tableWrapper}>
            <Typography
              component="h1"
              variant="h4"
              className={styles.tableName}
            >
              {NO_PAYOUTS}
            </Typography>
          </Container>
        )}
      {!isLoading && !isCalculatedLoading && calculatedPayouts.length > 0 && (
        <Container className={styles.tableWrapper}>
          <Typography component="h1" variant="h4" className={styles.tableName}>
            {CALCULATED_PAYOUTS}
          </Typography>
          {isSmallOrMedium ? (
            <CalculatedPayoutsTableSmall
              isAllChecked={isAllCalculatedChecked}
              selectedCaluclatedPayouts={selectedCaluclatedPayouts}
              data={calculatedPayouts}
              handleCheckAllClick={handleCalculatedCheckAllClick}
              handleCheckboxClick={handleCalculatedCheckboxClick}
              calculatedPayoutsSumData={calculatedPayoutsSumData}
            />
          ) : (
            <CalculatedPayoutsTableLarge
              isAllChecked={isAllCalculatedChecked}
              selectedCaluclatedPayouts={selectedCaluclatedPayouts}
              data={calculatedPayouts}
              handleCheckboxClick={handleCalculatedCheckboxClick}
              handleCheckAllClick={handleCalculatedCheckAllClick}
              calculatedPayoutsSumData={calculatedPayoutsSumData}
            />
          )}
          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={handleCreatePayouts}
            disabled={
              selectedCaluclatedPayouts.length === 0 || createPayoutsLoading
            }
          >
            {createPayoutsLoading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              <span>{CREATE}</span>
            )}
          </Button>
          {Boolean(errorCreate) && (
            <Alert
              severity="error"
              onClose={closeError}
              className={styles.errorBar}
            >
              <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
            </Alert>
          )}
        </Container>
      )}
      {!isLoading && isCalculatedLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && !isPendingLoading && pendingPayouts.length > 0 && (
        <Container className={styles.tableWrapper}>
          <Typography component="h1" variant="h4" className={styles.tableName}>
            {PENDING_PAYOUTS}
          </Typography>
          {isSmallOrMedium ? (
            <PendingPayoutsTableSmall
              isAllChecked={isAllPendingChecked}
              selectedCaluclatedPayouts={selectedPendingPayouts}
              data={pendingPayouts}
              handleCheckAllClick={handlePendingCheckAllClick}
              handleCheckboxClick={handlePendingCheckboxClick}
            />
          ) : (
            <PendingPayoutsTableLarge
              isAllChecked={isAllPendingChecked}
              selectedCaluclatedPayouts={selectedPendingPayouts}
              data={pendingPayouts}
              handleCheckboxClick={handlePendingCheckboxClick}
              handleCheckAllClick={handlePendingCheckAllClick}
            />
          )}
          <Button
            className={styles.button}
            variant="contained"
            color="primary"
            onClick={handleExecutePayouts}
            disabled={
              selectedPendingPayouts.length === 0 ||
              executePendingPayoutsLoading
            }
          >
            {executePendingPayoutsLoading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              <span>{EXECUTE}</span>
            )}
          </Button>
          {Boolean(errorExecute) && (
            <Alert
              severity="error"
              onClose={closeError}
              className={styles.errorBar}
            >
              <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
            </Alert>
          )}
        </Container>
      )}
      {!isLoading && isPendingLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      <VersionBanner />
    </div>
  );
};

export default withWindow(PayoutList);
