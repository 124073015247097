import React, { useRef } from 'react';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { ACTIONS } from 'src/constants/literals';

import styles from './ActionsButton.module.scss';

interface ActionButtonsProps {
  isFullWidth?: boolean;
  actionsOpen: boolean;
  toggleActionsOpen: () => void;
  className?: string;
}

const ActionsButton: React.FC<ActionButtonsProps> = ({
  children,
  isFullWidth,
  toggleActionsOpen,
  actionsOpen,
  className,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={className}>
      <Button
        ref={buttonRef}
        className={cx({ [styles.button]: isFullWidth })}
        onClick={toggleActionsOpen}
        variant="outlined"
        color="primary"
        size="small"
      >
        {ACTIONS}
      </Button>
      {buttonRef.current && (
        <Menu
          className={styles.wrapper}
          id="actions-menu"
          anchorEl={buttonRef.current}
          open={actionsOpen}
          onClose={toggleActionsOpen}
        >
          {children}
        </Menu>
      )}
    </div>
  );
};

export default ActionsButton;
