import React from 'react';
import Layout from 'src/shared/components/Layout';
import StudentPaymentForm from 'src/admin/components/StudentPaymentForm';

import useStudentPayment from './StudentPayment.hooks';

const StudentPayment: React.FC = () => {
  const {
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading,
    apiError,
    closeError,
    currentStudent,
  } = useStudentPayment();

  return (
    <Layout>
      <StudentPaymentForm
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        errors={errors}
        control={control}
        student={currentStudent}
        isLoading={isLoading}
        apiError={apiError}
        closeError={closeError}
      />
    </Layout>
  );
};

export default StudentPayment;
