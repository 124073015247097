import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from 'src/shared/components/Input';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';

import styles from 'src/admin/components/ProfessorForm/ProfessorForm.module.scss';
import AutocompleteSelectInput from 'src/shared/components/AutocompleteSelectInput';

interface ProfessorFormProps {
  onSubmit: any;
  handleSubmit: any;
  errors: any;
  control: any;
  isUpdate: boolean;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
}

const ProfessorForm: React.FC<ProfessorFormProps> = ({
  onSubmit,
  handleSubmit,
  errors,
  control,
  isUpdate,
  isLoading,
  apiError,
  closeError,
}) => {
  const buttonText = isUpdate ? 'Update Professor' : 'Add Professor';

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="outlined" className={styles.card}>
          <CardHeader
            title="General information"
            className={styles.cardHeader}
          />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="name"
              className={styles.input__generalInfo}
              label="Name of professor *"
              margin="dense"
              error={!!errors.name}
              helperText={!!errors.name && 'Name is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="language"
              className={styles.input__generalInfo}
              label="Language *"
              margin="dense"
              error={!!errors.language}
              helperText={!!errors.language && 'Language is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              rules={{ required: true }}
              name="email"
              className={styles.input__generalInfo}
              type="email"
              label="Email *"
              margin="dense"
              error={!!errors.email}
              helperText={!!errors.email && 'Email is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              name="mobile"
              label="Mobile"
              margin="dense"
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              name="city"
              label="City"
              margin="dense"
              disabled={isLoading}
            />
          </CardContent>
        </Card>

        <Card variant="outlined" className={styles.card}>
          <CardHeader title="Prices" className={styles.cardHeader} />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              name="professorPrice45"
              className={styles.input__generalInfo}
              label="Original Price for 45 min"
              margin="dense"
              type="number"
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              name="price45"
              className={styles.input__generalInfo}
              label="Default Price for 45 min"
              margin="dense"
              type="number"
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              rules={{ required: true }}
              name="professorPrice60"
              type="number"
              label="Original Price for 60 min *"
              margin="dense"
              error={Boolean(errors.professorPrice60)}
              helperText={
                Boolean(errors.professorPrice60) && 'This field is required'
              }
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              rules={{ required: true }}
              name="price60"
              type="number"
              label="Default Price for 60 min *"
              margin="dense"
              error={Boolean(errors.price60)}
              helperText={Boolean(errors.price60) && 'This field is required'}
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              rules={{ required: true }}
              name="professorPrice90"
              type="number"
              label="Original Price for 90 min *"
              margin="dense"
              error={Boolean(errors.professorPrice90)}
              helperText={
                Boolean(errors.professorPrice90) && 'This field is required'
              }
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__generalInfo}
              rules={{ required: true }}
              name="price90"
              type="number"
              label="Default Price for 90 min *"
              margin="dense"
              error={Boolean(errors.price90)}
              helperText={Boolean(errors.price90) && 'This field is required'}
              disabled={isLoading}
            />
            <AutocompleteSelectInput
              error={errors.professorCurrency}
              options={['RSD', 'EUR']}
              className={styles.input__generalInfo}
              label="Original Currency *"
              name="professorCurrency"
              control={control}
              errorText="Please select the currency"
            />
            <AutocompleteSelectInput
              error={errors.currency}
              options={['RSD', 'EUR']}
              className={styles.input__generalInfo}
              label="Default Currency *"
              name="currency"
              control={control}
              errorText="Please select the currency"
            />
          </CardContent>
        </Card>

        <Card variant="outlined" className={styles.card}>
          <CardHeader title="Payment Method" className={styles.cardHeader} />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              name="accountRsd"
              className={styles.input__paymentMethodInfo}
              label="RSD Account"
              margin="dense"
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              name="accountEur"
              className={styles.input__paymentMethodInfo}
              label="EUR Account"
              margin="dense"
              disabled={isLoading}
            />
            <Input
              defaultValue=""
              control={control}
              name="accountPayPal"
              className={styles.input__paymentMethodInfo}
              label="PayPal"
              margin="dense"
              disabled={isLoading}
            />
            <AutocompleteSelectInput
              options={['RSD Account', 'EUR Account', 'PayPal']}
              className={styles.input__generalInfo}
              label="Preferred Method"
              name="preferredAccount"
              control={control}
            />
          </CardContent>
        </Card>

        <Card variant="outlined" className={styles.card}>
          <CardHeader title="Other" className={styles.cardHeader} />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              className={styles.textArea}
              label="General remark"
              name="remark"
              multiline
              disabled={isLoading}
            />
          </CardContent>
        </Card>

        <Button
          className={styles.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>{buttonText}</span>
          )}
        </Button>
      </form>
      {apiError && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default ProfessorForm;
