import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import TableHeader from 'src/shared/components/TableHeader';
import DatesFilterBar from 'src/shared/components/DatesFilterBar';
import SumTableInfo from 'src/admin/components/SumTableInfo';
import { FilterDates } from 'src/types/types';

import { ClassDetails } from 'src/types/types';

import headerItems from './HeaderItems';
import styles from './TableLarge.module.scss';
import Row from './Row';
import { NUMBER_OF_CLASSES } from 'src/constants/literals';

interface TableLargeProps {
  data: ClassDetails[];
  filterDates: FilterDates;
  handleFilteringClasses: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
}

const TableLarge: React.FC<TableLargeProps> = ({
  data,
  filterDates,
  handleFilteringClasses,
  changeFilterDate,
}) => {
  const hasData = data.length !== 0;

  return (
    <TableContainer className={styles.table}>
      <DatesFilterBar
        filterDates={filterDates}
        handleFilteringData={handleFilteringClasses}
        changeFilterDate={changeFilterDate}
      />
      <span className={styles.numberOfClasses}>
        {NUMBER_OF_CLASSES}:{' '}
        <span className={styles.number}>{data.length}</span>
      </span>
      {hasData && (
        <>
          <Table>
            <TableHeader items={headerItems} />
            <TableBody>
              {data.map((item: ClassDetails) => {
                return <Row key={item.id} data={item} />;
              })}
            </TableBody>
          </Table>
          <div className={styles.sumTable}>
            <SumTableInfo data={data} />
          </div>
        </>
      )}
    </TableContainer>
  );
};

export default React.memo(TableLarge);
