import styles from './TableSmall.module.scss';

const headerItems = [
  {
    text: '',
    props: {
      width: '5%',
      className: styles.headerCell,
    },
  },
  {
    text: 'Professor',
    props: {
      width: '35%',
      className: styles.headerCell,
    },
  },
  {
    text: 'Student',
    props: {
      width: '35%',
      className: styles.headerCell,
    },
  },
  {
    text: 'Date',
    props: {
      width: '25%',
      className: styles.headerCell,
    },
  },
];

export default headerItems;
