import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Input from 'src/shared/components/Input';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ERROR_POP_UP_TEXT } from 'src/constants/literals';

import styles from './EditRelationForm.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutocompleteSelectInput from 'src/shared/components/AutocompleteSelectInput';

interface EditRelationFormProps {
  onSubmit: any;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  apiError: Error | null;
  closeError: () => void;
}

const EditRelationForm: React.FC<EditRelationFormProps> = ({
  onSubmit,
  handleSubmit,
  errors,
  control,
  isLoading,
  apiError,
  closeError,
}) => {
  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant="outlined" className={styles.card}>
          <CardHeader title="Prices" className={styles.cardHeader} />
          <CardContent className={styles.generalInfoWrapper}>
            <Input
              defaultValue=""
              control={control}
              name="professorPrice30"
              className={styles.input__priceInfo}
              label="Professor Price for 30 min"
              margin="dense"
              type="number"
            />
            <Input
              defaultValue=""
              control={control}
              name="professorPrice45"
              className={styles.input__priceInfo}
              label="Professor Price for 45 min"
              margin="dense"
              type="number"
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__priceInfo}
              rules={{ required: true }}
              name="professorPrice60"
              type="number"
              label="Professor Price for 60 min *"
              margin="dense"
              error={Boolean(errors.price60)}
              helperText={Boolean(errors.price60) && 'This field is required'}
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__priceInfo}
              rules={{ required: true }}
              name="professorPrice90"
              type="number"
              label="Original Price for 90 min *"
              margin="dense"
              error={Boolean(errors.professorPrice90)}
              helperText={
                Boolean(errors.professorPrice90) && 'This field is required'
              }
            />
            <AutocompleteSelectInput
              className={styles.input__priceInfo}
              error={errors.professorCurrency}
              options={['RSD', 'EUR']}
              label="Original Currency *"
              name="professorCurrency"
              control={control}
              errorText="Please select the currency"
            />
            <Input
              defaultValue=""
              control={control}
              name="price30"
              className={styles.input__priceInfo}
              label="Student Price for 30 min"
              margin="dense"
              type="number"
            />
            <Input
              defaultValue=""
              control={control}
              name="price45"
              className={styles.input__priceInfo}
              label="Student Price for 45 min"
              margin="dense"
              type="number"
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__priceInfo}
              rules={{ required: true }}
              name="price60"
              type="number"
              label="Student Price for 60 min *"
              margin="dense"
              error={Boolean(errors.professorPrice60)}
              helperText={
                Boolean(errors.professorPrice60) && 'This field is required'
              }
            />
            <Input
              defaultValue=""
              control={control}
              className={styles.input__priceInfo}
              rules={{ required: true }}
              name="price90"
              type="number"
              label="Student Price for 90 min *"
              margin="dense"
              error={Boolean(errors.price90)}
              helperText={Boolean(errors.price90) && 'This field is required'}
            />
            <AutocompleteSelectInput
              className={styles.input__priceInfo}
              error={errors.currency}
              options={['RSD', 'EUR']}
              label="Student Currency *"
              name="currency"
              control={control}
              errorText="Please select the currency"
            />
          </CardContent>
        </Card>

        <Button
          className={styles.submitButton}
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>Edit relation</span>
          )}
        </Button>
      </form>
      {apiError && (
        <Alert
          className={styles.errorBox}
          severity="error"
          onClose={closeError}
        >
          <AlertTitle>{ERROR_POP_UP_TEXT}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default EditRelationForm;
