export const ACTIONS = 'Actions';
export const ADD_PROFESSOR = 'Add Professor';
export const ADD_STUDENT = 'Add Student';
export const ASSIGN = 'Assign';
export const ASSIGN_STUDENT = 'Assign Student';
export const CANCEL = 'Cancel';
export const CHOOSE_STUDENT = 'Choose student';
export const COMMENT = 'Comment';
export const CREATE = 'Create';
export const CREATE_NEW_USER = 'Create New User';
export const CREATE_USER = 'Create User';
export const DEACTIVATE = 'Deactivate';
export const ERROR_POP_UP_TEXT = 'Something went wrong, please try again.';
export const EXECUTE = 'Execute';
export const LOADER_TEXT = 'Data is loading, please wait';
export const LOG_IN = 'Log in';
export const NUMBER_OF_CLASSES = 'Number of classes';
export const REACTIVATE = 'Reactivate';
export const SHOW_ACTIVE_STUDENTS = 'Show Active Students';
export const SHOW_INACTIVE_STUDENTS = 'Show Inactive Students';
export const SUBMIT_PAYMENT = 'Submit payment';
export const MIGRATE = 'Migrate';
export const WELCOME = 'Welcome';
export const NO_PAYOUTS = 'There is no more payouts.';
export const CALCULATED_PAYOUTS = 'Calculated payouts';
export const PENDING_PAYOUTS = 'Panding payouts';
export const ACTIVATE = 'Activate';
export const NO_EMAIL_ERROR = 'U sistemu ne postoji učenik sa unešenom email adresom';
export const MULTIPLE_EMAILS_ERROR = 'U sistemu postoji više učenika sa unešenom email adresom. Kontaktirajte nas kako bismo otklonili problem.';
export const EMAIL_CHECK_ERROR = 'Došlo je do greške. Molimo Vas pokušajte ponovo.';

export const FIRST_NAME_ERROR_MESSAGE = 'Ime je obavezno';
export const LAST_NAME_ERROR_MESSAGE = 'Prezime je obavezno';
export const EMAIL_ERROR_MESSAGE = 'Validan email je obavezan';
export const COUNTRY_ERROR_MESSAGE = 'Država je obavezna';
export const MOBILE_PHONE_ERROR_MESSAGE =
  'Validan broj mobilnog telefona je obavezan';
export const ADDRESS_ERROR_MESSAGE = 'Adresa je obavezna';
export const POSTAL_CODE_ERROR_MESSAGE = 'Poštanski kod je obavezan';
export const CITY_ERROR_MESSAGE = 'Grad je obavezan';
export const PAYMENT_VALUE_ERROR_MESSAGE = 'Molimo Vas izaberite iznos';

export const CHECKOUT_FIRST_STEP_TITLE = 'Online plaćanje skypecasovi.rs';
export const CHECKOUT_SECOND_STEP_TITLE = 'Unesite informacije o uplatiocu';
export const CHECKOUT_THIRD_STEP_TITLE = 'Izaberite način plaćanja';
export const CHECKOUT_SUCCESS_TITLE = 'Uspešna uplata';
export const CHECKOUT_SUCCESS_MESSAGE =
  'Uspešno ste izvršili uplatu.Uskoro će Vam stići email sa potvrdom. Hvala!';
export const CHECKOUT_ERROR_TITLE = 'Greška';
export const CHECKOUT_PAYPAL_ERROR_MESSAGE =
  'Došlo je do greške prilikom plaćanja. Molimo Vas proverite unete infomacije i pokušajte ponovo. Hvala!';
export const CHECKOUT_STUDENT_EMAIL_EXPLANATION =
  'Unesite Vašu email adresu ili email adresu osobe za koju vršite plaćanje.';
export const CHECKOUT_PAYMENT_FORM_EXPLANATION =
  '* Unesite informacije o uplatiocu';
export const CHECKOUT_SIGN_UP = 'Prijava';
export const PAYMENT_FORM_BUTTON_TEXT = 'Nastavi sa plaćanjem';
export const CANCEL_BUTTON = 'Odustani';
export const GO_BACK_BUTTON = 'Vrati se na početak';

export const STUDENT_NOT_FOUND_ERROR_MESSAGE =
  'U sistemu ne postoji učenik sa unešenom email adresom. Kontaktirajte nas kako bismo rešili problem. Hvala.';
export const PAYMENT_VALIDATION_FAILED_ERROR_MESSAGE =
  'Došlo je do greške prilikom registrovanja Vaše uplate u naš sistem. Kontaktirajte nas kako bi rešili problem. Hvala!';
export const ORDER_NOT_FOUND_ERROR_MESSAGE =
  'Došlo je do greške prilikom registrovanja Vaše uplate u naš sistem. Kontaktirajte nas kako bi rešili problem. Hvala!';
export const CHECKOUT_BACKEND_ERROR_MESSAGE =
  'Došlo je do greške prilikom registrovanja Vaše uplate u naš sistem. Kontaktirajte nas kako bi rešili problem. Hvala!';
