import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHeader from 'src/shared/components/TableHeader';
import { Payout } from 'src/types/types';
import { CalculatedPayoutsSumData } from 'src/admin/pages/PayoutManagement/PayoutManagement';

import headerItems from './HeaderItems';
import styles from './TableLarge.module.scss';
import Row from './Row';
import SumRow from './SumRow';

interface TableLargeProps {
  data: Payout[];
  handleCheckboxClick: (payout: Payout, checked: boolean) => void;
  handleCheckAllClick: (checked: boolean) => void;
  selectedCaluclatedPayouts: Payout[];
  isAllChecked: boolean;
  calculatedPayoutsSumData: CalculatedPayoutsSumData | undefined;
}

const TableLarge: React.FC<TableLargeProps> = ({
  data,
  handleCheckboxClick,
  handleCheckAllClick,
  isAllChecked,
  selectedCaluclatedPayouts,
  calculatedPayoutsSumData,
}) => {
  const hasData = data.length !== 0;

  return (
    <Paper>
      <TableContainer className={styles.table}>
        {hasData && (
          <Table>
            <TableHeader
              isCheckboxChecked={isAllChecked}
              items={headerItems}
              hasSelectAll
              handleCheckboxClick={handleCheckAllClick}
            />
            <TableBody>
              {data.map((item: Payout, i: number) => {
                const isChecked = selectedCaluclatedPayouts.find((payout) => {
                  return payout.currentId === item.currentId;
                });

                return (
                  <Row
                    key={i}
                    data={item}
                    handleCheckboxClick={handleCheckboxClick}
                    checked={!!isChecked}
                  />
                );
              })}
              {calculatedPayoutsSumData && (
                <SumRow data={calculatedPayoutsSumData} />
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Paper>
  );
};

export default React.memo(TableLarge);
