import { useEffect, useState } from 'react';
import { ClassDetails } from 'src/types/types';

interface SumData {
  totalEUR: number;
  totalRSD: number;
}

interface SumTableInfoData {
  sumData: SumData;
}

const calculateSumData = (classes: ClassDetails[]): SumData => {
  let totalEUR = 0;
  let totalRSD = 0;

  for (let i = 0; i < classes.length; i++) {
    if (classes[i].professorCurrency === 'EUR') {
      totalEUR += classes[i].professorPrice;
    } else if (classes[i].professorCurrency === 'RSD') {
      totalRSD += classes[i].professorPrice;
    }
  }

  return {
    totalEUR,
    totalRSD,
  };
};

const useSumTableInfo = (classes: ClassDetails[]): SumTableInfoData => {
  const [sumData, setSumData] = useState<SumData>({
    totalEUR: 0,
    totalRSD: 0,
  });

  useEffect(() => {
    setSumData(calculateSumData(classes));
  }, [classes]);

  return {
    sumData,
  };
};

export default useSumTableInfo;
