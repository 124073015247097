import React from 'react';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableHeader from 'src/shared/components/TableHeader';
import { FilterDates, Payment } from 'src/types/types';

import Row from './Row';
import headerItems from './HeaderItems';
import styles from './TableSmall.module.scss';
import DatesFilterBar from 'src/shared/components/DatesFilterBar';

interface TableSmallProps {
  data: Payment[];
  filterDates: FilterDates;
  handleFilteringPayments: () => void;
  changeFilterDate: (data: Date | null, type: string) => void;
  handleModalOpen: (paymentId: number, remark: string | null) => void;
}

const TableSmall: React.FC<TableSmallProps> = ({
  data,
  filterDates,
  handleFilteringPayments,
  changeFilterDate,
  handleModalOpen,
}) => {
  return (
    <TableContainer className={styles.table}>
      <DatesFilterBar
        filterDates={filterDates}
        handleFilteringData={handleFilteringPayments}
        changeFilterDate={changeFilterDate}
      />
      <Table>
        <TableHeader items={headerItems} />
        <TableBody>
          {data.map((item: Payment) => {
            return (
              <Row
                key={item.id}
                data={item}
                handleModalOpen={handleModalOpen}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSmall;
