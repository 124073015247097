import React from 'react';
import cx from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import { ClassDetails } from 'src/types/types';
import { formatDate, formatMoney } from 'src/utils/helpers';
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutline';
import FlagIcon from '@material-ui/icons/Flag';

import styles from './Row.module.scss';

interface RowProps {
  data: ClassDetails;
}

const Row: React.FC<RowProps> = ({ data }) => {
  const {
    currency,
    date,
    duration,
    id,
    price,
    professorCurrency,
    professorName,
    professorPrice,
    remark,
    studentName,
    commissionBased,
  } = data;

  const flagClasses = cx({
    [styles.flag]: true,
    [styles.flag__withRemark]: remark,
  });

  return (
    <TableRow>
      <TableCell size="small">{id}</TableCell>
      <TableCell size="small">{professorName}</TableCell>
      <TableCell size="small">{studentName}</TableCell>
      <TableCell size="small">{formatDate(date)}</TableCell>
      <TableCell size="small">{duration}</TableCell>
      <TableCell size="small">
        {formatMoney(professorPrice, professorCurrency)}
      </TableCell>
      <TableCell size="small">{formatMoney(price, currency)}</TableCell>
      <TableCell size="small" align="center" className={styles.remarkCell}>
        {!commissionBased && <FlagIcon className={flagClasses} />}
        {remark && (
          <Tooltip title={remark} classes={{ tooltip: styles.tooltip }}>
            <ChatBubbleIcon fontSize="small" />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
