import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatMoney } from 'src/utils/helpers';
import { CalculatedPayoutsSumData } from 'src/admin/pages/PayoutManagement/PayoutManagement';

import styles from './SumRow.module.scss';

interface SumRowProps {
  data: CalculatedPayoutsSumData;
}

const SumRow: React.FC<SumRowProps> = ({ data }) => {
  const {
    amountSum,
    commissionSum,
    amountAfterCommission,
    classCountSum,
  } = data;

  return (
    <TableRow>
      <TableCell size="small">{''}</TableCell>
      <TableCell size="small">{''}</TableCell>
      <TableCell size="small">{''}</TableCell>
      <TableCell size="small" className={styles.text}>
        {classCountSum}
      </TableCell>
      <TableCell size="small" className={styles.text}>
        {amountSum ? formatMoney(amountSum) : ''}
      </TableCell>
      <TableCell size="small" className={styles.text}>
        {commissionSum ? formatMoney(commissionSum) : ''}
      </TableCell>
      <TableCell size="small" className={styles.text}>
        {amountAfterCommission ? formatMoney(amountAfterCommission) : ''}
      </TableCell>
      <TableCell size="small">{''}</TableCell>
      <TableCell size="small">{''}</TableCell>
    </TableRow>
  );
};

export default React.memo(SumRow);
