import {
  RouteCodes,
  AdminRouteCodes,
  ProfessorRouteCodes,
} from 'src/constants/routes';
import useAuth, { UserRoles } from 'src/hooks/useAuth';

interface Data {
  getDefaultRoute: () => string;
}

const useRouter = (): Data => {
  const { isAuthenticated, getUserRole } = useAuth();
  const isAuth = isAuthenticated();
  const userRole = getUserRole();

  const getDefaultRoute = () => {
    let route = '/';
    if (!isAuth) {
      route = RouteCodes.LOGIN;
    } else {
      if (userRole === UserRoles.admin) {
        route = AdminRouteCodes.DASHBOARD;
      } else {
        route = ProfessorRouteCodes.DASHBOARD;
      }
    }

    return route;
  };

  return { getDefaultRoute };
};

export default useRouter;
