import { useEffect, useState } from 'react';
import { ClassDetails } from 'src/types/types';

interface SumData {
  profTotalEUR: number;
  profTotalRSD: number;
  totalEUR: number;
  totalRSD: number;
}

interface SumTableInfoData {
  sumData: SumData;
}

const calculateSumData = (classes: ClassDetails[]): SumData => {
  let profTotalEUR = 0;
  let profTotalRSD = 0;
  let totalEUR = 0;
  let totalRSD = 0;

  for (let i = 0; i < classes.length; i++) {
    if (classes[i].currency === 'EUR') {
      totalEUR += classes[i].price;
    } else if (classes[i].currency === 'RSD') {
      totalRSD += classes[i].price;
    }

    if (classes[i].professorCurrency === 'EUR') {
      profTotalEUR += classes[i].professorPrice;
    } else if (classes[i].professorCurrency === 'RSD') {
      profTotalRSD += classes[i].professorPrice;
    }
  }

  return {
    profTotalEUR: profTotalEUR,
    profTotalRSD: profTotalRSD,
    totalEUR: totalEUR,
    totalRSD: totalRSD,
  };
};

const useSumTableInfo = (classes: ClassDetails[]): SumTableInfoData => {
  const [sumData, setSumData] = useState<SumData>({
    profTotalEUR: 0,
    profTotalRSD: 0,
    totalEUR: 0,
    totalRSD: 0,
  });

  useEffect(() => {
    setSumData(calculateSumData(classes));
  }, [classes]);

  return {
    sumData,
  };
};

export default useSumTableInfo;
