import { useState, useEffect } from 'react';
import useAsyncRequest from 'src/hooks/useAsyncRequest';
import analytics from 'src/api/analytics';
import { AnalyticsData } from 'src/types/types';

interface Data {
  data: AnalyticsData | undefined;
  isLoading: boolean;
  error: Error | null;
  closeError: () => void;
}

const useProfessorInfo = (): Data => {
  const [data, setData] = useState<AnalyticsData | undefined>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    getAnalytics();
  }, []);

  const [getAnalytics, getAnalyticsStatus] = useAsyncRequest<
    []
  >(analytics.getAnalytics, {
    onComplete: (data: AnalyticsData) => {
      setData(data);
    },
    onError: (error: Error) => {
      setError(error);
      window.scrollTo(0, 0);
    },
  });

  const closeError = () => {
    setError(null);
  }

  return { 
    data, 
    isLoading: getAnalyticsStatus.loading,
    error,
    closeError,
  };
};

export default useProfessorInfo;
