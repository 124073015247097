import Typography from '@material-ui/core/Typography';
import React from 'react';
import cx from 'classnames';
import { formatMoney } from 'src/utils/helpers';
import { Link } from 'react-router-dom';

import styles from './ProfessorOverviewData.module.scss';
import { AdminRouteCodes } from 'src/constants/routes';
import Button from '@material-ui/core/Button';
import ActivityBox from '../ActivityBox';

interface ProfessorOverviewDataProps {
  professorData: any;
  availabilityData: any;
  activity: string;
}

const ProfessorOverviewData: React.FC<ProfessorOverviewDataProps> = ({
  professorData,
  availabilityData,
  activity,
}) => {
  const {
    id,
    name,
    language,
    email,
    remark,
    professorPrice60,
    professorPrice90,
    professorCurrency,
  } = professorData;
  const { availability } = availabilityData;

  const availabilityCircle = cx({
    [styles.availabilityCircle]: true,
    [styles.availabilityCircle__low]: availability === 'LOW',
    [styles.availabilityCircle__full]: availability === 'NONE',
    [styles.availabilityCircle__high]: availability === 'HIGH',
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftContentWrapper}>
        <Typography component="div" variant="h5" className={styles.name}>
          {name} <span className={availabilityCircle} />{' '}
          <ActivityBox activityValue={activity} />
        </Typography>
        <Typography component="p" className={styles.text}>
          {language}
        </Typography>
        <Typography component="p" className={styles.text}>
          {email}
        </Typography>
        <Typography component="p" className={styles.remark}>
          {remark}
        </Typography>
        <Typography component="p" className={styles.text}>
          Professor price 60min:{' '}
          <b>{formatMoney(professorPrice60, professorCurrency)}</b>
        </Typography>
        <Typography component="p" className={styles.text}>
          Professor price 90min:{' '}
          <b>{formatMoney(professorPrice90, professorCurrency)}</b>
        </Typography>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          className={styles.button}
          to={`${AdminRouteCodes.ADD_CLASS}/${id}`}
        >
          Add class
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          className={styles.button}
          to={`${AdminRouteCodes.STUDENT_ASSIGN}/${id}`}
        >
          Assing Student
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          className={styles.button}
          to={`${AdminRouteCodes.PROFESSOR}/${id}`}
        >
          Edit Info
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          className={styles.button}
          to={`${AdminRouteCodes.CLASSES}?professorId=${id}`}
        >
          View Classes
        </Button>
      </div>
    </div>
  );
};

export default ProfessorOverviewData;
