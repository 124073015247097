import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Student } from 'src/types/types';

import useAsyncRequest from 'src/hooks/useAsyncRequest';
import student from 'src/api/student';
import { AdminRouteCodes } from 'src/constants/routes';
import { useHistory, useParams } from 'react-router-dom';

interface Data {
  onSubmit: (data: Student) => void;
  handleSubmit: any;
  errors: any;
  control: any;
  isLoading: boolean;
  isUpdate: boolean;
  apiError: Error | null;
  closeError: () => void;
}

interface Params {
  id: string;
}

const useStudentForm = (): Data => {
  const [error, setError] = useState<Error | null>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const history = useHistory();
  const { id } = useParams<Params>();
  const { handleSubmit, errors, control, reset } = useForm();

  const onCreateStudentComplete = () => {
    history.push(AdminRouteCodes.DASHBOARD);
  };

  const [createStudent, createStudentStatus] = useAsyncRequest<[Student]>(
    student.createStudent,
    {
      onComplete: () => {
        onCreateStudentComplete();
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [updateStudent, updateStudentStatus] = useAsyncRequest<[Student]>(
    student.updateStudent,
    {
      onComplete: () => {
        onCreateStudentComplete();
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  const [getStudentById, getStudentByIdStatus] = useAsyncRequest<[string]>(
    student.getStudentById,
    {
      onComplete: (data: Student) => {
        reset(data);
      },
      onError: (error: Error) => {
        setError(error);
      },
    },
  );

  useEffect(() => {
    if (id) {
      getStudentById(id);
      setIsUpdate(true);
    }
  }, [id]);

  const onSubmit = useCallback(
    (data: Student) => {
      if (id) {
        updateStudent({ ...data, id });
      } else {
        createStudent(data);
      }
    },
    [id],
  );

  return {
    isUpdate,
    onSubmit,
    handleSubmit,
    errors,
    control,
    isLoading:
      createStudentStatus.loading ||
      updateStudentStatus.loading ||
      getStudentByIdStatus.loading,
    apiError: error,
    closeError: (): void => setError(null),
  };
};

export default useStudentForm;
