import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { ACTIVATE } from 'src/constants/literals';
import { OverviewPsRelation } from 'src/types/types';

import styles from './Row.module.scss';

interface RowProps {
  data: OverviewPsRelation;
  activateRelation: (id: string) => void;
  isLoading: boolean;
}

const Row: React.FC<RowProps> = ({ data, activateRelation, isLoading }) => {
  return (
    <TableRow>
      <TableCell size="small">{data.studentName}</TableCell>
      <TableCell size="small">{data.classCount}</TableCell>
      <TableCell size="small">{data.formatedLastClassDate}</TableCell>
      <TableCell size="small">{data.formatedRating}</TableCell>
      <TableCell size="small">{data.status}</TableCell>
      <TableCell size="small">
        <Button
          className={styles.activateButton}
          variant="contained"
          color="primary"
          onClick={() => activateRelation(data.id)}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size="24px" />
          ) : (
            <span>{ACTIVATE}</span>
          )}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default React.memo(Row);
